import React, { useContext } from 'react'

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

//  React components
import MDBox from "components/MDBox";
import { useNavigate } from "react-router-dom";
import { Padding } from "@mui/icons-material";
import { DataContext } from "context/dataContext";

function DataTableBodyCell({ cell, noBorder, padding = 'initial', align, children, highlight = false }) {
  const { getTagsApi } = useContext(DataContext)


  const navigate = useNavigate();
  // link to redirect url of a page or a popup prefix class to trigger that
  let link = cell?.column?.link || false;
  // If this is true then we will get row's id by 'cell.row.original.id' (id is custom field).
  let linkByID = cell?.column?.linkByID ? cell.row.original.id : "";
  let openAsPopup = cell?.column?.openAsPopup || false;


  let customFunction = cell?.column?.customFunction || false;



  const handelToNavigate = () => {
    // If openAsPopup is true then it trigger button of popup. It find popup with prefix with popup id
    // If openAsPopup is false then it redirect to url (link + linkByID)
    if (openAsPopup) {
      const button = document.querySelector(`#${link + linkByID} > .popup`);
      if (link && linkByID && button) {
        button.click();
      }
    } else {
      link && navigate(link + linkByID);
    }



    if (customFunction) {
      let linked_id = cell?.row?.original?.linked_id || 0
      let linked_counter = cell?.row?.original?.linked_counter || 0

      if (customFunction === 'get_tags') {
        getTagsApi(linked_id, linked_counter)
      }

      if (customFunction === 'edit_tags') {
        navigate('/transactions/tags/' + linked_id + '-' + linked_counter);
      }
    }




  };






  return (
    <MDBox
      onDoubleClick={() => {
        handelToNavigate();
      }}
      component="td"
      textAlign={align}
      py={padding === 'initial' ? 1.5 : padding}
      px={padding === 'initial' ? 3 : padding}
      sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
        fontSize: size.sm,
        borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
        cursor: link ? "pointer" : "initial",
        userSelect: link ? "none" : "initial",
        background: highlight ? "#d9d9d9" : "initial",
      })}
    >
      <MDBox
        display="inline-block"
        width="max-content"
        color="text"
        sx={{ verticalAlign: "middle" }}
      >
        {children}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;
