
import { useContext, useEffect, useState } from "react";
import Card from "@mui/material/Card";
//  React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "../../../components/DataTable";

// Data
import dataTableData from "./components/dataTableData";
import NoDataFound from "components/NoDataFound";
import rowDataJson from "../../../demo_data/itemsData.json";
import { NavLink } from "react-router-dom";

import stampData from '../../../demo_data/stamp.json';


import { DataContext } from 'context/dataContext'




const Items = () => {
  const { dataState } = useContext(DataContext)

  const [data, setData] = useState({ ...dataTableData, rows: [] });
  let items_groups_and_item_group_type = [...dataState.items_groups, ...dataState.item_group_type]


  useEffect(() => {
    setData(prevData => ({
      ...prevData,
      rows: dataState.all_items.map((item) => {
        const category = items_groups_and_item_group_type.find((e) => e.id === Number(item.item_type));
        return {
          ...item,
          item_type: category?.name || null,
        };
      })
    }));
  }, [dataState.all_items, dataState.items_groups_and_item_group_type, dataState.items_groups, dataState.item_group_type]);



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={2}>
        <MDBox display="flex" justifyContent="flex-end" alignItems="center" gap={2} mb={2}>
          <MDButton variant="gradient" color="primary" component={NavLink} to="/master/add-item"> Create Item </MDButton>
          <MDButton variant="gradient" color="primary"> Create Item (CSV ) </MDButton>
        </MDBox>
        <Card>
          {
            data.rows.length > 0 ? (
              <DataTable table={data} canSearch />
            ) : (
              <MDBox p={2}>
                <NoDataFound
                  title="No Data Found"
                />
              </MDBox>
            )
          }
        </Card>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Items;
