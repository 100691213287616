import React, { useState } from 'react';
import { TableButtonStyle } from 'layouts/transactions/components/style';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Popover } from '@mui/material';
import MDInput from 'components/MDInput';
import { PopoverModalStyle } from 'layouts/transactions/components/style';
import { v4 as uuidv4 } from 'uuid';

const PopoverCopyRows = ({
  tagItems,
  setTagItems,
  rowData,
  // itemCodeCounter,
  // setItemCodeCounter,
  prefixDataManager,
  // itemCodeType,
  handleItemCodeOn_CopyRow
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [counterForCopyRow, setCounterForCopyRow] = useState(1); // Default to 1
  const [isProcessing, setIsProcessing] = useState(false); // Prevent multiple submissions

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const tagItemsTableMaxLength = 50; // Temporary value

  // const incrementItemCodeForCopy = (index) => {
  //   let tempitemCode = itemCodeCounter + index;
  //   return itemCodeType + tempitemCode;
  // };





  const handleToUpdateCounterForCopyRow = (value) => {
    const validValue = value.match(/^\d+$/) ? parseInt(value, 10) : 1;

    if (validValue <= tagItemsTableMaxLength - tagItems.length && validValue >= 1) {
      setCounterForCopyRow(validValue);
    } else {
      const trimmedCounter = Math.min(tagItemsTableMaxLength - tagItems.length, 50);
      setCounterForCopyRow(trimmedCounter);
      console.log(`Cannot add ${value} rows. Maximum allowable row limit is 50.`);
    }
  };

  const handleToCopyRows = () => {




    console.log('counterForCopyRow', counterForCopyRow);
    console.log('prefixDataManager', prefixDataManager);



    if (isProcessing || !counterForCopyRow) return;

    setIsProcessing(true); // Block further input
    if (tagItems.length < tagItemsTableMaxLength) {
      const rowWhichHasToCopy = tagItems.find((item) => item.id === rowData.id);

      if (!rowWhichHasToCopy || !rowWhichHasToCopy.item) {
        setIsProcessing(false);
        return;
      }

      let rowWhichHasToCopy_Prefix = prefixDataManager.find((item) => item.id === rowWhichHasToCopy.item)

      const newCopiedRows = Array.from({ length: counterForCopyRow }, (_, index) => ({
        ...rowWhichHasToCopy,
        id: uuidv4(),
        prefix: rowWhichHasToCopy_Prefix.prefix,
        prefix_counter: (rowWhichHasToCopy_Prefix.new_counter) + (index + 1),
      }));

      // Update itemCodeCounter and tagItems
      // setItemCodeCounter((prevCounter) => prevCounter + counterForCopyRow);
      setTagItems((prevItems) => [...prevItems, ...newCopiedRows]);

      // counterForCopyRow


      console.log(
        'prefixDataManager.find((item)=>item.id === rowWhichHasToCopy.id)',
        rowWhichHasToCopy_Prefix
      );


      handleItemCodeOn_CopyRow(rowWhichHasToCopy.item, (rowWhichHasToCopy_Prefix.new_counter + counterForCopyRow))

      console.log(`${counterForCopyRow} row(s) copied`);
    } else {
      console.log(`Cannot add more rows. Maximum allowable row limit is ${tagItemsTableMaxLength}.`);
    }

    // Allow input after the operation completes
    setTimeout(() => setIsProcessing(false), 500); // Add small delay for UI consistency
  };












  const handleToFocusAllText = (e) => {
    e.target.select();
  };


  return (
    <>
      <TableButtonStyle aria-describedby={id} onClick={handleClick}>
        <FileCopyIcon sx={{ fill: '#2281ff' }} />
      </TableButtonStyle>
      <Popover
        id={id}
        open={open}
        sx={{
          '.MuiPaper-root': {
            background: '#fff',
            borderRadius: '3px',

            boxShadow: "0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)"
          }
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
      >
        <PopoverModalStyle>
          <MDInput
            variant="outlined"
            autoFocus={true}
            inputProps={{ type: 'number' }}
            value={counterForCopyRow}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !isProcessing) {
                handleToCopyRows();
                handleClose();
              }
            }}
            onFocus={(e) => { handleToFocusAllText(e) }}
            onChange={(e) => handleToUpdateCounterForCopyRow(e.target.value)}
            size="small"
            sx={{
              '*': { border: 'hidden' },
              width: '60px',
              '& input': {
                border: '1px solid #d8d8d8',
                borderRadius: '4px',
                fontSize: '14px',
                fontWeight: 500,
                padding: '5px 10px',
                textAlign: 'center',
                color: '#000',

              },
            }}
          />
          <CheckIcon
            onClick={() => {
              handleToCopyRows();
              handleClose();
            }}
            sx={{ width: '25px', height: '25px', fill: '#2281ff' }}
          />
          <CloseIcon onClick={handleClose} sx={{ width: '25px', height: '25px', fill: '#c80500' }} />
        </PopoverModalStyle>
      </Popover>
    </>
  );
};

export default PopoverCopyRows;
