

import { useState, useEffect } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useParams } from "react-router-dom";
import FormField from "./components/FormField";
import MDTypography from "components/MDTypography";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";

// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Switch from "@mui/material/Switch";

// Setting pages components
import TableCell from "layouts/account/profile/components/TableCell";
import { useAuth } from "../../../utils/AuthProvider";
import { API_URL } from "context/dataContext";

const axios = require('axios').default

function AddRoles() {

  const { cookies } = useAuth();
  axios.defaults.headers.common = {'Authorization': `Bearer ${cookies.token}`}

  const [activeStep, setActiveStep] = useState(0);
  const [roleName, setRoleName] = useState('');
  const [pages, setPages] = useState(
    [
      {
        page:'Master',
        read:true,
        add:true,
        edit:true,
        delete:true,
      },
      {
        page:'Transactions',
        read:true,
        add:false,
        edit:false,
        delete:false,
      },
      {
        page:'Reports',
        read:true,
        add:false,
        edit:false,
        delete:false,
      },
      {
        page:'Girvi',
        read:true,
        add:false,
        edit:true,
        delete:false,
      },
      {
        page:'Kitty',
        read:true,
        add:false,
        edit:true,
        delete:false,
      },
    ]
  );

  const { email } = useParams();


  useEffect(() => {
    // axios
    //   .post('http://localhost:5000/api/getuserdata', {
    //     user_email:email
    //   })
    //   .then( function (response) {
    //    console.log(response.data)
    //    if(response.data.status === 'user found'){
    //     const user = response.data.user
    //     if(user.user_roles){
    //       const userRoles = user.user_roles
    //       setPages(userRoles)
    //     }
    //    }
    //   })
    //   .catch(function (error) {
    //     alert('Something went wrong')
    //     console.log(error)
    //   })
    

  }, [email]); // Add email to the dependency array


  function savePermission() {
    if(roleName!==''){
      axios
      .post(`${API_URL}/api/roles/add`, {
        role_name:roleName,
        roles:pages,
      })
      .then( function (response) {
       console.log(response.data)
       alert('Role Saved')
      })
      .catch(function (error) {
        alert('Something went wrong')
        console.log(error)
      })
    }else{
      alert('Please add a name to the role')
    }
    
  }


  function changePermisssion(checked, value, index) {
    var pagesArr = pages
    if(value === 'read'){
      pagesArr[index].read = checked
    }else if(value === 'add'){
      pagesArr[index].add = checked
    }else if(value === 'edit'){
      pagesArr[index].edit = checked
    }else if(value === 'delete'){
      pagesArr[index].delete = checked
    }
    
    setPages(pagesArr)
    console.log(pagesArr)
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={2}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 1 }}>
          <Grid item xs={12} lg={12}>
            <Card>
            <MDBox p={3} lineHeight={1}>
              <MDBox mb={1}>
                <MDTypography variant="h5">Add Role:
                </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <MDInput variant="standard" label="Role Name" placeholder="Role Name" value={roleName} onChange={(e)=>setRoleName(e.target.value)} sx={{width:'20rem'}}  type='text' />
              </MDBox>
              <MDTypography variant="button" color="text">
                Choose which pages and permissions are available. 
              </MDTypography>
            </MDBox>
            <MDBox pb={3} px={3}>
              <MDBox minWidth="auto" sx={{ overflow: "scroll" }}>
                <Table sx={{ minWidth: "36rem" }}>
                  <MDBox component="thead">
                    <TableRow>
                      <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                        Pages
                      </TableCell>
                      <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                        Read
                      </TableCell>
                      <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                        Add
                      </TableCell>
                      <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                        Edit
                      </TableCell>
                      <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                        Delete
                      </TableCell>
                    </TableRow>
                  </MDBox>
                  <TableBody>
                    {pages.map((el,i) => (
                      <TableRow>
                      <TableCell padding={[1, 1, 1, 0.5]}>
                        <MDBox lineHeight={1.4}>
                          <MDTypography display="block" variant="button" fontWeight="regular">
                            {el.page}
                          </MDTypography>
                          <MDTypography variant="caption" color="text" fontWeight="regular">
                            description about the page
                          </MDTypography>
                        </MDBox>
                      </TableCell>
                      <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                        <Switch checked={el.read} onChange={(val)=>changePermisssion(val.target.checked,'read',i)} />
                      </TableCell>
                      <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                        <Switch checked={el.add} onChange={(val)=>changePermisssion(val.target.checked,'add',i)}/>
                      </TableCell>
                      <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                        <Switch checked={el.edit} onChange={(val)=>changePermisssion(val.target.checked,'edit',i)}/>
                      </TableCell>
                      <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                        <Switch checked={el.delete} onChange={(val)=>changePermisssion(val.target.checked,'delete',i)}/>
                      </TableCell>
                    </TableRow>
                    ))}
                    
                  </TableBody>
                </Table>
              </MDBox>
            </MDBox>
            <MDBox justifyContent='flex-start' p={3} pt={1}>
              <MDButton variant="gradient" color="primary" onClick={savePermission}>Save Permissions</MDButton>
            </MDBox>
            
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default AddRoles;