

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "layouts/users/user-list/data/dataTableData";
import NoDataFound from "components/NoDataFound";
import { useAuth } from "utils/AuthProvider";
import { API_URL } from "context/dataContext";

const axios = require('axios').default

function UserList() {

  const { cookies } = useAuth();
  axios.defaults.headers.common = {'Authorization': `Bearer ${cookies.token}`}
  // const config = {
  //     headers: { Authorization: `Bearer ${cookies.token}` }
  // }

  const [menu, setMenu] = useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const [data, setData] = useState({ ...dataTableData, rows: [] });

  useEffect(() => {
    console.log(cookies.token)
    axios
      .get(`${API_URL}/api/users`, {})
      .then( function (response) {
       console.log(response.data)
       setData(prevData => ({ ...prevData, rows: response.data }));
       //window.location.href = '/dashboards/analytics'
      })
      .catch(function (error) {
        console.log('Something went wrong')
        console.log(error)
      })
    //setData(prevData => ({ ...prevData, rows: rowDataJson }));
  }, [dataTableData]);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>All</MenuItem>
      <MenuItem onClick={closeMenu}>Admin</MenuItem>
      <MenuItem onClick={closeMenu}>Customer</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={1}>
          <Grid container spacing={1} alignItems="left">
            <Grid item xs={12} lg={6} mt={3}>
              <MDTypography variant="h4" fontWeight="medium">
                Users
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDBox display="flex" justifyContent="flex-start">
          <Link to="/users/new-user">
          <MDButton variant="gradient" color="primary">
            new user
          </MDButton>
          </Link>
          <Link to="/users/add-roles">
          <MDButton variant="gradient" color="error" sx={{marginLeft:'10px'}}>
            new role
          </MDButton>
          </Link>
          </MDBox>
          
          <MDBox display="flex">
            <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>file_download</Icon>
                &nbsp;export pdf
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
        {
            data.rows.length > 0 ? (
              <DataTable table={data} entriesPerPage={false} canSearch  />
            ) : (
              <MDBox p={2}>
                <NoDataFound
                  title="No Data Found"
                />
              </MDBox>
            )
          }
        </Card>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default UserList;