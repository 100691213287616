import { Card, Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import React from 'react'

const FilterMenuContainer = ({
  children,
  flexDirection = "row"
}) => {
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" py={1} px={2}>
        <Grid item xs={12} sm={12}>
          <MDBox
            display="flex"
            flexDirection={flexDirection}
            gap={2}
            alignItems="center"
            justifyContent="flex-start"
            py={2}
          >
            {children}
          </MDBox>
        </Grid>
      </MDBox>
    </Card>
  )
}

export default FilterMenuContainer