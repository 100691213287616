/** 
  All of the routes for the  React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

//  React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import NewUser from "layouts/users/new-user";
import Invoice2 from "layouts/account/invoice";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Reset from "layouts/authentication/reset-password";
import BlankPage from "layouts/ecommerce/products/blank-page";

//  React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
// import AccountGroups from "layouts/master/account_groups";
import Sale from "layouts/transactions/sale";
import Account from "layouts/master/account";
import Items from "layouts/master/items";
// import ItemGroups from "layouts/master/item_groups";
import Tags from "layouts/transactions/tags";
import GirviDashboard from "layouts/girvi/girvi_dashboard";
import NewGirvi from "layouts/girvi/girvi_payment";
import GirviPayment from "layouts/girvi/new_girvi";
import KittyDashboard from "layouts/kitty/kitty_dashboard";
import AddKitty from "layouts/kitty/kitty_payment";
import KittyPayment from "layouts/kitty/add_kitty";
import UserActivity from "layouts/user_activity";
import InvoicePage from "layouts/invoice";
import UserList from "layouts/users/user-list";

import Ledger from "layouts/reports/ledger";
import SaleReport from "layouts/reports/sales";
import Stocks from "layouts/reports/stocks";
import Payments from "layouts/reports/payments";

import AddItem from "layouts/master/item_manage/AddItem";
import EditItem from "layouts/master/item_manage/EditItem";
import TransactionPayment from "layouts/transactions/payment";
import AddAccount from "layouts/master/account_manage/AddAccount";
import EditAccount from "layouts/master/account_manage/EditAccount";
import Profile from "layouts/account/profile";
import EditUser from "layouts/users/edit-user";
import Account_Group from "layouts/master/account_group";
import Item_Group from "layouts/master/item_group";
import EditRoles from "layouts/users/edit-roles";
import Stamp from "layouts/master/stamp";
import RoleList from "./layouts/users/role-list";
import AddRoles from "./layouts/users/add-roles";
import TagStock from "layouts/master/tag_stock";
import LooseStock from "layouts/master/loose_stock";
import AllTags from "layouts/reports/tags";
// import { useAuth } from "utils/AuthProvider";

const routes = [
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "/dashboard",
    component: <Analytics />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Transactions",
    key: "transactions",
    icon: <Icon fontSize="medium">payments</Icon>,
    collapse: [
      {
        name: "Sale",
        key: "sale",
        route: "/transactions/sale",
        component: <Sale />,
      },
      {
        name: "Payment",
        key: "payment",
        route: "/transactions/payment",
        component: <TransactionPayment />,
      },
      {
        name: "Tags",
        key: "tags",
        route: "/transactions/tags",
        component: <Tags />,
      },
    ],
  },

  {
    type: "hidden",
    name: "Transactions",
    key: "transactions",
    collapse: [
      {
        name: "Sale",
        key: "sale",
        route: "/transactions/sale/:id",
        component: <Sale />,
      },
      {
        name: "Payment",
        key: "payment",
        route: "/transactions/payment/:id",
        component: <TransactionPayment />,
      },
      {
        name: "Tags",
        key: "tags",
        route: "/transactions/tags/:id",
        component: <Tags />,
      },
    ],
  },

  {
    type: "collapse",
    name: "Master",
    key: "master",
    icon: <Icon fontSize="medium">admin_panel_settings</Icon>,
    collapse: [
      {
        name: "Items",
        key: "items",
        route: "/master/items",
        component: <Items />,
      },
      {
        name: "Item Groups",
        key: "item_group",
        route: "/master/item_group",
        component: <Item_Group />,
      },
      // {
      //   name: "Item Groups",
      //   key: "item-groups",
      //   route: "/master/item-groups",
      //   component: <ItemGroups />,
      // },
      {
        name: "Account",
        key: "account",
        route: "/master/account",
        component: <Account />,
      },
      // {
      //   name: "Account Groups",
      //   key: "account-groups",
      //   route: "/master/account-groups",
      //   component: <AccountGroups />,
      // },
      {
        name: "Account Groups",
        key: "account_group",
        route: "/master/account_group",
        component: <Account_Group />,
      },
      {
        name: "Stamp",
        key: "stamp",
        route: "/master/stamp",
        component: <Stamp />,
      },
      {
        type: "collapse",
        name: "OP Stock",
        key: "open-stock",
        icon: <Icon fontSize="medium">admin_panel_settings</Icon>,
        collapse: [
          {
            name: "Tag Stock",
            key: "tag-stock",
            route: "/master/open-stock/tag-stock",
            component: <TagStock />,
          },
          {
            name: "Loose Stock",
            key: "loose-stock",
            route: "/master/open-stock/loose-stock",
            component: <LooseStock />,
          },
        ],
      },
    ],
  },

  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="medium">summarize</Icon>,
    collapse: [
      {
        name: "Ledger",
        key: "ledger",
        route: "/reports/ledger",
        component: <Ledger />,
      },
      {
        name: "Sales",
        key: "sales",
        route: "/reports/sales",
        component: <SaleReport />,
      },
      {
        name: "Payments",
        key: "payments",
        route: "/reports/payments",
        component: <Payments />,
      },
      {
        name: "Stocks",
        key: "stocks",
        route: "/reports/stocks",
        component: <Stocks />,
      },
      {
        name: "Tags",
        key: "tags",
        route: "/reports/tags",
        component: <AllTags />,
      },




    ],
  },

  // {
  //   type: "route",
  //   name: "User Activity",
  //   key: "user-activity",
  //   icon: <Icon fontSize="medium">insights</Icon>,
  //   route: "/user-activity",
  //   component: <UserActivity />,
  // },

  {
    type: "hidden",
    name: "Master",
    key: "master",
    collapse: [
      {
        name: "Add Item",
        key: "items",
        route: "/master/add-item",
        component: <AddItem />,
      },
      {
        name: "Edit Item",
        key: "items",
        route: "/master/edit-item/:id",
        component: <EditItem />,
      },
      {
        name: "Add Account",
        key: "account",
        route: "/master/add-account",
        component: <AddAccount />,
      },
      {
        name: "Edit Account",
        key: "account",
        route: "/master/edit-account/:id",
        component: <EditAccount />,
      },
    ],
  },

  {
    type: "hidden",
    name: "Authentication",
    key: "authentication",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-in",
            component: <SignIn />,
          },
        ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-up",
            component: <SignUp />,
          },
        ],
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/reset-password",
            component: <Reset />,
          },
        ],
      },
    ],
  },

  { type: "divider", key: "divider-1" },

  {
    type: "collapse",
    name: "Girvi",
    key: "girvi",
    icon: <Icon fontSize="medium">money_bag</Icon>,
    collapse: [
      {
        name: "Girvi Dashboard",
        key: "girvi-dashboard",
        route: "/master/girvi-dashboard",
        component: <GirviDashboard />,
      },
      {
        name: "New Girvi",
        key: "new-girvi",
        route: "/master/new-girvi",
        component: <NewGirvi />,
      },
      {
        name: "Girvi Payment",
        key: "girvi-payment",
        route: "/master/girvi-payment",
        component: <GirviPayment />,
      },
    ],
  },

  {
    type: "collapse",
    name: "Kitty",
    key: "kitty",
    icon: <Icon fontSize="medium">diversity_3</Icon>,
    collapse: [
      {
        name: "Kitty Dashboard",
        key: "kitty-dashboard",
        route: "/master/kitty-dashboard",
        component: <KittyDashboard />,
      },
      {
        name: "Add Kitty",
        key: "add-kitty",
        route: "/master/add-kitty",
        component: <AddKitty />,
      },
      {
        name: "Kitty Payment",
        key: "kitty-payment",
        route: "/master/girvi-payment",
        component: <KittyPayment />,
      },
    ],
  },

  { type: "divider", key: "divider-2" },

  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="medium">people</Icon>,
    collapse: [
      {
        name: "All Users",
        key: "user-list",
        route: "/users/user-list",
        component: <UserList />,
      },
      {
        name: "All Roles",
        key: "role-list",
        route: "/users/role-list",
        component: <RoleList />,
      },
    ],
  },

  {
    type: "hidden",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="medium">people</Icon>,
    collapse: [
      {
        type: "hidden",
        name: "New User",
        key: "new-user",
        route: "/users/new-user",
        component: <NewUser />,
      },
      {
        type: "hidden",
        name: "Edit User",
        key: "edit-user",
        route: "/users/edit-user/:email",
        component: <EditUser />,
      },
      {
        type: "hidden",
        name: "Add Roles",
        key: "add-roles",
        route: "/users/add-roles/",
        component: <AddRoles />,
      },
      {
        type: "hidden",
        name: "Edit Roles",
        key: "edit-roles",
        route: "/users/edit-roles/:role_name",
        component: <EditRoles />,
      },
    ],
  },

  {
    type: "collapse",
    name: "Settings",
    key: "account",
    icon: <Icon fontSize="medium">settings</Icon>,
    collapse: [
      {
        name: "Print Configuration",
        key: "print-configuration",
        route: "/settings/print-configuration",
        component: <InvoicePage />,
      },
      {
        name: "Profile",
        key: "settings",
        route: "/account/profile",
        component: <Profile />,
      },
      {
        name: "Invoice",
        key: "invoice",
        route: "/account/invoice",
        component: <Invoice2 />,
      },
    ],
  },

  {
    type: "logout",
    name: "Logout",
    icon: <Icon fontSize="medium">logout</Icon>,
    noCollapse: true,
  },

  {
    type: "hidden",
    name: "Authentication",
    key: "authentication",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-in",
            component: <SignIn />,
          },
        ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-up",
            component: <SignUp />,
          },
        ],
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/reset-password",
            component: <Reset />,
          },
        ],
      },
    ],
  },

  // {
  //   type: "collapse",
  //   name: "Products",
  //   key: "products",
  //   icon: <Icon fontSize="medium">shopping_cart</Icon>,
  //   collapse: [
  //     {
  //       name: "Blank Page",
  //       key: "blank-page",
  //       route: "/ecommerce/products/blank-page",
  //       component: <BlankPage />,
  //     },
  //     {
  //       name: "New Product",
  //       key: "new-product",
  //       route: "/ecommerce/products/new-product",
  //       component: <NewProduct />,
  //     },
  //     {
  //       name: "Edit Product",
  //       key: "edit-product",
  //       route: "/ecommerce/products/edit-product",
  //       component: <EditProduct />,
  //     },
  //     {
  //       name: "Product Page",
  //       key: "product-page",
  //       route: "/ecommerce/products/product-page",
  //       component: <ProductPage />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Orders",
  //   key: "orders",
  //   icon: <Icon fontSize="medium">receipt_long</Icon>,
  //   collapse: [
  //     {
  //       name: "Order List",
  //       key: "order-list",
  //       route: "/ecommerce/orders/order-list",
  //       component: <OrderList />,
  //     },
  //     {
  //       name: "Order Details",
  //       key: "order-details",
  //       route: "/ecommerce/orders/order-details",
  //       component: <OrderDetails />,
  //     },
  //   ],
  // },
];

export default routes;
