// import { useState } from "react";
import { useState, useContext, useEffect } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

//  React components
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// import NewUser layout schemas for form and form fields
// Assuming form and validations are already defined in schemas (fix imports accordingly)
import validations from "./components/validations";
import form from "./components/form";
import initialValues from "./components/initialValues";

import FormField from "./components/FormField";
import { Autocomplete, Divider } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
// import { groupCategoryOption } from "components/const";
import { methodOption } from "components/const";
// import { v4 as uuidv4 } from 'uuid';
import { useAuth } from "utils/AuthProvider";
import InputComponent from "components/InputComponent";
import ErrorText from "components/ErrorText";
import CustomDatepicker from "components/CustomDatepicker";
import { DataContext } from "context/dataContext";

// const axios = require('axios').default
import { NavLink, useNavigate } from "react-router-dom";
import SelectComponent from "components/SelectComponent";
import { MultiValueCompare } from "components/globalFunctions";


function AddAccount() {
  const { dataState, getSingleAccountApi } = useContext(DataContext);

  const { updateAccountApi } = useContext(DataContext);
  const navigate = useNavigate();

  const [value, setValue] = useState({
    account_type: null,
    name: "",
    contact_no: "",
    alternate_contact_no: "",
    date_of_birth: new Date(),
    address: "",

    // gold_fine_method: null,
    // gold_fine: 0,
    // silver_fine_method: null,
    // silver_fine: 0,
    material_method: null,
    material_weight: 0,
    amount_method: null,
    amount: 0,
  });






  const [errors, setErrors] = useState({
    account_type: "",
    name: "",

    weight: "",
    amount: "",
  });

  const validateForm = () => {
    const newErrors = {
      account_type: value.account_type ? "" : "Accounts Category is required",
      name: value.name ? "" : "Name is required",

      weight: (
        (value.material_method && value.material_weight) || (!value.material_method && !value.material_weight)
      ) ? "" : "Both Material Method and Weight must be filled or left empty",
      amount: (
        (value.amount_method && value.amount) || (!value.amount_method && !value.amount)
      ) ? "" : "Both Amount Method and Amount must be filled or left empty"
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");

  };

  const handleSubmit = () => {
    if (validateForm()) {
      updateAccountApi(value).then((res) => {
        if (res.success) {
          navigate("/master/account");
        } else {
          console.error("Something went wrong");
        }
      });
    }
  };

  const updateValue = (param, val, index, decimalCounting) => {
    let allTypes = ['material_method', 'amount_method']

    if (val === 'date_of_birth') {
      setValue((prevData) => ({
        ...prevData,
        [val]: param
      }));
    } else {
      setValue((prevData) => ({
        ...prevData,
        [param]: MultiValueCompare(allTypes, param) ? (val?.status) : val
      }));
    }

  };








  useEffect(() => {
    let url = window.location.href;
    let urlParts = url.split('/');
    let id = urlParts[urlParts.length - 1];

    getSingleAccountApi(id).then((res) => {
      console.log('getSingleAccountApi', res);

      if (res.success) {
        let resData = res?.data?.data?.account
        console.log('getSingleAccountApi resData', res?.data?.data);

        setValue({
          id: resData?.id || '',
          name: resData?.name || '',
          account_type: resData?.account_type || null,
          contact_no: resData?.contact_no || '',
          alternate_contact_no: resData?.alternate_contact_no || '',
          date_of_birth: resData?.date_of_birth || '',
          address: resData?.address || '',
          // gold_fine_method: resData?.gold_fine_method || null,
          // gold_fine: resData?.gold_fine || 0,
          // silver_fine_method: resData?.silver_fine_method || null,
          // silver_fine: resData?.silver_fine || 0,
          material_method: resData?.material_method || null,
          material_weight: resData?.material_weight || 0,
          amount_method: resData?.amount_method || null,
          amount: resData?.amount || 0,
        })

      } else {
        console.log('Something went wrong');
      }
    });

  }, [])









  let account_groups_and_account_group_type = [...dataState.account_groups, ...dataState.account_group_type]



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={2} mb={2}>
        <Grid container justifyContent="center" alignItems="center" gap={2} sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <MDBox display="flex" alignItems="center" gap={2}>
              <MDButton variant="gradient" color="primary" component={NavLink} to="/master/account"> Go Back </MDButton>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card autoComplete="off" sx={{ height: "100%" }}>
              <MDBox p={3} pb={0}>
                <MDTypography variant="h5">Edit - {value.name || 'Account'}</MDTypography>
              </MDBox>
              <MDBox p={3}>
                <MDBox component="form" pb={3}>
                  <Grid container spacing={3}>

                    {/* Name Field */}
                    <Grid item xs={12} sm={3}>
                      <InputComponent
                        optionName="name"
                        data={value}
                        updateDataFunction={updateValue}
                        label="Name"
                        inputType="text"
                        inputStyle="outlined"
                        autoFocus={true}
                        required={true}
                      />
                      {errors.name && <ErrorText errorName={errors.name} />}
                    </Grid>

                    {/* Group Field */}
                    <Grid item xs={12} sm={3}>
                      <SelectComponent
                        optionName="account_type"
                        data={value}
                        updateDataFunction={updateValue}
                        // options={groupCategoryOption}
                        options={account_groups_and_account_group_type}
                        label="Account Type"
                        inputStyle="outlined"
                        required={true}
                      />
                      {errors.account_type && <ErrorText errorName={errors.account_type} />}
                    </Grid>

                    {/* Contact No Field */}
                    <Grid item xs={12} sm={3}>
                      <InputComponent
                        optionName="contact_no"
                        data={value}
                        updateDataFunction={updateValue}
                        label="Contact No"
                        inputType="number"
                        decimalCounting={0}
                        maxLimit={15}
                        inputStyle="outlined"
                      />
                    </Grid>

                    {/* Alternate Contact No Field */}
                    <Grid item xs={12} sm={3}>
                      <InputComponent
                        optionName="alternate_contact_no"
                        data={value}
                        updateDataFunction={updateValue}
                        label="Alternate Contact No"
                        inputType="number"
                        decimalCounting={0}
                        maxLimit={15}
                        inputStyle="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <InputComponent
                        optionName="address"
                        data={value}
                        updateDataFunction={updateValue}
                        label="Address"
                        inputType="text"
                        inputStyle="outlined"
                      />
                    </Grid>


                    <Grid item xs={12} sm={3}>
                      <MDBox sx={{ width: '100%', position: 'relative' }}>
                        <CustomDatepicker
                          value={value?.date_of_birth || new Date()}
                          valueKey="date_of_birth"
                          editValue={updateValue}
                          label="Date of Birth"
                          inputStyle="outlined"
                        />
                      </MDBox>
                    </Grid>


                    <Grid item xs={12} sm={12} mt={5}>
                      <MDTypography variant="h5">Opening Balance Amount</MDTypography>
                    </Grid>

                    <Grid container item spacing={3}>

                      <Grid item xs={12} sm={3}>
                        <Grid display="flex" gap={2}>
                          <SelectComponent
                            optionName="material_method"
                            data={value}
                            updateDataFunction={updateValue}
                            options={methodOption}
                            label='Method'
                            inputStyle="outlined"
                            itsMinWidth={80}
                          />
                          <InputComponent
                            optionName="material_weight"
                            data={value}
                            updateDataFunction={updateValue}
                            label="Material Weight"
                            decimalCounting={3}
                            maxLimit={7}
                            inputStyle="outlined"
                          />
                        </Grid>
                        {errors.weight && <ErrorText errorName={errors.weight} />}
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <Grid display="flex" gap={2}>
                          <SelectComponent
                            optionName="amount_method"
                            data={value}
                            updateDataFunction={updateValue}
                            options={methodOption}
                            label='Method'
                            inputStyle="outlined"
                            itsMinWidth={80}
                          />
                          <InputComponent
                            optionName="amount"
                            data={value}
                            updateDataFunction={updateValue}
                            label="Amount"
                            decimalCounting={2}
                            maxLimit={10}
                            inputStyle="outlined"
                          />
                        </Grid>
                        {errors.amount && <ErrorText errorName={errors.amount} />}
                      </Grid>

                    </Grid>

                  </Grid>
                </MDBox>

                <MDBox mt={2} gap={2} width="100%" display="flex" justifyContent="flex-start">
                  <MDButton variant="gradient" color="primary" onClick={handleSubmit}>
                    Edit This Account
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout >
  );
}

export default AddAccount;