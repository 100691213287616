import MDBox from 'components/MDBox'
import React from 'react'

const FM_ButtonGroup = ({ children }) => {
  return (
    <MDBox display="flex" ml="auto"alignItems="center"  gap={2}>
      {children}
    </MDBox>
  )
}

export default FM_ButtonGroup