import IdCell from "./IdCell";
import DefaultCell from "components/DataTable/DefaultCell";
 

import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import moment from "moment";
import { SetFormattedIndianNumber } from "components/globalFunctions";
// import { NavLink } from "react-router-dom";

const dataTableData = {
  columns: [
    // {
    //   Header: "ID",
    //   accessor: "id",
    //   width: 10,
    //   link: '/transactions/payment',
    //   // linkByID: true,
    //   Cell: ({ value }) => <IdCell id={value} />,
    // },
    {
      Header: "Date",
      accessor: "createdAt",
      link: '/transactions/payment',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={moment(value).format('DD-MM-YYYY')} />
    },
    {
      Header: "Bill#",
      accessor: "voucher_id",
      link: '/transactions/payment',
      // linkByID: true,
      Cell: ({ value, row }) => {
        return <DefaultCell value={(row?.original?.prefix).toUpperCase() + '-' + value} />
      }    
    },
    {
      Header: "Party Name",
      accessor: "user_id",
      link: '/transactions/payment',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Type",
      accessor: "type",
      link: '/transactions/payment',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Fine",
      accessor: "fine",
      align: "right",
      link: '/transactions/payment',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
    },
    {
      Header: "Amount",
      accessor: "amount",
      align: "right",
      link: '/transactions/payment',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value)} />
    },
    {
      Header: "Method",
      accessor: "method",
      align: "right",
      link: '/transactions/payment',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Fine Balance",
      accessor: "fine_balance",
      align: "right",
      link: '/transactions/payment',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(0, 3)} />
    },
    {
      Header: "Amount Balance",
      accessor: "ount_balance",
      align: "right",
      link: '/transactions/payment',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(0)} />
    },
    // {
    //   Header: "Actions",
    //   accessor: "actions",
    //   width: 10,
    //   align: "right",
    //   // Cell: (
    //   //   <MDBox display="flex" gap={2}>
    //   //     <MDButton size="small" variant="gradient" color="primary" component={NavLink} to={`/master/edit-account/${row.original.id}`}>Edit</MDButton>
    //   //     <MDButton size="small" variant="outlined" color="primary">Delete</MDButton>
    //   //   </MDBox>
    //   // ),
    //   Cell: ({ row }) => (
    //     <MDBox display="flex" gap={2}>
    //       {/* <MDButton size="small" variant="gradient" color="primary" component={NavLink} to={`/master/edit-sale/${row.original.id}`}>Edit</MDButton> */}
    //       <MDButton size="small" variant="outlined" color="primary">Delete</MDButton>
    //     </MDBox>
    //   ),
    // },
  ],

  rows: [],
};

export default dataTableData;
