

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";

function SidenavList({ miniSidenav, children }) {
  return (
    <List
      sx={{
        my: 0.3,

        ...miniSidenav ? {
          px: 2,
        } : {
          px: 2,
        }
      }}
    >
      {children}
    </List>
  );
}

// Typechecking props for the SidenavItem
SidenavList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SidenavList;
