import React, { useContext } from 'react'

import { Autocomplete, Grid } from '@mui/material'
import CustomDatepicker from 'components/CustomDatepicker'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import { useState, useEffect } from 'react'
// import { useAuth } from '../../../../utils/AuthProvider'
import { DataContext } from "context/dataContext";
import ErrorText from 'components/ErrorText'
import AddAccountPopup from 'layouts/transactions/components/AddAccountPopup'
import { addDays } from 'date-fns'
import { DateRange } from 'react-date-range';
import FilterMenuContainer from 'components/FilterMenu/FilterMenuContainer'
import FM_Button1 from 'components/FilterMenu/FM_Button1'
import FM_Button2 from 'components/FilterMenu/FM_Button2'
import FM_ButtonGroup from 'components/FilterMenu/FM_ButtonGroup'
import moment from 'moment'
import MonthsSelectComponent from 'components/MonthsSelecComponent'

// const axios = require('axios').default

const FilterMenu = () => {

  // const { cookies } = useAuth();
  // axios.defaults.headers.common = { 'Authorization': `Bearer ${cookies.token}` }

  const { dataState, getLedgersApi } = useContext(DataContext)


  const [filterMenuData, setFilterMenuData] = useState({
    // user_name: "",
    // user_id: null,
    user_name: "KASHISH JEWELLERS",
    user_id: 109,
    selected_date: "",
  });

  // Add Account Popup State
  // const [addAccountPopupState, setAddAccountPopupState] = useState(false);

  const [newAccountData, setNewAccountData] = useState(false)



  const [allItems, setAllItems] = useState([]);



  let today = moment().format("MMM DD, YYYY");
  let oneMonthAgo = moment().subtract(1, 'months').format("MMM DD, YYYY");

  const [startDate, setStartDate] = useState(oneMonthAgo);
  const [endDate, setEndDate] = useState(today);
  const [dateopen, setDateOpen] = useState(false);








  const [datestate, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection'
    }
  ]);
  const [origselection, setOrigSelection] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection'
    }
  ]);

  const handelToUpdateFilterMenuData = (value, key) => {
    setFilterMenuData(prevData => ({
      ...prevData,
      [key]: value,
    }));
    // console.log(userInfo)
  }

  function resetParams() {
    setFilterMenuData(prevData => ({
      ...prevData,
      user_name: "",
      user_id: null,
    }));

    setStartDate(oneMonthAgo)
    setEndDate(today)
    setDateState(origselection)
  }

  useEffect(() => {
    var accounts = dataState.all_accounts
    var accountitems = []
    accounts.forEach((cus, i) => {
      accountitems.push({ label: cus.name, id: cus.id, phone: cus.contact_no, _id: cus._id })
    });
    setAllItems(accountitems)
  }, [dataState.all_accounts]);


















  // Managing Add Account Popup
  // const handleToManageAddAccountPopup = async () => {
  //   setAddAccountPopupState(true)
  // }





  // Add New Account Data to DropDown
  useEffect(() => {
    if (newAccountData) {
      handelToUpdateFilterMenuData(dataState.all_accounts[0]?.name || '', 'user_name')
      handelToUpdateFilterMenuData(dataState.all_accounts[0]?.id || null, 'user_id')
    }
  }, [dataState?.all_accounts])







  const handleToSearch = () => {
    filterMenuData.user_id && getLedgersApi(filterMenuData.user_id, startDate || null, endDate || null)
  }
















  return (
    <>


      <FilterMenuContainer>



        <MDBox sx={{ width: '100%', maxWidth: '25%' }} >
          <Autocomplete
            popupIcon={''}
            value={filterMenuData.user_name}
            options={allItems}
            onChange={(_, newValue) => {
              console.log('newValue', newValue);
              handelToUpdateFilterMenuData(newValue?.label || '', 'user_name')
              handelToUpdateFilterMenuData(newValue?.id || null, 'user_id')
              setNewAccountData(false)
            }}


            sx={{
              '& .MuiFormControl-root': { background: 'initial' },
            }}

            renderInput={(params) => (
              <MDInput
                {...params}
                label="Customer"
                variant="standard"
              // error={userInfoValidation.user_name && userInfo?.user_id === null}
              />
            )}
          />
          {/* {userInfoValidation.user_name && userInfo?.user_id === null && (
              <ErrorText errorName="Customer is required" />
              )} */}
        </MDBox>
        <MDBox sx={{ width: '100%', maxWidth: '25%', position: 'relative'}}>
              <MonthsSelectComponent setStartDate={setStartDate} setEndDate={setEndDate}/>
        </MDBox>







        <MDBox sx={{ width: '100%', maxWidth: '25%', position: 'relative' }}>
          <MDInput
            variant="standard"
            label="Select Date"
            value={startDate && endDate ? (startDate + ' - ' + endDate) : ''}
            placeholder="Select Range"
            onClick={() => setDateOpen(!dateopen)}
            onFocus={() => setDateOpen(!dateopen)}
            // onBlur={() => setDateOpen(!dateopen)}
            style={{ width: '100%' }}
            type='text'
            autoComplete="off"
          />
          {dateopen ? <>
            <div
              onClick={() => setDateOpen(false)}
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1200,
              }}
            ></div>
            <MDBox sx={{
              position: 'absolute',
              zIndex: 4,
              left: 0,
              top: '3.5rem',
              padding: '2',
              backgroundColor: '#fff',
              border: '1px solid #d2d6da',
              borderRadius: '8px',
              boxShadow: '2px 2px 5px -3px rgb(0 0 0 / 30%)',
              overflow: 'hidden',
              zIndex: 1201,
            }}>
              <DateRange
                rangeColors={['#AF891F']}
                // maxDate={addDays(new Date(), 0)}

                onChange={item => {
                  console.log(item.selection)
                  setDateState([item.selection])

                  var options = { year: 'numeric', month: 'short', day: 'numeric' };

                  (new Date(item.selection.startDate).toLocaleDateString("en-US", options))
                  setEndDate(new Date(item.selection.endDate).toLocaleDateString("en-US", options))
                  if (item.selection.startDate !== item.selection.endDate) {
                    setDateOpen(false)
                  }

                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={datestate}
                direction="vertical"
              />
            </MDBox>
          </> : ''}
        </MDBox>



        <FM_ButtonGroup>
          <FM_Button2
            actionButton={handleToSearch}
          />
          <FM_Button1
            actionButton={resetParams}
          />
        </FM_ButtonGroup>

      </FilterMenuContainer>







    </>
  )
}

export default FilterMenu