import React, { useContext, useEffect, useState } from 'react'
import { Autocomplete, Grid } from '@mui/material'
import CustomDatepicker from 'components/CustomDatepicker'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import { DataContext } from 'context/dataContext'
import { ConditionalCompare } from 'layouts/transactions/components/functions'
import FilterMenuContainer from 'components/FilterMenu/FilterMenuContainer'
import FM_Button1 from 'components/FilterMenu/FM_Button1'
import ErrorText from 'components/ErrorText'

const FilterMenu = ({
  stockInfo,
  setStockInfo,
  filterMenuValidation
}) => {
  const { dataState } = useContext(DataContext)
  const [allItems, setAllItems] = useState([]);

  let items_groups_and_item_group_type = [...dataState.items_groups, ...dataState.item_group_type]


  const handelToUpdateInfo = (value, key) => {
    setStockInfo(prevData => ({
      ...prevData,
      [key]: value,
    }));
  }



  function resetParams() {
    setStockInfo(prevData => ({
      ...prevData,
      selected_on: new Date(),
      type: null,
      user_name: "",
      user_id: null,
    }));
  }













  return (
    <>


      <FilterMenuContainer>



        <MDBox sx={{ width: '100%', maxWidth: '25%', position: 'relative' }}>
          <CustomDatepicker
            value={stockInfo.selected_on || new Date()}
            valueKey="selected_on"
            editValue={handelToUpdateInfo}
            label="Select Date"
          />
        </MDBox>


        <MDBox sx={{ width: '100%', maxWidth: '25%', position: 'relative' }}>
          <Autocomplete
            value={stockInfo.type}
            options={["Vendor", "Loose Stock"]}
            onChange={(_, newValue) => {
              handelToUpdateInfo(newValue || null, 'type');
            }}
            sx={{
              '& .MuiFormControl-root': { background: 'initial' },
              ...(filterMenuValidation.type && stockInfo?.type === null && {
                '& .MuiInput-root:before': { borderColor: '#F44335' },
                '& .MuiInputLabel-root': { color: '#F44335' },
              }),
            }}
            renderInput={(params) => (
              <MDInput
                {...params}
                label="Type"
                variant="standard"
                error={filterMenuValidation.type && stockInfo?.type === null}
              />
            )}
          />
          {filterMenuValidation.type && stockInfo?.type === null && (
            <ErrorText errorName="Tag type is required" />
          )}
        </MDBox>











        {stockInfo.type === "Vendor" && (
          <MDBox sx={{ width: '100%', maxWidth: '25%' }} >
            <Autocomplete
              popupIcon={''}

              value={dataState.all_accounts.find(group => group.id === stockInfo.user_id) || null}
              options={dataState.all_accounts}
              getOptionLabel={(innerOptions) => innerOptions.name || ""}

              onChange={(_, newValue) => {
                console.log('newValue', newValue);
                handelToUpdateInfo(newValue?.id || null, 'user_id')
              }}
              sx={{
                '& .MuiFormControl-root': { background: 'initial' },
                ...(filterMenuValidation.user_id && stockInfo?.user_id === null && {
                  '& .MuiInput-root:before': { borderColor: '#F44335' },
                  '& .MuiInputLabel-root': { color: '#F44335' },
                }),
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label="Customer"
                  variant="standard"
                  error={filterMenuValidation.user_id && stockInfo?.user_id === null}
                />
              )}
            />
            {filterMenuValidation.user_id && stockInfo?.user_id === null && (
              <ErrorText errorName="Customer is required" />
            )}
          </MDBox>
        )}


        {stockInfo.type === "Loose Stock" && (
          <MDBox sx={{ width: '100%', maxWidth: '25%' }} >
            <Autocomplete
              popupIcon={''}

              value={items_groups_and_item_group_type.find(group => group.id === stockInfo.item_group_id) || null}
              options={items_groups_and_item_group_type}
              getOptionLabel={(innerOptions) => innerOptions.name || ""}

              onChange={(_, newValue) => {
                handelToUpdateInfo(newValue?.id || null, 'item_group_id')
              }}
              sx={{
                '& .MuiFormControl-root': { background: 'initial' },
                ...(filterMenuValidation.item_group_id && stockInfo?.item_group_id === null && {
                  '& .MuiInput-root:before': { borderColor: '#F44335' },
                  '& .MuiInputLabel-root': { color: '#F44335' },
                }),
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label="Item Groups"
                  variant="standard"
                  error={filterMenuValidation.item_group_id && stockInfo?.item_group_id === null}
                />
              )}
            />
            {filterMenuValidation.item_group_id && stockInfo?.item_group_id === null && (
              <ErrorText errorName="Item Groups is required" />
            )}
          </MDBox>
        )}



        <FM_Button1
          actionButton={resetParams}
        />

      </FilterMenuContainer>



    </>
  )
}

export default FilterMenu