

import { useState, useEffect, useContext } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";

import { v4 as uuidv4 } from 'uuid';
// import SalePanelRow from "../../transactions/components/SalePanelRow";
import {
  // ConditionalCompare,
  GetCalculationOf_ItemFine,
  GetCalculationOf_ItemTotal,
  GetCalculationOf_Netwt,
  // GetCalculationOf_Row2_Fine,
  TotalCalculationOf_Fine,
  // TotalCalculationOf_GrandFine,
  // TotalCalculationOf_GrandTotal,
  TotalCalculationOf_ItemTotal,
  TotalCalculationOf_Grwt,
  TotalCalculationOf_Lbr,
  TotalCalculationOf_Less,
  TotalCalculationOf_Netwt,
  TotalCalculationOf_Pieces,
  TotalCalculationOf_Wstg
} from "../../transactions/components/functions";
// import { TableContainer } from "@mui/material";
// import GrandTotal from "../../transactions/components/GrandTotal";
import RoughInvoice from "layouts/invoice/components/RoughInvoice";

import FilterMenu from "./components/FilterMenu";
// import { randomString } from "helpers/randomString";
import { useAuth } from "utils/AuthProvider";

import { SetFormattedIndianNumber } from "components/globalFunctions";
import { SetNumbersDecimals } from "components/globalFunctions";
import { DataContext } from "context/dataContext";
import SaleItemRow from "../../transactions/components/SaleItemRow";
import { flowType_In_Out } from "components/const";



const axios = require('axios').default


function TagStock() {

  const { cookies } = useAuth();
  axios.defaults.headers.common = { 'Authorization': `Bearer ${cookies.token}` }

  const { createLooseStockApi } = useContext(DataContext)

  // const [menu, setMenu] = useState(null);
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');




  const [itemTableHeaderData, setItemTableHeaderData] = useState([
    { name: "Item", align: "left", key: "item" },
    { name: "Type", align: "left", key: "type", visibilty: true },
    { name: "Stamp", align: "right", key: "stamp", visibilty: true },
    { name: "Unit", align: "right", key: "unit", visibilty: true },
    { name: "Pc", align: "right", key: "pc", visibilty: true },
    { name: "Gr.Wt", align: "right", key: "grwt", visibilty: true },
    { name: "Less", align: "right", key: "less", visibilty: true },
    { name: "Net.Wt", align: "right", key: "netwt", visibilty: true },
    { name: "Tunch", align: "right", key: "tunch", visibilty: true },
    { name: "Wstg", align: "right", key: "wstg", visibilty: true },

    { name: "Size", align: "right", key: "size", visibilty: false },
    { name: "UID", align: "right", key: "uid", visibilty: false },
    { name: "D. Carats", align: "right", key: "d_carats", visibilty: false },
    { name: "Color / Clarity", align: "right", key: "color_clarity", visibilty: false },
    { name: "Remarks", align: "right", key: "remarks", visibilty: false },

    { name: "Rate", align: "right", key: "rate", visibilty: true },
    { name: "Lbr", align: "right", key: "lbr", visibilty: true },
    { name: "On", align: "right", key: "on", visibilty: true },
    { name: "Fine", align: "left", key: "fine", visibilty: true },
    { name: "Total", align: "right", key: "total", visibilty: true },
  ]);



  const [saleItems, setSaleItems] = useState([{
    id: uuidv4(),
    item: null,
    type: "inflow",
    stamp: null,
    unit: null,
    pc: 0,
    grwt: 0.000,
    less: 0.000,
    less_details: [],
    netwt: 0.000,
    tunch: 0.00,
    wstg: 0.00,

    size: "",
    qty: 0.00,
    uid: "",
    d_carats: 0.00,
    color_clarity: "",
    remarks: "",

    rate: 0.00,
    lbr: 0.00,
    on: 'Wt',
    fine: 0.000,
    total: 0.00,
  }]);
  // const [saleItems, setSaleItems] = useState([
  //   { "id": "46382a5f-9ef7-4143-aa82-31a85f8d8be2", "item": "0b0acf3e-752e-467c-a3ab-c02978a74255", "type": "inflow", "stamp": "24K", "unit": "Gm", "pc": "1", "grwt": "1.000", "less": "0.000", "netwt": "30.000", "tunch": "99.00", "wstg": "1.00", "rate": "0.00", "lbr": "0.00", "on": "Wt", "fine": "30.000", "total": "3000.00" },
  //   { "id": "46382a5f-9ef7-4143-aa82-31a85f8d8be2", "item": "5a33d149-9537-4372-b84b-ab417fa10f99", "type": "outflow", "stamp": "24K", "unit": "Gm", "pc": "1", "grwt": "40.000", "less": "10.000", "netwt": "30.000", "tunch": "99.00", "wstg": "1.00", "rate": "0.00", "lbr": "100.00", "on": "Wt", "fine": "30.000", "total": "3000.00" },
  //   { "id": "2f239c62-f177-44ed-b2cc-16afcc5c1fc6", "item": "4b8b4b51-2a17-4bb3-ad69-afcfc4161dc8", "type": "outinflow", "stamp": "18K", "unit": "Pc", "pc": "40", "grwt": "10.000", "less": "0.000", "netwt": "10.000", "tunch": "99.00", "wstg": "1.00", "rate": "1000.00", "lbr": "100.00", "on": "PC", "fine": "10.000", "total": "4000.00" }
  // ]);

  // const [panels, setPanels] = useState([{
  //   id: uuidv4(),
  //   type: 'inflow',
  //   method: null,
  //   miaGoldBhav: true,
  //   bank: null,
  //   useAsGoldBhav: false,
  //   amount: '0.000',
  //   grwt: '0.000',
  //   tunch: '100.00',
  //   fine: '0.000',
  //   rate: '0.00',
  // }]);
  // const [panels, setPanels] = useState([
  //   { "id": "610bc761-9908-446e-b8a8-c27a31049cae", "type": "inflow", "method": "cash", "miaGoldBhav": true, "useAsGoldBhav": true, "amount": 14600, "grwt": 2.0277777777777777, "tunch": "100.00", "fine": "2.0277777777777777", "rate": "7200.00", "bank": null },
  //   { "id": "610bc762-9908-446e-b8a8-c27a31049caf", "type": "inflow", "method": "gold bhav", "miaGoldBhav": false, "useAsGoldBhav": false, "amount": 15400, "grwt": 2.138888888888889, "tunch": "100.00", "fine": "2.138888888888889", "rate": 7200, "bank": null },
  //   { "id": "610bc761-9908-446e-b8a8-c27a31049caf", "type": "inflow", "method": "metal", "miaGoldBhav": true, "useAsGoldBhav": false, "amount": "0.00", "grwt": 20.428, "tunch": 92.67, "fine": "18.9306276", "rate": "0.00", "bank": null },
  //   { "id": "fb00c27f-bcae-4db8-84f1-ba9eea83747f", "type": "inflow", "method": "cash", "miaGoldBhav": true, "useAsGoldBhav": false, "amount": 10000, "grwt": "0.000", "tunch": "100.00", "fine": "0", "rate": "0.00", "bank": null },
  // ]);



  // const [grandTotal, setGrandTotal] = useState({
  //   id: uuidv4(),
  //   balance: {
  //     fine: "0.000",
  //     fine_type: null,
  //     total: "0.00",
  //     total_type: null,
  //   },

  //   sale_fine: "0.000",
  //   sale_total: "0.00",

  //   return_fine: "0.000",
  //   return_total: "0.00",

  //   sub_total: {
  //     fine: "0.000",
  //     fine_type: 'inflow',
  //     total: "0.00",
  //     total_type: 'inflow',
  //   },

  //   adjustments_fine: "0.0",
  //   adjustments_type: "percentage",
  // });

  const [itemfinals, setItemFinals] = useState({
    id: uuidv4(),
    pcs: "0",
    grwt: "0.000",
    less: "0.000",
    ntwt: "0.000",

    wstg: "0.00",
    lbr: "0.00",

    fine: "0.000",
    total: "0.00",
  });




  // const [userInfo, setUserInfo] = useState({
  //   user_name: "",
  //   bill_number: "",
  //   phone_number: ""
  // });

  const [userInfo, setUserInfo] = useState({
    item_group: null,
    // bill_number: "",
    selected_date: ""
  });



  const [userInfoValidation, setUserInfoValidation] = useState({
    item_group: false,
  });

  const [errorForItem, setErrorForItem] = useState(false)




  let newRow = {
    id: uuidv4(),
    item: null,
    type: "inflow",
    stamp: null,
    unit: null,
    pc: 0,
    grwt: 0.000,
    less: 0.000,
    less_details: [],
    netwt: 0.000,
    tunch: 0.00,
    wstg: 0.00,

    size: "",
    qty: 0.00,
    uid: "",
    d_carats: 0.00,
    color_clarity: "",
    remarks: "",

    rate: 0.00,
    lbr: 0.00,
    on: 'Wt',
    fine: 0.000,
    total: 0.00,
  }





  function addRow() {
    console.log('saleItems', saleItems)
    var rowsNew = saleItems
    rowsNew.push(newRow)

    setTimeout(() => {
      setSaleItems([])
      setSaleItems(rowsNew)
    }, 100);

  }





  // function addRow2() {
  //   var rowsNew = panels
  //   rowsNew.push(
  //     {
  //       id: uuidv4(),
  //       type: 'inflow',
  //       method: '',
  //       miaGoldBhav: true,
  //       useAsGoldBhav: false,
  //       amount: '0.000',
  //       grwt: '0.000',
  //       tunch: '100.00',
  //       fine: '0.000',
  //       rate: '0.00',
  //     }
  //   )

  //   setTimeout(() => {
  //     setPanels([])
  //     setPanels(rowsNew)
  //   }, 100);

  // }


  function deleteRow(i) {
    if (saleItems.length <= 1) {
      deleteRowAction(i)
      addRow()
    }
    else {
      deleteRowAction(i)
    }
  }

  function deleteRowAction(i) {
    const index = i
    var saleitems = saleItems
    if (index > -1) {
      saleitems.splice(index, 1);
    }
    setTimeout(() => {
      setSaleItems([])
      setSaleItems(saleitems)
    }, 100);
  }



  // function deleteRow2(i) {
  //   const index = i
  //   var panelitems = panels
  //   if (index > -1) {
  //     panelitems.splice(index, 1);
  //   }
  //   setTimeout(() => {
  //     setPanels([])
  //     setPanels(panelitems)
  //   }, 100);
  // }
  // function deleteRow2Action(i) {
  //   const index = i
  //   var panelitems = panels
  //   if (index > -1) {
  //     panelitems.splice(index, 1);
  //   }
  //   setTimeout(() => {
  //     setPanels([])
  //     setPanels(panelitems)
  //   }, 100);
  // }














































































  const [disablebuttons, setDisablebuttons] = useState({
    save: false,
    save_print: false,
  })



  const handelToDisableButton = (value, key) => {
    setDisablebuttons(prevData => ({
      ...prevData,
      [key]: value,
    }));
  }


  const collectAndUpdateStateInfo = () => {



    const updatedUserInfo = {
      ...userInfo,
    };
    // setUserInfo(updatedUserInfo);





    const updatedItems = saleItems.map((item) => ({
      ...item,
      netwt: GetCalculationOf_Netwt(item),
      fine: GetCalculationOf_ItemFine(item),
      total: GetCalculationOf_ItemTotal(item),
    }));
    setSaleItems(updatedItems);







    // const updatedPanels = panels.map((item) => ({
    //   ...item,
    //   fine: GetCalculationOf_Row2_Fine(item),
    // }));
    // setPanels(updatedPanels);


    const updateItemFinals = {
      ...itemfinals,
      pcs: TotalCalculationOf_Pieces(saleItems),
      grwt: SetNumbersDecimals(TotalCalculationOf_Grwt(saleItems), 3),
      less: SetNumbersDecimals(TotalCalculationOf_Less(saleItems), 3),
      ntwt: SetNumbersDecimals(TotalCalculationOf_Netwt(saleItems), 3),

      wstg: SetNumbersDecimals(TotalCalculationOf_Wstg(saleItems), 2),
      lbr: SetFormattedIndianNumber(TotalCalculationOf_Lbr(saleItems), 3),

      fine: SetFormattedIndianNumber(TotalCalculationOf_Fine(saleItems), 3),
      total: SetFormattedIndianNumber(TotalCalculationOf_ItemTotal(saleItems), 2),
    }
    setItemFinals(updateItemFinals);






    // const updatedGrandTotal = {
    //   ...grandTotal,
    //   sale_fine: TotalCalculationOf_Fine(
    //     saleItems.filter((item) => ConditionalCompare(item.type, '===', 'outflow'))
    //   ),
    //   sale_total: TotalCalculationOf_ItemTotal(
    //     saleItems.filter((item) => ConditionalCompare(item.type, '===', 'outflow'))
    //   ),
    //   return_fine: Math.abs(Number(
    //     TotalCalculationOf_Fine(
    //       saleItems.filter((item) => ConditionalCompare(item.type, '===', 'inflow') || ConditionalCompare(item.type, '===', 'outinflow'))
    //     )
    //   )),
    //   return_total: Math.abs(Number(
    //     TotalCalculationOf_ItemTotal(
    //       saleItems.filter((item) => ConditionalCompare(item.type, '===', 'inflow') || ConditionalCompare(item.type, '===', 'outinflow'))
    //     )
    //   )),
    //   sub_total: {
    //     ...grandTotal.sub_total,
    //     fine: Math.abs(Number(TotalCalculationOf_GrandFine(saleItems, panels))),
    //     fine_type: TotalCalculationOf_GrandFine(saleItems, panels) <= 0 ? (TotalCalculationOf_GrandFine(saleItems, panels) === 0 ? null : "inflow") : "outflow",
    //     total: Math.abs(Number(TotalCalculationOf_GrandTotal(saleItems, panels))),
    //     total_type: TotalCalculationOf_GrandTotal(saleItems, panels) <= 0 ? (TotalCalculationOf_GrandTotal(saleItems, panels) === 0 ? null : "inflow") : "outflow",
    //   },
    // };
    // setGrandTotal(updatedGrandTotal);


    // console.log('Old Data ', { items: saleItems, panels: panels, total: grandTotal })

    // return { user: updatedUserInfo, items: updatedItems, panels: updatedPanels, total: updatedGrandTotal, itemfinals: updateItemFinals }
    return { user: updatedUserInfo, items: updatedItems }

  }






  // function saveSale(newData, print) {
  //   if (newData.user.bill_number !== '' && newData.user.selected_date !== '' && newData.user.user_id !== '') {
  //     var items = newData.items

  //     createBillApi(newData).then((response) => {
  //       console.log(response);
  //     })

  //     items.forEach((itm, i) => {
  //       var saleid = itm.type === 'outflow' ? uuidv4() : ''
  //       var paymentid = itm.type === 'infow' || itm.type === 'outinflow' ? uuidv4() : ''
  //       addToLedgerApi(newData, itm, saleid, paymentid).then((response) => {
  //         console.log(response);
  //       })


  //     });

  //     setTimeout(() => {
  //       handelToDisableButton(false, 'save')
  //       alert('Save Successful')
  //       if (print) {
  //         setInvoicePrintState(true)
  //         handelToDisableButton(false, 'save_print')
  //       }
  //     }, 2000);

  //   } else {
  //     alert('Please enter the bill number, date and customer')
  //     handelToDisableButton(false, 'save')
  //     handelToDisableButton(false, 'save_print')
  //     //setInvoicePrintState(true)
  //   }
  // }








  const saveSaleData = () => {
    handelToDisableButton(true, 'save')
    console.log('saveSaleData =================>');
    console.log('New Data ', collectAndUpdateStateInfo());
    const newData = collectAndUpdateStateInfo();
    saveLoose(newData, false)
  }




  // Print State To Manage Invoice Print
  const [roughPrintState, setInvoicePrintState] = useState(false);





  const [saleData, setSaleData] = useState(null);

  const savePrintSaleData = () => {
    handelToDisableButton(true, 'save_print')
    console.log('savePrintSaleData =================>');
    // console.log('New Data ', collectAndUpdateStateInfo());

    const newData = collectAndUpdateStateInfo();
    setSaleData(newData);

    saveLoose(newData, true)
  }


  // const cancelSaleData = () => {
  //   console.log('cancelSaleDatav =================>');

  // }





























  function saveLoose(newData, print) {

    const allItemsValid = newData.items.every((val) => {
      return val.item && val.type && val.stamp && val.unit && val.pc && val.grwt && val.tunch && (val.grwt >= val.less)
    });
    setErrorForItem(!allItemsValid);

    userInfo?.item_group === null ? (
      setUserInfoValidation({ item_group: true })
    ) : (
      setUserInfoValidation({ item_group: false })
    )

    if (userInfo?.item_group === null || !allItemsValid) {
      handelToDisableButton(false, 'save')
      handelToDisableButton(false, 'save_print')
      return
    }
    else {
      setErrorForItem(false);
      createLooseStockApi(newData)

      // Reset Values
      setSaleItems([newRow])
      setUserInfo({
        item_group: null,
        selected_date: ""
      });


      handelToDisableButton(false, 'save')
      if (print) {
        setInvoicePrintState(true)
        handelToDisableButton(false, 'save_print')
      }
    }

  }








  return (
    <DashboardLayout>





      {saleData && <RoughInvoice
        visibility={false}
        printState={roughPrintState}
        printStateUpdate={setInvoicePrintState}
        invoicesData={saleData}
      />}



      <DashboardNavbar />
      {/* <MDBox mt={2} mb={1}>
        <Grid container spacing={1} alignItems="left">
          <Grid item xs={12} lg={6}>
            <MDTypography variant="h4" fontWeight="medium">
              Sale
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox> */}
      <MDBox my={2}>
        <FilterMenu
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          userInfoValidation={userInfoValidation}
        />
      </MDBox>
      <MDBox my={2}>



        <MDBox mb={2}>
          <Card>
            <Grid item xs={12} sm={12}>




              <SaleItemRow
                saleItems={saleItems}
                setSaleItems={setSaleItems}
                deleteRow={deleteRow}
                addRow={addRow}
                itemTableHeaderData={itemTableHeaderData}
                setItemTableHeaderData={setItemTableHeaderData}
                typeMethod={flowType_In_Out}
                errorForItem={errorForItem}
              />




            </Grid>
          </Card>
        </MDBox>











        <MDBox mb={2}>
          <Card>
            <MDBox display="flex" justifyContent="center" p={2}>

              <MDButton
                onClick={() => {
                  !disablebuttons.save && saveSaleData()
                }}
                variant="outlined"
                color="primary"
              > Save </MDButton>

              <MDButton
                onClick={() => {
                  !disablebuttons.save_print && savePrintSaleData()
                }}
                variant="gradient"
                color="primary"
                sx={{ marginLeft: '10px' }}
              > Save & Print </MDButton>

              {/* <MDButton
                onClick={() => {
                  cancelSaleData()
                }}
                variant="outlined"
                color="error"
                sx={{ marginLeft: '10px' }}
              > Cancel </MDButton> */}

            </MDBox>
          </Card >
        </MDBox >






























      </MDBox >
      {/* <Footer /> */}
    </DashboardLayout >
  );
}

export default TagStock;
