import React, { useReducer, createContext, useCallback, useEffect } from 'react'

import * as types from "./dataReducer";
import dataReducer from "./dataReducer";

const axios = require('axios').default

export const API_URL = 'https://admin.goldshopper.in'
// export const API_URL = 'http://localhost:5000'
//export const API_URL = 'http://192.168.1.50:5000'


import accountGroupsData from '../demo_data/accountGroupsData.json'
import allStamps from '../demo_data/stamp.json'
import { useAuth } from 'utils/AuthProvider';
import { useCookies } from 'react-cookie';
import SnackbarAlert from 'components/SnackbarAlert';





import { v4 as uuidv4 } from 'uuid';
function uuidvNumbers() {
  const uuid = uuidv4();
  const uuidFinal = uuid.replace(/\D/g, '').slice(0, 12);
  return Number(uuidFinal)
}



let AccountGrouptype = [
  { id: 1, name: 'Capital Accounts' },
  { id: 2, name: 'Reserves & Surplus' },
  { id: 3, name: 'Secured Loans' },
  { id: 4, name: 'Unsecured Loans' },
  { id: 5, name: 'Fixed Assets' },
  { id: 6, name: 'Opening Stock' },
  { id: 7, name: 'Purchases' },
  { id: 8, name: 'Sales' },
  { id: 9, name: 'Sundry Creditors' },
  { id: 10, name: 'Sundry Debtors' },
  { id: 11, name: 'Sundry Payables' },
  { id: 12, name: 'Output VAT/GST' },
  { id: 13, name: 'Cash Balances' },
  { id: 14, name: 'Bank Balances' },
  { id: 15, name: 'Current Assets' },
  { id: 16, name: 'Input VAT/GST' },
  { id: 17, name: 'Sundry Securities' },
  { id: 18, name: 'Trading A/C' },
  { id: 19, name: 'P&L A/C' },
  { id: 20, name: 'Loans & Advances' },
  { id: 21, name: 'Current Liabilities' },
  { id: 22, name: 'Profit & Loss Apro' },
  { id: 23, name: 'Investments' },
]



let ItemGrouptype = [
  { id: 30, type: 'gold', name: 'Gold Jewellery', },
  { id: 31, type: 'gold', name: 'Gold Metal', },
  { id: 32, type: 'silver', name: 'Silver Jewellery', },
  { id: 33, type: 'silver', name: 'Silver Metal', },
  { id: 34, type: 'gold', name: 'Branded Jewellery', },
  { id: 35, type: 'stone', name: 'Diamond', },
  { id: 36, type: 'gold', name: 'Diamond Jewellery', },
  { id: 37, type: 'gold', name: 'Kundan Jewellery', },
  { id: 38, type: 'gold', name: 'Pc Rate Jewellery', },
  { id: 39, type: 'stone', name: 'Gems/stones', },
]




const initialState = {
  // id_order: '',
  // token_access: '',

  // loading: true,
  // innerLoading: false,
  // tokenIsValid: false,



  item_group_type: ItemGrouptype,
  account_group_type: AccountGrouptype,

  items_groups: [],
  account_groups: [],

  all_items: [],
  all_voucher: [],
  all_items_is_enable: [],
  all_accounts: [],
  all_stamps: [],
  all_stamps_is_enable: [],

  all_ledgers: [],
  all_sales: [],
  all_payments: [],
  all_stock: [],
  all_tags: [],
  current_voucher_of_all_tags: [],
  all_tagsByVoucher: [],



  snackbar_alert: { color: 'error', title: '', errorSB: false },


};

export const DataContext = createContext(initialState);
export const UserProvider = ({ children }) => {
  const [dataState, dispatch] = useReducer(dataReducer, initialState);
  const [cookies, setCookies, removeCookie] = useCookies();



  let headerWithToken = {
    headers: {
      'Authorization': `Bearer ${cookies.token}`,
      'Accept': '*/*',
      'Content-Type': 'application/json'
    }
  }


  const closeSnackbarAlert = () => {
    dispatch({ type: types.HIDE_SNACKBAR_ALERT, payload: false });
  }





  const myDispatch = (key, value) => {
    dispatch({ type: key, payload: value });
  };








  const createItemGroupsApi = useCallback(async (reqData) => {
    try {
      const res = await axios.post(`${API_URL}/api/itemgroups/add`, {
        "id": reqData.id || uuidvNumbers(),
        "name": reqData.group_name,
        "group_type": reqData.group_type,
        "sale_account": reqData.sale_account,
        "purchase_account": reqData.purchase_account,
      }, headerWithToken);
      dispatch({ type: types.UPDATE_ITEMS_GROUPS, payload: res.data.result });
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Item Group Created successfully!', errorSB: true } });
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
    }
  }, []);




  const updateItemGroupsApi = useCallback(async (reqData) => {
    try {
      await axios.post(`${API_URL}/api/itemgroups/update`, {
        "id": reqData.id,
        "name": reqData.group_name,
        "group_type": reqData.group_type,
        "sale_account": reqData.sale_account,
        "purchase_account": reqData.purchase_account,
      }, headerWithToken);
      getItemGroupsApi()
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Item Group Updated successfully!', errorSB: true } });
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
    }
  }, []);




  // const createAccountGroupsApi = useCallback(async (reqData) => {
  //   console.log('createAccountGroupsApi', reqData);

  //   axios.post(`${API_URL}/api/accountgroups/add`, {
  //     created_at: new Date(),
  //     id: uuidvNumbers(),
  //     name: reqData.group_name,
  //     type: reqData.group_type,
  //   })
  //     .then((res) => {
  //       console.log('createAccountGroupsApi then', res);
  //       // dispatch({ type: types.UPDATE_ITEMS_GROUPS, payload: res.data.result });
  //       dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Item Group Deleted successfully!', errorSB: true } });
  //     })
  //     .catch((error) => {
  //       console.log('createAccountGroupsApi catch', error);
  //       dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
  //     });
  // }, []);


  const createAccountGroupsApi = useCallback(async (reqData) => {
    try {
      const res = await axios.post(`${API_URL}/api/accountgroups/add`, {
        // created_at: new Date(),
        id: uuidvNumbers(),
        name: reqData.group_name,
        type: reqData.group_type,
      }, headerWithToken);
      dispatch({ type: types.UPDATE_ACCOUNT_GROUPS, payload: res.data.result });
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Account Group Created successfully!', errorSB: true } });
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
    }
  }, []);



  const updateAccountGroupsApi = useCallback(async (reqData) => {
    try {
      await axios.post(`${API_URL}/api/accountgroups/update`, {
        // created_at: reqData.created_at,
        id: reqData.id,
        name: reqData.group_name,
        type: reqData.group_type,
      }, headerWithToken);
      getAccountGroupsApi()
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Account Group Updated successfully!', errorSB: true } });
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
    }
  }, []);






  const createStampApi = useCallback(async (reqData) => {
    try {
      const res = await axios.post(`${API_URL}/api/stamps/add`, {
        id: uuidvNumbers(),
        // created_at: new Date(),
        name: reqData.stamp_name,
        type: reqData.stamp_type,
        tunch: reqData.tunch,
        is_enable: reqData.is_enable,
      }, headerWithToken);
      dispatch({ type: types.UPDATE_ALL_STAMPS, payload: res.data.result });
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Stamp Created successfully!', errorSB: true } });
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
    }
  }, []);





  const updateStampApi = useCallback(async (reqData) => {
    try {
      await axios.post(`${API_URL}/api/stamps/update`, {
        id: reqData.id,
        // created_at: reqData.created_at,
        name: reqData.stamp_name,
        type: reqData.stamp_type,
        tunch: reqData.tunch,
        is_enable: reqData.is_enable,
      }, headerWithToken);
      getStampsApi()
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Stamp Updated successfully!', errorSB: true } });
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
    }
  }, []);








  // const createStampApi = useCallback(async (reqData) => {
  //   axios.post(`${API_URL}/api/stamps/add`, {
  //     id: uuidvNumbers(),
  //     name: reqData.name,
  //     type: reqData.type,
  //     tunch: reqData.tunch,
  //     is_enable: reqData.is_enable,
  //     created_at: new Date(),
  //   })
  //     .then((res) => {
  //       // console.log(res.data)
  //       if (res.data) {
  //         alert('Stamp Added')
  //       } else {
  //         alert('Something went wrong')
  //       }
  //       //alert('Stamp Added')
  //       //return { success: true };
  //     })
  //     .catch((error) => {
  //       // console.log(error)
  //       // dispatch({ type: types.LOADING, payload: false });
  //       return { success: false };
  //     });
  // }, []);
















  const createAccountApi = useCallback(async (reqData) => {
    try {
      const response = await axios.post(`${API_URL}/api/accounts/add`, {
        id: uuidvNumbers(),
        account_type: reqData.account_type,
        name: reqData.name,
        contact_no: reqData.contact_no,
        alternate_contact_no: reqData.alternate_contact_no,
        date_of_birth: reqData.date_of_birth,
        address: reqData.address,
        material_method: reqData.material_method,
        material_weight: reqData.material_weight,
        amount_method: reqData.amount_method,
        amount: reqData.amount,
      }, headerWithToken);
      dispatch({ type: types.UPDATE_ALL_ACCOUNTS, payload: response.data.result });
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Account Created successfully!', errorSB: true } });
      return { success: true };
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
      return { success: false };
    }
  }, []);




  // dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Stamp Updated successfully!', errorSB: true } });
  // dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });



  const updateAccountApi = useCallback(async (reqData) => {
    try {
      await axios.post(`${API_URL}/api/accounts/update`, {
        id: reqData.id,
        account_type: reqData.account_type,
        name: reqData.name,
        contact_no: reqData.contact_no,
        alternate_contact_no: reqData.alternate_contact_no,
        date_of_birth: reqData.date_of_birth,
        address: reqData.address,
        material_method: reqData.material_method,
        material_weight: reqData.material_weight,
        amount_method: reqData.amount_method,
        amount: reqData.amount,
      }, headerWithToken);
      getAccountsApi()
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Account Updated successfully!', errorSB: true } });
      return { success: true };
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
      return { success: false };
    }
  }, []);















  // const createAccountApi = useCallback(async (reqData) => {
  //   axios.post(`${API_URL}/api/accounts/add`, {
  //     id: uuidvNumbers(),
  //     created_by: cookies.email,
  //     created_at: new Date(),
  //     group: reqData.group,
  //     name: reqData.name,
  //     contact_no: reqData.contact_no,
  //     alternate_contact_no: reqData.alternate_contact_no,
  //     date_of_birth: reqData.date_of_birth,
  //     address: reqData.address,
  //     gold_fine_method: reqData.gold_fine_method,
  //     gold_fine: reqData.gold_fine,
  //     silver_fine_method: reqData.silver_fine_method,
  //     silver_fine: reqData.silver_fine,
  //     amount_method: reqData.amount_method,
  //     amount: reqData.amount,
  //   })
  //     .then((res) => {
  //       // console.log(res.data)
  //       //alert('Item Added')
  //       if (res.data) {
  //         alert('Item Added')
  //       } else {
  //         alert('Something went wrong')
  //       }
  //       //return { success: true };
  //     })
  //     .catch((error) => {
  //       // console.log(error)
  //       // dispatch({ type: types.LOADING, payload: false });
  //       return { success: false };
  //     });
  // }, []);


  // const createBillApi = useCallback(async (reqData) => {
  //   axios.post(`${API_URL}/api/bills/add`, {
  //     id: uuidvNumbers(),
  //     user_id: reqData.user.user_id,
  //     user_name: reqData.user.user_name,
  //     bill_number: reqData.user.bill_number,
  //     selected_date: reqData.user.selected_date,
  //     created_on: new Date(),
  //     user: reqData.user,
  //     totals: reqData.totals,
  //     panels: reqData.panels,
  //     account: reqData.user.accountId,
  //   })
  //     .then((res) => {
  //       if (res.data) {
  //       } else {
  //         alert('Something went wrong')
  //       }
  //     })
  //     .catch((error) => {
  //       return { success: false };
  //     });
  // }, []);


  const createBillApi = useCallback(async (reqData) => {
    try {
      const res = await axios.post(`${API_URL}/api/bills/add`, {
        id: uuidvNumbers(),
        user_id: reqData.user.user_id,
        user_name: reqData.user.user_name,
        bill_number: reqData.user.bill_number,
        selected_date: reqData.user.selected_date,
        created_on: new Date(),
        user: reqData.user,
        totals: reqData.totals,
        panels: reqData.panels,
        account: reqData.user.accountId,
      }, headerWithToken)
      if (reqData.id) {
        getStampsApi()
        dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Bill Updated successfully!', errorSB: true } });
      } else {
        dispatch({ type: types.UPDATE_ALL_STAMPS, payload: res.data.result });
        dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Bill Created successfully!', errorSB: true } });
        getVoucherApi()
      }
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
    }
  }, []);












  const createSalesPaymentApi = useCallback(async (reqData) => {
    try {
      const res = await axios.post(`${API_URL}/api/sales-payment/add`, reqData, headerWithToken)
      if (reqData.id) {
        // getStampsApi()
        dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Transaction Updated successfully!', errorSB: true } });
      } else {
        // dispatch({ type: types.UPDATE_ALL_STAMPS, payload: res.data.result });
        dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Transaction Created successfully!', errorSB: true } });
        // getVoucherApi()
      }
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
    }
  }, []);


  const createLooseStockApi = useCallback(async (reqData) => {
    try {
      const res = await axios.post(`${API_URL}/api/loose-stock/add`, reqData, headerWithToken)
      if (reqData.id) {
        // getStampsApi()
        dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Loose Stock Updated successfully!', errorSB: true } });
      } else {
        // dispatch({ type: types.UPDATE_ALL_STAMPS, payload: res.data.result });
        dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Loose Stock Created successfully!', errorSB: true } });
        // getVoucherApi()
      }
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
    }
  }, []);

  const createTagStockApi = useCallback(async (reqData) => {
    try {
      await axios.post(`${API_URL}/api/tags/add`, reqData, headerWithToken)
      // if (reqData.id) {/
      // getStampsApi()
      // dispatch({ typ/e: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Tag Stock Updated successfully!', errorSB: true } });
      // } else {/
      // dispatch({ type: types.UPDATE_ALL_STAMPS, payload: res.data.result });
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Tag Stock Created successfully!', errorSB: true } });
      // getVoucherApi()
      // }/
      return { success: true };
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
      return { success: false };
    }
  }, []);




  const createTagApi = useCallback(async (reqData) => {
    try {
      await axios.post(`${API_URL}/api/tags/add`, reqData, headerWithToken)
      // if (reqData.id) {
      // getStampsApi()
      // dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Tags Updated successfully!', errorSB: true } });
      // } else {
      // dispatch({ type: types.UPDATE_ALL_STAMPS, payload: res.data.result });
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Tags Created successfully!', errorSB: true } });
      // getVoucherApi()
      // }
      return { success: true };
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
      return { success: false };
    }
  }, []);





  // const addToLedgerApi = useCallback(async (reqData, itm, saleid, paymentid, vouchercode) => {
  //   axios.post(`${API_URL}/api/ledgers/add`, {
  //     id: uuidvNumbers(),
  //     user_id: reqData.user.user_id,
  //     user_name: reqData.user.user_name,
  //     bill_number: reqData.user.bill_number,
  //     selected_date: reqData.user.selected_date,
  //     created_on: new Date(),
  //     fine: itm.fine,
  //     grwt: itm.grwt,
  //     item: itm.item,
  //     lbr: itm.lbr,
  //     less: itm.less,
  //     wstg: itm.wstg,
  //     netwt: itm.netwt,
  //     wstg: itm.wstg,
  //     on: itm.on,
  //     pc: itm.pc,
  //     rate: itm.rate,
  //     stamp: itm.stamp,
  //     total: itm.total,
  //     tunch: itm.tunch,
  //     type: itm.type,
  //     user: reqData.user,
  //     vouchercode: vouchercode,
  //     account: reqData.user.accountId,
  //     saleid: saleid,
  //     paymentid: paymentid,
  //   })
  //     .then((res) => {
  //       // console.log(res.data)
  //       //alert('Item Added')
  //       if (res.data) {
  //         //alert('Bill Added')
  //       } else {
  //         alert('Something went wrong')
  //       }
  //       //return { success: true };
  //     })
  //     .catch((error) => {
  //       // console.log(error)
  //       // dispatch({ type: types.LOADING, payload: false });
  //       return { success: false };
  //     });

  // }, []);


  const addToSaleApi = useCallback(async (reqData, itm) => {
    axios.post(`${API_URL}/api/sales/add`, {
      id: uuidvNumbers(),
      user_id: reqData.user.user_id,
      user_name: reqData.user.user_name,
      bill_number: reqData.user.bill_number,
      selected_date: reqData.user.selected_date,
      created_on: new Date(),
      fine: itm.fine,
      grwt: itm.grwt,
      item: itm.item,
      lbr: itm.lbr,
      less: itm.less,
      wstg: itm.wstg,
      netwt: itm.netwt,
      on: itm.on,
      pc: itm.pc,
      rate: itm.rate,
      stamp: itm.stamp,
      total: itm.total,
      tunch: itm.tunch,
      type: itm.type,
      user: reqData.user,
      item_code: itm.item_code,
      unit: itm.unit,
      less_details: itm.less_details,
      size: itm.size,
      uid: itm.uid,
      d_carats: itm.d_carats,
      color_clarity: itm.color_clarity,
      remarks: itm.remarks,
      account: reqData.user.accountId,
    }, headerWithToken)
      .then((res) => {
        // console.log(res.data)
        //alert('Item Added')
        if (res.data) {
          //alert('Bill Added')
        } else {
          alert('Something went wrong')
        }
        //return { success: true };
      })
      .catch((error) => {
        // console.log(error)
        // dispatch({ type: types.LOADING, payload: false });
        return { success: false };
      });

  }, []);


  const addToPaymentApi = useCallback(async (reqData, itm) => {
    axios.post(`${API_URL}/api/payments/add`, {
      id: uuidvNumbers(),
      user_id: reqData.user.user_id,
      user_name: reqData.user.user_name,
      bill_number: reqData.user.bill_number,
      selected_date: reqData.user.selected_date,
      created_on: new Date(),

      amount: itm.amount,
      bank: itm.bank,
      fine: itm.fine,
      grwt: itm.grwt,
      method: itm.method,
      miaGoldBhav: itm.miaGoldBhav,
      rate: itm.rate,
      tunch: itm.tunch,
      type: itm.type,
      useAsGoldBhav: itm.useAsGoldBhav,

      user: reqData.user,
      account: reqData.user.accountId,
    }, headerWithToken)
      .then((res) => {
        // console.log(res.data)
        //alert('Item Added')
        if (res.data) {
          //alert('Bill Added')
        } else {
          alert('Something went wrong')
        }
        //return { success: true };
      })
      .catch((error) => {
        // console.log(error)
        // dispatch({ type: types.LOADING, payload: false });
        return { success: false };
      });

  }, []);















  const getItemsApi = useCallback(async () => {
    axios.get(`${API_URL}/api/items`, headerWithToken)
      .then(function (response) {
        // console.log('response.data', response.data);
        dispatch({ type: types.SET_ALL_ITEMS, payload: response.data });
      })
      .catch(function (error) {
        // console.log('Something went wrong')
        // console.log(error)
      })
  }, []);





  const getSingleItemApi = useCallback(async (reqId) => {
    try {
      const res = await axios.post(`${API_URL}/api/items/item`, {
        id: reqId
      }, headerWithToken);
      return { success: true, data: res };
    } catch (error) {
      // console.log('asdasasdas', res);
      return { success: false, };
    }
  }, []);





  const createItemApi = useCallback(async (reqData) => {
    try {
      const res = await axios.post(`${API_URL}/api/items/add`, {
        // id: uuidvNumbers(),
        name: reqData.name,
        item_type: reqData.item_type,
        prefix: reqData.prefix,
        prefix_counter: reqData.prefix_counter,
        unit: reqData.unit,
        labour_upon: reqData.labour_upon,
        stock_method: reqData.stock_method,
        individual_price: reqData.individual_price,
        material_method: reqData.material_method,
        material_weight: reqData.material_weight,
        amount_method: reqData.amount_method,
        amount: reqData.amount,
        is_enable: reqData.is_enable
      }, headerWithToken);
      dispatch({ type: types.UPDATE_ALL_ITEMS, payload: res.data.result });
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Item Created successfully!', errorSB: true } });
      return { success: true, };
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
      return { success: false, };
    }
  }, []);





  const updateItemApi = useCallback(async (reqData) => {
    try {
      await axios.post(`${API_URL}/api/items/update`, {
        id: reqData.id,
        name: reqData.name,
        item_type: reqData.item_type,
        prefix: reqData.prefix,
        prefix_counter: reqData.prefix_counter,
        unit: reqData.unit,
        labour_upon: reqData.labour_upon,
        stock_method: reqData.stock_method,
        individual_price: reqData.individual_price,
        material_method: reqData.material_method,
        material_weight: reqData.material_weight,
        amount_method: reqData.amount_method,
        amount: reqData.amount,
        is_enable: reqData.is_enable
      }, headerWithToken);
      getItemsApi()
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Item Updated successfully!', errorSB: true } });
      return { success: true };
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
      return { success: false };
    }
  }, []);









  const deleteSingleItemApi = useCallback(async (reqId) => {
    try {
      await axios.delete(`${API_URL}/api/items/delete`, {
        headerWithToken,
        data: {
          id: reqId
        }
      });
      dispatch({ type: types.DELETE_SINGLE_ITEMS, payload: reqId });
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Item Deleted successfully!', errorSB: true } });
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
    }
  }, []);


  const deleteSingleItemGroupApi = useCallback(async (reqId) => {
    try {
      await axios.delete(`${API_URL}/api/itemgroups/delete`, {
        headerWithToken,
        data: {
          id: reqId
        }
      });
      dispatch({ type: types.DELETE_SINGLE_ITEMS_GROUP, payload: reqId });
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Item Group Deleted successfully!', errorSB: true } });
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
    }
  }, []);


  const deleteSingleAccountGroupApi = useCallback(async (reqId) => {
    try {
      await axios.delete(`${API_URL}/api/accountgroups/delete`, {
        headerWithToken,
        data: {
          id: reqId
        }
      });
      dispatch({ type: types.DELETE_SINGLE_ACCOUNT_GROUP, payload: reqId });
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Account Group Deleted successfully!', errorSB: true } });
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
    }
  }, []);



  const deleteSingleAccountApi = useCallback(async (reqId) => {
    try {
      await axios.delete(`${API_URL}/api/accounts/delete`, {
        headerWithToken,
        data: {
          id: reqId
        }
      });
      dispatch({ type: types.DELETE_SINGLE_ACCOUNT, payload: reqId });
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'accounts Deleted successfully!', errorSB: true } });
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
    }
  }, []);




  const deleteSingleStampApi = useCallback(async (reqId) => {
    try {
      await axios.delete(`${API_URL}/api/stamps/delete`, {
        headerWithToken,
        data: {
          id: reqId
        }
      });
      dispatch({ type: types.DELETE_SINGLE_STAMP, payload: reqId });
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Stamp Deleted successfully!', errorSB: true } });
    } catch (error) {
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
    }
  }, []);





  const getItemGroupsApi = useCallback(async () => {
    axios.get(`${API_URL}/api/itemgroups`, headerWithToken)
      .then(function (response) {
        // console.log('getItemGroupsApi', response.data);
        dispatch({ type: types.SET_ITEMS_GROUPS, payload: response.data });
      })
      .catch(function (error) {
        // console.log('Something went wrong')
        // console.log(error)
      })
  }, []);











  const addAccountGroupsApi = useCallback(async (reqData) => {
    return axios.post(`${API_URL}/api/accountgroups/add`, reqData, headerWithToken)
      .then(function (response) {
        // console.log(response.data);
        return { success: true, message: "Account Group Added" };
      })
      .catch(function (error) {
        // console.log(error);
        return { success: false, message: "Something went wrong" };
      });
  }, []);





  const getAccountGroupsApi = useCallback(async () => {
    axios.get(`${API_URL}/api/accountgroups`, headerWithToken)
      .then(function (response) {
        // console.log('getAccountGroupsApi', response.data)
        dispatch({ type: types.SET_ACCOUNT_GROUPS, payload: response.data });
      })
      .catch(function (error) {
        // console.log('Something went wrong')
        // console.log(error)
      })
  }, []);


















  const getVoucherApi = useCallback(async () => {
    axios.get(`${API_URL}/api/vouchers`, headerWithToken)
      .then(function (response) {
        // console.log('getVoucherApi', response.data)
        dispatch({ type: types.SET_ALL_VOUCHER, payload: response.data });
      })
      .catch(function (error) {
        // console.log('Something went wrong')
      })
  }, []);





















  const getAccountsApi = useCallback(async () => {
    axios.get(`${API_URL}/api/accounts`, headerWithToken)
      .then(function (response) {
        // console.log('getAccountsApi', response.data);
        dispatch({ type: types.SET_ALL_ACCOUNTS, payload: response.data });
      })
      .catch(function (error) {
        // console.log('Something went wrong')
        // console.log(error)
      })
  }, []);


  const getStampsApi = useCallback(async () => {
    axios.get(`${API_URL}/api/stamps`, headerWithToken)
      .then(function (response) {
        // console.log('stamps', response.data);
        dispatch({ type: types.SET_ALL_STAMPS, payload: response.data });
      })
      .catch(function (error) {
        // console.log('Something went wrong')
        // console.log(error)
      })
  }, []);


















  const getSingleAccountApi = useCallback(async (reqId) => {
    try {
      const res = await axios.post(`${API_URL}/api/accounts/account`, {
        id: reqId
      }, headerWithToken);
      return { success: true, data: res };
    } catch (error) {
      // console.log('asdasasdas', res);
      return { success: false, };
    }
  }, []);











  const getLedgersApi = useCallback(async (user_id, startDate, endDate) => {
    // axios.get(`${API_URL}/api/ledgers/get?user_id=${reqID}&startDate=${reqStartDate}&endDate=${reqEndDate}`, headerWithToken)
    axios.get(`${API_URL}/api/ledgers/get`, {
      params: { user_id, startDate, endDate, },
      headers: headerWithToken.headers
    })
      .then(function (response) {
        // console.log('getAccountGroupsApi', response.data)
        dispatch({ type: types.SET_ALL_LEDGERS, payload: response.data });
      })
      .catch(function (error) {
        // console.log('Something went wrong')
        // console.log(error)
      })
  }, []);











  const getSingleLedgersApi = useCallback(async (reqId) => {
    try {
      const res = await axios.get(`${API_URL}/api/sales-payment/get/${reqId}`, headerWithToken);
      return { success: true, data: res };
    } catch (error) {
      // console.log('asdasasdas', res);
      return { success: false, };
    }
  }, []);





  // const getSingleTagApi = useCallback(async (reqId) => {
  //   try {
  //     const res = await axios.get(`${API_URL}/api/tag-stock/${reqId}`, headerWithToken);
  //     return { success: true, data: res };
  //   } catch (error) {
  //     return { success: false, };
  //   }
  // }, []);





  const getStockApi = useCallback(async () => {
    try {
      let res = await axios.get(`${API_URL}/api/loose-stock`, headerWithToken);
      dispatch({ type: types.SET_ALL_STOCK, payload: res.data.stocks });
    } catch (error) {
      return { success: false, };
    }
  }, []);









  const getTagsApi = useCallback(async (linked_id = undefined, linked_counter = undefined) => {
    try {
      let res = await axios.get(`${API_URL}/api/tags/linked`, {
        params: {
          linked_id, linked_counter
        },
        headers: headerWithToken.headers
      })
      dispatch({ type: types.SET_ALL_TAGS, payload: res.data.tags });
      dispatch({ type: types.CURRENT_VOUCHER_OF_ALL_TAGS, payload: [linked_id, linked_counter] });
      return { success: true, data: res.data.tags };
    } catch (error) {
      return { success: false, };
    }
  }, []);












  const getTagsByVoucherApi = useCallback(async (startDate = undefined, endDate = undefined) => {
    try {
      let res = await axios.get(`${API_URL}/api/tags`, {
        params: {
          startDate, endDate
        },
        headers: headerWithToken.headers
      })
      dispatch({ type: types.SET_ALL_TAGS_BY_VOUCHER, payload: res.data.tags });
    } catch (error) {
      return { success: false, };
    }
  }, []);

















  const getSalesApi = useCallback(async () => {
    axios.post(`${API_URL}/api/ledgers/type`, {
      type: 'outflow'
    }, headerWithToken)
      .then(function (response) {
        // console.log('getAccountGroupsApi', response.data)
        dispatch({ type: types.SET_ALL_SALES, payload: response.data });
      })
      .catch(function (error) {
        // console.log('Something went wrong')
        // console.log(error)
      })
  }, []);












  // const getPaymentsApi = useCallback(async () => {
  //   axios.get(`${API_URL}/api/payments`, headerWithToken)
  //     .then(function (response) {
  //       // console.log('getAccountGroupsApi', response.data)
  //       dispatch({ type: types.SET_ALL_PAYMENTS, payload: response.data });
  //     })
  //     .catch(function (error) {
  //       // console.log('Something went wrong')
  //       // console.log(error)
  //     })
  // }, []);









  const getReportPaymentApi = useCallback(async (params) => {
    axios.get(`${API_URL}/api/payments`, { headers: headerWithToken.headers, params })
      .then(function (response) {
        // console.log('getAccountGroupsApi', response.data)
        dispatch({ type: types.SET_ALL_PAYMENTS, payload: response.data });
      })
      .catch(function (error) {
        // console.log('Something went wrong')
        // console.log(error)
      })
  }, []);
















  const searchTagByItemCodeApi = useCallback(async (reqId) => {
    try {
      let res = await axios.get(`${API_URL}/api/tags/item/${reqId}`, headerWithToken);
      // console.log('getItemCode res', res.data.tag);
      // getItemsApi()
      dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'success', title: 'Tag Imported successfully!', errorSB: true } });
      return { success: true, response: res.data.tag };
    } catch (error) {
      // dispatch({ type: types.SHOW_SNACKBAR_ALERT, payload: { color: 'error', title: 'Something Went Wrong!', errorSB: true } });
      return { success: false };
    }
  }, []);



























  useEffect(() => {
    getItemsApi()
    getItemGroupsApi()
    getAccountsApi()
    getStampsApi()
    getAccountGroupsApi()
    getVoucherApi()
    getSalesApi()
    // getTagsByVoucherApi()
    // getStockApi()
    // getTagsApi()
    // getPaymentsApi()

  }, [])








  return (
    <DataContext.Provider
      value={{
        dataState,
        createStampApi,
        updateStampApi,
        createItemApi,
        updateItemApi,
        getReportPaymentApi,
        deleteSingleStampApi,
        createAccountApi,
        updateAccountApi,
        createItemGroupsApi,
        updateItemGroupsApi,
        createAccountGroupsApi,
        updateAccountGroupsApi,
        createBillApi,

        createSalesPaymentApi,
        createTagApi,
        searchTagByItemCodeApi,
        createLooseStockApi,
        createTagStockApi,
        // addToLedgerApi,
        addToSaleApi,
        addToPaymentApi,
        getItemsApi,
        getSingleItemApi,
        getAccountsApi,
        getStampsApi,
        addAccountGroupsApi,
        deleteSingleItemApi,
        deleteSingleItemGroupApi,
        deleteSingleAccountGroupApi,
        getTagsByVoucherApi,
        deleteSingleAccountApi,
        getItemGroupsApi,
        getSingleAccountApi,
        getLedgersApi,
        getSingleLedgersApi,
        // getSingleTagApi,
        getTagsApi,
        //   checkToken,
        //   fetchAllData
        closeSnackbarAlert,
        getStockApi,
        myDispatch
      }}
    >
      {" "} {children} {" "}
    </DataContext.Provider>
  );
};


