import React, { useContext } from 'react'

// import { Autocomplete, Grid } from '@mui/material'
// import CustomDatepicker from 'components/CustomDatepicker'
import MDBox from 'components/MDBox'
// import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import { useState } from 'react'
// import { useAuth } from '../../../../utils/AuthProvider'
import { DataContext } from "context/dataContext";
// import ErrorText from 'components/ErrorText'
// import AddAccountPopup from 'layouts/transactions/components/AddAccountPopup'
import { addDays } from 'date-fns'
import { DateRange } from 'react-date-range';
import FilterMenuContainer from 'components/FilterMenu/FilterMenuContainer'
import FM_ButtonGroup from 'components/FilterMenu/FM_ButtonGroup'
import FM_Button2 from 'components/FilterMenu/FM_Button2'
import FM_Button1 from 'components/FilterMenu/FM_Button1'
import MonthsSelectComponent from 'components/MonthsSelecComponent'
import moment from 'moment';

// const axios = require('axios').default

const FilterMenu = ({
  // filterMenuData,
  // setFilterMenuData,
  // userInfoValidation,
}) => {

  // const { cookies } = useAuth();
  // axios.defaults.headers.common = { 'Authorization': `Bearer ${cookies.token}` }

  const { dataState, getReportPaymentApi } = useContext(DataContext)

  // Add Account Popup State
  // const [addAccountPopupState, setAddAccountPopupState] = useState(false);

  // const [newAccountData, setNewAccountData] = useState(false)



  // const [allItems, setAllItems] = useState([]);



  let today = moment().format("MMM DD, YYYY");
  let oneMonthAgo = moment().subtract(1, 'months').format("MMM DD, YYYY");
  const [startDate, setStartDate] = useState(oneMonthAgo);
  const [endDate, setEndDate] = useState(today);
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  const [dateopen, setDateOpen] = useState(false);

  const [datestate, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection'
    }
  ]);
  // const [origselection, setOrigSelection] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: addDays(new Date(), 1),
  //     key: 'selection'
  //   }
  // ]);

  // const handelToUpdateFilterMenuData = (value, key) => {
  //   setFilterMenuData(prevData => ({
  //     ...prevData,
  //     [key]: value,
  //   }));
  //   // console.log(userInfo)
  // }

  function resetParams() {
    // setFilterMenuData(prevData => ({
    //   ...prevData,
    //   selected_date: "",
    // }));

    setStartDate('')
    setEndDate('')
    // setDateState(origselection)
  }

  // useEffect(() => {
  //   var accounts = dataState.all_accounts
  //   var accountitems = []
  //   accounts.forEach((cus, i) => {
  //     accountitems.push({ label: cus.name, id: cus.id, phone: cus.contact_no, _id: cus._id })
  //   });
  //   // console.log(accountitems)
  //   // accountitems.push({ id: 0, label: "Add Account" })
  //   // setAllItems(accountitems)
  //   // console.log('accountitems', accountitems);


  //   // handelToUpdateFilterMenuData(new Date(), 'selected_date')

  // }, [dataState.all_accounts]);









  const handleToSearch = () => {
    getReportPaymentApi({
      startDate, endDate, type: "outflow"
    })
  }








  // Managing Add Account Popup
  // const handleToManageAddAccountPopup = async () => {
  //   setAddAccountPopupState(true)
  // }





  // Add New Account Data to DropDown
  // useEffect(() => {
  //   if (newAccountData) {
  //     handelToUpdateFilterMenuData(dataState.all_accounts[0]?.name || '', 'user_name')
  //     handelToUpdateFilterMenuData(dataState.all_accounts[0]?.id || null, 'user_id')
  //     handelToUpdateFilterMenuData(dataState.all_accounts[0]?.contact_no || '', 'phone_number')
  //     handelToUpdateFilterMenuData(dataState.all_accounts[0]?._id || '', 'accountId')
  //   }
  // }, [dataState?.all_accounts])

















  return (
    <>


      <FilterMenuContainer>









        <MDBox sx={{ width: '100%', maxWidth: '25%', position: 'relative' }}>
          <MDInput
            variant="standard"
            label="Select Date"
            value={startDate && endDate ? (startDate + ' - ' + endDate) : ''}
            placeholder="Select Range"
            onClick={() => setDateOpen(!dateopen)}
            onFocus={() => setDateOpen(!dateopen)}
            // onBlur={() => setDateOpen(!dateopen)}
            style={{ width: '100%' }}
            type='text'
            autoComplete="off"
          />
          {dateopen ? <>
            <div
              onClick={() => setDateOpen(false)}
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1200,
              }}
            ></div>
            <MDBox sx={{
              position: 'absolute',
              zIndex: 4,
              left: 0,
              top: '3.5rem',
              padding: '2',
              backgroundColor: '#fff',
              border: '1px solid #d2d6da',
              borderRadius: '8px',
              boxShadow: '2px 2px 5px -3px rgb(0 0 0 / 30%)',
              overflow: 'hidden',
              zIndex: 1201,
            }}>
              <DateRange
                rangeColors={['#AF891F']}
                // maxDate={addDays(new Date(), 0)}

                onChange={item => {
                  console.log(item.selection)
                  setDateState([item.selection])

                  var options = { year: 'numeric', month: 'short', day: 'numeric' };

                  setStartDate(new Date(item.selection.startDate).toLocaleDateString("en-US", options))
                  setEndDate(new Date(item.selection.endDate).toLocaleDateString("en-US", options))
                  if (item.selection.startDate !== item.selection.endDate) {
                    setDateOpen(false)
                  }

                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={datestate}
                direction="vertical"
              />
            </MDBox>
          </> : ''}
        </MDBox>

        <MDBox sx={{ width: '100%', maxWidth: '25%', position: 'relative' }}>
          <MonthsSelectComponent setStartDate={setStartDate} setEndDate={setEndDate} />
        </MDBox>


        <FM_ButtonGroup>
          <FM_Button2 actionButton={handleToSearch} />
          <FM_Button1
            actionButton={resetParams}
          />
        </FM_ButtonGroup>


      </FilterMenuContainer>





    </>
  )
}

export default FilterMenu