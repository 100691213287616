import React, { useContext } from 'react'
import { useEffect, useState } from "react";

// import { Autocomplete, Button, Popover, Typography } from '@mui/material'
// import MDInput from 'components/MDInput'

// import ItemsData from '../../../../demo_data/itemsData.json'

// import { transactionsTypeOption, stampTypeOption, unitOption, labourOption } from "components/const";
// import MDButton from "components/MDButton";
import SaleItemRowSelectBox from "./SaleItemRowSelectBox";
import SaleItemRowInput from "./SaleItemRowInput";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import FileCopyIcon from '@mui/icons-material/FileCopy';
// import { Padding } from "@mui/icons-material";
import { GetCalculationOf_Netwt, TotalCalculationOf_Grwt, TotalCalculationOf_Less, TotalCalculationOf_Netwt } from "./functions";
// import { SetFormattedIndianNumber } from "components/globalFunctions";
import { TableButtonsGroupStyle, TableButtonStyle, TableHeaderInsideStyle, TableInnerErrorStyle, TableInnerStyle, TableStyle } from "./style";
import { SetNumbersDecimals } from "components/globalFunctions";
import ImageUploader from "../tags/components/ImageUploader";
// import CheckIcon from '@mui/icons-material/Check';
// import CloseIcon from '@mui/icons-material/Close'; 
import PopoverCopyRows from '../tags/components/PopoverCopyRows';
import { TableContainer } from '@mui/material';
import MDTypography from 'components/MDTypography';
import SettingsIcon from '@mui/icons-material/Settings';
import TableColumnManager from 'layouts/transactions/components/TableColumnManager';
import { TableColumnVisibiltyFunction } from 'components/globalFunctions';
import { DataContext } from '../../../context/dataContext'
import LessWeightPopup from './LessWeightPopup';





const TagItemRow = ({
  tagItems,
  setTagItems,
  // itemCodeType,
  // itemCodeCounter,
  // setItemCodeCounter,
  prefixDataManager,
  handleToAppendOneMoreRow,
  handleItemCodeOn_UpdateItem,
  handleItemCodeOn_CopyRow,
  itemTableHeaderData,
  setItemTableHeaderData,
  errorForTags
}) => {
  const { dataState } = useContext(DataContext)
  // Add Item Popup State
  // const [addItemPopupState, setAddItemPopupState] = useState(false);

  // const allItemsData = ItemsData
  let allStampData = dataState.all_stamps_is_enable.map((item) => ({ id: item.id, name: item.name, type: item.type }))

  let allItemsData = dataState.all_items_is_enable.map((item) => ({ id: item.id, name: item.name, prefix: item.prefix, prefix_counter: item.prefix_counter }))


  let totalValuesOf_grwt = tagItems.reduce((total, item) => total + item.grwt, 0);
  let totalValuesOf_less = tagItems.reduce((total, item) => total + item.less, 0);
  let totalValuesOf_netwt = totalValuesOf_grwt - totalValuesOf_less;
  let totalValuesOf_qty = tagItems.reduce((total, item) => total + item.qty, 0);

  const [lessWeightPopup, setLessWeightPopup] = useState(false)

  // // Temporary Fetch & Save Data From Json
  // const [itemsDataOptions, setItemsDataOptions] = useState([])
  // useEffect(() => {
  //   setItemsDataOptions(allItemsData.map((item) => ({ id: item.id, name: item.name })))
  // }, [])




  // useEffect(() => {
  //   console.log('allItemsData', allItemsData);
  // }, [allItemsData])


  // param `key name`, val `new value` , index item's row index`, decimalCounting ` if you want to add value in 0.000`
  const updateRow = (param, val, index, decimalCounting) => {
    // console.log('param, val, index, decimalCounting ', param, val, index, decimalCounting);
    // console.log((decimalCounting, decimalCounting === 0), decimalCounting !== 999);


    if (val !== '') {
      setTagItems((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          // [param]: (decimalCounting || decimalCounting === 0) && decimalCounting !== 999 ? (
          //   parseFloat(val)
          // ) : (
          //   param === 'type' ? (
          //     val.status
          //   ) : (
          //     param === 'item' ? (
          //       val.id
          //     ) : (
          //       val
          //     )
          //   )
          // )

          [param]: (decimalCounting || decimalCounting === 0) && decimalCounting !== 999 ? (
            parseFloat(val)
          ) : (
            param === 'type' ? (
              val.status
            ) : (
              Array.isArray(val) || val?.name ? (
                val.name
              ) : (
                val
              )
            )
          )
        };
        // console.log('newData', newData);

        return newData;
      });
      checkToAutoSelectAndCalculationMethod(param, val, index, decimalCounting)
    }
  }

  const checkToAutoSelectAndCalculationMethod = (param, val, index, decimalCounting) => {
    switch (param) {
      case "item":
        // if (val?.name) {
        //   const unitVal = allItemsData.find((item) => item.id === val.id)?.unit;
        //   updateRow("unit", unitVal, index);
        // }
        handleItemCodeOn_UpdateItem(val, index)
        break;

      case "type":
        // Empty for now
        break;

      default:
        break;
    }
  };


  // Managing Add Item Popup
  // const handleToManageAddItemPopup = () => {
  //   setAddItemPopupState(true)
  // }










  // Deleteding Existing Row From Table Using `key`
  const handleToDeleteCurruntRow = (id) => {
    console.log('key', id);
    setTagItems(
      tagItems.filter(item => item.id !== id)
    );
    // toast.success('1 row has been deleted from the table');
  };























  return (
    <>
      <TableContainer sx={{ boxShadow: 'none' }}>
        <TableStyle cellPadding={0} cellSpacing={0}>
          <thead>
            <tr display="flex" mb={1}>
              {itemTableHeaderData.map((item) => (
                item.visibilty ? (
                  <th key={item.name}>
                    <TableHeaderInsideStyle style={{ justifyContent: item.align, textAlign: item.align }}>
                      {item.name}
                    </TableHeaderInsideStyle>
                  </th>
                ) : ''
              ))}
              <th>
                <TableHeaderInsideStyle style={{ justifyContent: 'right', textAlign: 'right' }}>
                  <TableColumnManager
                    itemTableHeaderData={itemTableHeaderData}
                    setItemTableHeaderData={setItemTableHeaderData}
                  />
                </TableHeaderInsideStyle>
              </th>
            </tr>
          </thead>
          <tbody>

            {tagItems.map((val, i) => (
              <tr key={val.id}>



                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='serial_number'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <TableInnerStyle align="center">{i + 1}</TableInnerStyle>
                  </td>
                </TableColumnVisibiltyFunction>


                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='img'>
                  <td valign="middle" align="center" style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <ImageUploader
                      value={val}
                      optionName="img"
                      indexCount={i}
                      handleToUpdateValues={updateRow}
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='item_code'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    {/* <TableInnerStyle>{val.item_code}</TableInnerStyle> */}
                    <TableInnerStyle textTransform="uppercase">
                      {val?.prefix && val?.prefix_counter ? (val.prefix + '-' + val.prefix_counter) : ('')}
                    </TableInnerStyle>
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='item'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowSelectBox
                      optionName="item"
                      data={val}
                      updateDataFunction={updateRow}
                      options={allItemsData}
                      indexCount={i}
                      itsMinWidth={150}
                      error={!val.item && errorForTags}
                    />
                  </td>
                </TableColumnVisibiltyFunction>


                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='stamp'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowSelectBox
                      optionName="stamp"
                      data={val}
                      updateDataFunction={updateRow}
                      options={allStampData}
                      indexCount={i}
                      itsMinWidth={50}
                      align="right"
                      error={!val.stamp && errorForTags}
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='grwt'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="grwt"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      decimalCounting={3}
                      maxLimit={5}
                      align="right"
                      error={!val.grwt && errorForTags}
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='less'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>

                    <SaleItemRowInput
                      optionName="less"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      decimalCounting={3}
                      maxLimit={4}
                      align="right"
                      lessWeightPopup={lessWeightPopup}
                      setLessWeightPopup={setLessWeightPopup}
                      readOnly={val?.less_details.length > 0}
                      fontColor={val?.less_details.length > 0 && 'highlight'}


                    // autoFocus={true}
                    />

                    <LessWeightPopup
                      popupState={lessWeightPopup}
                      setPopupState={setLessWeightPopup}
                      indexCount={i}
                      parentData={val}
                      setParentData={setTagItems}
                    />

                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='netwt'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    {Number(val.grwt) >= Number(val.less) ? (
                      <TableInnerStyle align="right">{SetNumbersDecimals(GetCalculationOf_Netwt(val), 3)}</TableInnerStyle>
                    ) : (
                      <TableInnerErrorStyle align="right">Invalid less weight</TableInnerErrorStyle>
                    )}
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='size'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="size"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      align="right"
                      inputType="text"
                      placeholder="Size"
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='qty'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="qty"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      decimalCounting={0}
                      maxLimit={3}
                      align="right"
                      error={!val.qty && errorForTags}
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='uid'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="uid"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      align="right"
                      inputType="text"
                      placeholder="UID"
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='tunch'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="tunch"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      decimalCounting={2}
                      maxLimit={3}
                      align="right"
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='d_carats'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="d_carats"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      decimalCounting={2}
                      maxLimit={3}
                      align="right"
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='color_clarity'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="color_clarity"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      align="right"
                      inputType="text"
                      placeholder="Color / Clarity"
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='remarks'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <div
                      onKeyDown={(event) => {
                        if (event.key === 'Tab' && (tagItems.length) === (tagItems.findIndex(item => item.id === val.id) + 1)) {
                          handleToAppendOneMoreRow()
                        }
                      }}
                    >
                      <SaleItemRowInput
                        optionName="remarks"
                        data={val}
                        updateDataFunction={updateRow}
                        indexCount={i}
                        align="right"
                        inputType="text"
                        placeholder="Remarks"

                      />
                    </div>
                  </td>
                </TableColumnVisibiltyFunction>

                <td style={{ borderBottom: '1px solid #d8d8d8' }}>
                  <TableButtonsGroupStyle>

                    <PopoverCopyRows
                      rowData={val}
                      tagItems={tagItems}
                      setTagItems={setTagItems}
                      prefixDataManager={prefixDataManager}
                      // itemCodeCounter={itemCodeCounter}
                      // setItemCodeCounter={setItemCodeCounter}
                      // itemCodeType={itemCodeType}
                      handleItemCodeOn_CopyRow={handleItemCodeOn_CopyRow}
                    />

                    <TableButtonStyle onClick={() => { handleToDeleteCurruntRow(val.id) }}>
                      <DeleteOutlineIcon sx={{ fill: '#c80500' }} />
                    </TableButtonStyle>

                  </TableButtonsGroupStyle>
                </td>
              </tr >
            ))}






















































            <tr>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='serial_number'>
                <td style={{ borderBottom: '1px solid #d8d8d8' }}></td>
              </TableColumnVisibiltyFunction>

              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='img'>
                <td style={{ borderBottom: '1px solid #d8d8d8' }}></td>
              </TableColumnVisibiltyFunction>

              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='item_code'>
                <td style={{ borderBottom: '1px solid #d8d8d8' }}></td>
              </TableColumnVisibiltyFunction>

              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='item'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle align="right">
                    Final Total
                  </TableInnerStyle>
                </td>
              </TableColumnVisibiltyFunction>

              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='stamp'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}></td>
              </TableColumnVisibiltyFunction>

              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='grwt'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle align="right">
                    {SetNumbersDecimals(totalValuesOf_grwt, 3)}
                  </TableInnerStyle>
                </td>
              </TableColumnVisibiltyFunction>

              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='less'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle align="right">
                    {SetNumbersDecimals(totalValuesOf_less, 3)}
                  </TableInnerStyle>
                </td>
              </TableColumnVisibiltyFunction>

              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='netwt'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle align="right">
                    {SetNumbersDecimals(totalValuesOf_netwt, 3)}
                  </TableInnerStyle>
                </td>
              </TableColumnVisibiltyFunction>

              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='size'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}></td>
              </TableColumnVisibiltyFunction>

              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='qty'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle align="right">
                    {SetNumbersDecimals(totalValuesOf_qty, 0)}
                  </TableInnerStyle>
                </td>
              </TableColumnVisibiltyFunction>

              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='uid'>
                <td style={{ borderBottom: '1px solid #d8d8d8' }}></td>
              </TableColumnVisibiltyFunction>

              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='tunch'>
                <td style={{ borderBottom: '1px solid #d8d8d8' }}></td>
              </TableColumnVisibiltyFunction>

              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='d_carats'>
                <td style={{ borderBottom: '1px solid #d8d8d8' }}></td>
              </TableColumnVisibiltyFunction>

              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='color_clarity'>
                <td style={{ borderBottom: '1px solid #d8d8d8' }}></td>
              </TableColumnVisibiltyFunction>

              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='remarks'>
                <td style={{ borderBottom: '1px solid #d8d8d8' }}></td>
              </TableColumnVisibiltyFunction>

              <td style={{ borderBottom: '1px solid #d8d8d8' }}></td>
            </tr >

            {/* <tr>
              <td colSpan={4} style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                <TableInnerStyle align="right">
                  Final Total
                </TableInnerStyle>
              </td>
              <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                <TableInnerStyle align="right">
                  {SetNumbersDecimals(totalValuesOf_grwt, 3)}
                </TableInnerStyle>
              </td>
              <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                <TableInnerStyle align="right">
                  {SetNumbersDecimals(totalValuesOf_less, 3)}
                </TableInnerStyle>
              </td>
              <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                <TableInnerStyle align="right">
                  {SetNumbersDecimals(totalValuesOf_netwt, 3)}
                </TableInnerStyle>
              </td>
              <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}></td>
              <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                <TableInnerStyle align="right">
                  {SetNumbersDecimals(totalValuesOf_qty, 0)}
                </TableInnerStyle>
              </td>
              <td colSpan={6} style={{ borderBottom: '1px solid #d8d8d8' }}></td>
            </tr> */}






          </tbody>
        </TableStyle>
      </TableContainer>

    </>
  )
}

export default TagItemRow