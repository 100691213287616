import { API_URL } from "context/dataContext";
import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const axios = require("axios").default;

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);

  const navigate = useNavigate();

  const [cookies, setCookies, removeCookie] = useCookies();

  useEffect(() => {
    //setToken(token);
    var token = cookies.token;
    console.log("token", token);
    if (token) {
      //login(token);
      //navigate("/dashboards/analytics");
    } else {
      setCookies("token", null, { path: "/" });
      setCookies("email", null, { path: "/" });
      setCookies("type", null, { path: "/" });
      console.log(cookies);
      navigate("/");
    }
  }, []);

  const login = (userToken, userEmail, userType) => {
    //setToken(userToken);
    var date = new Date();
    // add a day
    date.setDate(date.getDate() + 1);

    setCookies("email", userEmail, { path: "/", expires: date });
    setCookies("token", userToken, { path: "/", expires: date });
    setCookies("type", userType, { path: "/", expires: date });
  };

  const logout = () => {
    //setToken(null);
    axios
      .post(`${API_URL}/api/users/logout`, {})
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.log("Something went wrong");
        console.log(error);
      });

    //removeCookie('token');
    //removeCookie('type');
    //removeCookie('email');
    setCookies("token", null, { path: "/" });
    setCookies("email", null, { path: "/" });
    setCookies("type", null, { path: "/" });
    console.log(cookies);
    navigate("/");
  };
  const isAuthenticated = !!token;

  const value = useMemo(
    () => ({
      cookies,
      login,
      logout,
    }),
    [cookies]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};
