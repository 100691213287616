import MDButton from 'components/MDButton'
import React from 'react'

const FM_Button2 = ({actionButton}) => {
  return (
    <MDButton
      variant="gradient"
      color="primary"
      onClick={actionButton}
      style={{
        minWidth: 100,
        flexShrink: 0,
        height: 40,
        marginLeft: 'auto'
      }}>
      search
    </MDButton>
  )
}

export default FM_Button2