// ProductsList page components
import { useContext } from "react";
import IdCell from "./IdCell";
import DefaultCell from "components/DataTable/DefaultCell";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import GroupPopup from "../../components/GroupPopup";
import { useAuth } from "utils/AuthProvider";
import { DataContext } from "context/dataContext";
import moment from "moment";
import DeleteConfirmationPopover from "components/DeleteConfirmationPopover";

// const axios = require('axios').default





const dataTableData = {
  columns: [
    {
      Header: "S/N",
      accessor: "id",
      width: 50,
      link: 'account_group_',
      linkByID: true,
      openAsPopup: true,
      Cell: ({ value }) => <IdCell id={value} />,
    },
    {
      Header: "Name",
      accessor: "name",
      width: 200,
      link: 'account_group_',
      linkByID: true,
      openAsPopup: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Group Type",
      accessor: "type",
      width: 200,
      link: 'account_group_',
      linkByID: true,
      openAsPopup: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      width: 200,
      link: 'account_group_',
      linkByID: true,
      openAsPopup: true,
      Cell: ({ value }) => <DefaultCell value={value ? moment(value).format('DD/MM/YYYY') : ''} />,
    },
    {
      Header: "Actions",
      accessor: "actions",
      width: 10,
      align: "right",
      Cell: ({ row }) => {
        const { updateAccountGroupsApi, deleteSingleAccountGroupApi } = useContext(DataContext);

        const handleToEditAccountGroup = (data) => {
          updateAccountGroupsApi(data)
        }

        return (
          <MDBox display="flex" gap={2} id={`account_group_${row.original.id}`}>
            <GroupPopup
              name="Edit"
              size="small"
              createFunction={handleToEditAccountGroup}
              rowName={row.original.group_name}
              addOrEdit="edit"
              rowId={row.original.id}
            />
            <DeleteConfirmationPopover
              heading="are you sure?"
              buttonText="Delete"
              DeleteFunction={() => { deleteSingleAccountGroupApi(row.original.id) }}
            />
          </MDBox>
        )
      },
    },
  ],
  rows: [],
};

export default dataTableData;