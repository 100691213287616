// export const LOADING = 'LOADING';  // Temporary
// export const TOKENISVALID = 'TOKENISVALID';  // Temporary
// export const TOKEN_ACCESS_RED = 'TOKEN_ACCESS_RED';  // Temporary

export const SET_ALL_ITEMS = "SET_ALL_ITEMS";
export const SET_ITEMS_GROUPS = "SET_ITEMS_GROUPS";
export const UPDATE_ITEMS_GROUPS = "UPDATE_ITEMS_GROUPS";
export const UPDATE_ALL_ITEMS = "UPDATE_ALL_ITEMS";
export const DELETE_SINGLE_ITEMS = "DELETE_SINGLE_ITEMS";
export const DELETE_SINGLE_ITEMS_GROUP = "DELETE_SINGLE_ITEMS_GROUP";
export const DELETE_SINGLE_ACCOUNT = "DELETE_SINGLE_ACCOUNT";
export const DELETE_SINGLE_STAMP = "DELETE_SINGLE_STAMP";
export const DELETE_SINGLE_ACCOUNT_GROUP = "DELETE_SINGLE_ACCOUNT_GROUP";
export const SET_ALL_ACCOUNTS = "SET_ALL_ACCOUNTS";
export const SET_ALL_STAMPS = "SET_ALL_STAMPS";
export const UPDATE_ALL_STAMPS = "UPDATE_ALL_STAMPS";
export const SET_ACCOUNT_GROUPS = "SET_ACCOUNT_GROUPS";
export const UPDATE_ALL_ACCOUNTS = "UPDATE_ALL_ACCOUNTS";
export const UPDATE_ACCOUNT_GROUPS = "UPDATE_ACCOUNT_GROUPS";
export const SET_ALL_VOUCHER = "SET_ALL_VOUCHER";
export const SET_ALL_LEDGERS = "SET_ALL_LEDGERS";
export const SET_ALL_SALES = "SET_ALL_SALES";
export const SET_ALL_PAYMENTS = "SET_ALL_PAYMENTS";
export const SET_ALL_STOCK = "SET_ALL_STOCK";
export const SET_ALL_TAGS = "SET_ALL_TAGS";
export const SET_ALL_TAGS_BY_VOUCHER = "SET_ALL_TAGS_BY_VOUCHER";
export const CURRENT_VOUCHER_OF_ALL_TAGS = "CURRENT_VOUCHER_OF_ALL_TAGS";



export const SHOW_SNACKBAR_ALERT = "SHOW_SNACKBAR_ALERT";
export const HIDE_SNACKBAR_ALERT = "HIDE_SNACKBAR_ALERT";

export default (state, action) => {
  switch (action.type) {
    // case LOADING: // Temporary
    //   return {
    //     ...state,
    //     loading: action.payload
    //   };
    // case TOKENISVALID: // Temporary
    //   return {
    //     ...state,
    //     tokenIsValid: action.payload
    //   };
    // case TOKEN_ACCESS_RED: // Temporary
    //   return {
    //     ...state,
    //     token_access: action.payload
    //   };

    case SET_ALL_ITEMS:
      return {
        ...state,
        all_items: action.payload,
        all_items_is_enable: action.payload.filter((item) => item.is_enable === true),
      };

    case UPDATE_ALL_ITEMS:
      return {
        ...state,
        all_items: [action.payload, ...state.all_items],
        all_items_is_enable: [action.payload, ...state.all_items_is_enable],
      };

    case DELETE_SINGLE_ITEMS:
      return {
        ...state,
        all_items: state.all_items.filter((item) => item.id !== action.payload),
        all_items_is_enable: state.all_items_is_enable.filter((item) => item.id !== action.payload),
      };

    case DELETE_SINGLE_ITEMS_GROUP:
      return {
        ...state,
        items_groups: state.items_groups.filter((item) => item.id !== action.payload),
      };

    case DELETE_SINGLE_ACCOUNT:
      return {
        ...state,
        all_accounts: state.all_accounts.filter((item) => item.id !== action.payload),
      };
    case DELETE_SINGLE_STAMP:
      return {
        ...state,
        all_stamps: state.all_stamps.filter((item) => item.id !== action.payload),
        all_stamps_is_enable: state.all_stamps_is_enable.filter(
          (item) => item.id !== action.payload
        ),
      };
    case DELETE_SINGLE_ACCOUNT_GROUP:
      return {
        ...state,
        account_groups: state.account_groups.filter((item) => item.id !== action.payload),
      };

    case SET_ITEMS_GROUPS:
      return {
        ...state,
        items_groups: action.payload,
      };

    case UPDATE_ITEMS_GROUPS:
      return {
        ...state,
        items_groups: [action.payload, ...state.items_groups],
      };

    case SET_ACCOUNT_GROUPS:
      return {
        ...state,
        account_groups: action.payload,
      };

    case UPDATE_ACCOUNT_GROUPS:
      return {
        ...state,
        account_groups: [action.payload, ...state.account_groups],
      };

    case SET_ALL_STAMPS:
      return {
        ...state,
        all_stamps: action.payload,
        all_stamps_is_enable: action.payload.filter((item) => item.is_enable === true),
      };

    case UPDATE_ALL_STAMPS:
      return {
        ...state,
        all_stamps: [action.payload, ...state.all_stamps],
        all_stamps_is_enable: [action.payload, ...state.all_stamps_is_enable],
      };

    case SET_ALL_ACCOUNTS:
      return {
        ...state,
        all_accounts: action.payload,
      };

    case UPDATE_ALL_ACCOUNTS:
      return {
        ...state,
        all_accounts: [action.payload, ...state.all_accounts],
      };

    case SET_ALL_VOUCHER:
      return {
        ...state,
        all_voucher: action.payload,
      };

    case SET_ALL_LEDGERS:
      return {
        ...state,
        all_ledgers: action.payload,
      };

    case SET_ALL_SALES:
      return {
        ...state,
        all_sales: action.payload,
      };

    case SET_ALL_STOCK:
      return {
        ...state,
        all_stock: action.payload,
      };
    case SET_ALL_TAGS:
      return {
        ...state,
        all_tags: action.payload,
      };
    case CURRENT_VOUCHER_OF_ALL_TAGS:
      return {
        ...state,
        current_voucher_of_all_tags: action.payload,
      };





    case SET_ALL_TAGS_BY_VOUCHER:
      return {
        ...state,
        all_tagsByVoucher: action.payload,
      };




    case SET_ALL_PAYMENTS:
      return {
        ...state,
        all_payments: action.payload,
      };

    case SHOW_SNACKBAR_ALERT:
      return {
        ...state,
        snackbar_alert: {
          color: action.payload.color,
          title: action.payload.title,
          errorSB: action.payload.errorSB,
        },
      };

    case HIDE_SNACKBAR_ALERT:
      return {
        ...state,
        snackbar_alert: {
          ...state.snackbar_alert,
          errorSB: action.payload,
        },
      };
  }
};
