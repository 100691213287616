import React, { useContext } from 'react'
import { Autocomplete, Grid } from '@mui/material'
import CustomDatepicker from 'components/CustomDatepicker'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import { DataContext } from 'context/dataContext'
import { ConditionalCompare } from 'layouts/transactions/components/functions'
import FM_Button1 from 'components/FilterMenu/FM_Button1'
import FilterMenuContainer from 'components/FilterMenu/FilterMenuContainer'
import ErrorText from 'components/ErrorText'

const FilterMenu = ({
  stockInfo,
  setStockInfo,
  stockValidation
}) => {
  const { dataState } = useContext(DataContext)

  let items_groups_and_item_group_type = [...dataState.items_groups, ...dataState.item_group_type]


  const handelToUpdateInfo = (value, key) => {

    setStockInfo(prevData => ({
      ...prevData,
      [key]: value,
    }));

  }



  function resetParams() {
    setStockInfo(prevData => ({
      ...prevData,
      stock_no: "",
      selected_on: "",
      // category: "",
      vendor: "",
    }));
  }




  return (
    <>


      <FilterMenuContainer>



        <MDBox sx={{ width: '100%', maxWidth: '25%', position: 'relative' }}>
          <CustomDatepicker
            value={stockInfo.selected_on || new Date()}
            valueKey="selected_on"
            editValue={handelToUpdateInfo}
            label="Select Date"
          />
        </MDBox>




        <MDBox sx={{ width: '100%', maxWidth: '25%', position: 'relative' }}>
          <Autocomplete
            value={
              items_groups_and_item_group_type.find((item) => ConditionalCompare(item.id, '===', stockInfo.item_group_id)) || null
            }
            options={items_groups_and_item_group_type}
            getOptionLabel={(innerOptions) => innerOptions?.name}

            onChange={(_, newValue) => {
              handelToUpdateInfo(newValue?.id || null, 'item_group_id');
            }}

            sx={{
              '& .MuiFormControl-root': { background: 'initial' },
              ...(stockValidation.item_group_id && stockInfo?.item_group_id === null && {
                '& .MuiInput-root:before': { borderColor: '#F44335' },
                '& .MuiInputLabel-root': { color: '#F44335' },
              }),
            }}

            renderInput={(params) => (
              <MDInput
                {...params}
                label="Item Group"
                variant="standard"
                error={stockValidation.item_group_id && stockInfo?.item_group_id === null}
              />
            )}
          />
          {stockValidation.item_group_id && stockInfo?.item_group_id === null && (
            <ErrorText errorName="Customer is required" />
          )}
        </MDBox>



        <FM_Button1
          actionButton={resetParams}
        />

      </FilterMenuContainer>


    </>
  )
}

export default FilterMenu