import MDButton from 'components/MDButton'
import React from 'react'

const FM_Button1 = ({actionButton}) => {
  return (
    <MDButton
      variant="outlined"
      color="error"
      onClick={actionButton}
      style={{
        minWidth: 100,
        flexShrink: 0,
        height: 40,
        marginLeft: 'auto'
      }}>
      reset
    </MDButton>
  )
}

export default FM_Button1