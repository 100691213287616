

import { useContext, useEffect, useState } from "react";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "components/DataTable";
// import 'react-date-range/dist/styles.css'; // main css file
// import 'react-date-range/dist/theme/default.css'; // theme css file
import dataTableDataByTags from "./components/dataTableDataByTags";
import dataTableDataByVoucherID from "./components/dataTableDataByVoucherID";
// import FormField from "layouts/account/components/FormField";
import { DataContext } from "context/dataContext";
// import SelectComponent from "components/SelectComponent";
import FilterMenu from "./components/FilterMenu";
// import ComplexStatisticsCard from "components/Cards/StatisticsCards/ComplexStatisticsCard";
// import { SetFormattedIndianNumber } from "components/globalFunctions";
import NoDataFound from "components/NoDataFound";
// import { transactionsTypeOption2 } from "components/const";
// import { transactionsTypeOption } from "components/const";
import { flowType_In_Out } from "components/const";


function AllTags() {
  const { dataState } = useContext(DataContext)

  const [data, setData] = useState({});
  // const [dataByTag, setDataByTag] = useState({ ...dataTableDataByTags, rows: [] });

  // all_tags
  // all_tagsByVoucher


  useEffect(() => {

    if (dataState.all_tags.length > 0) {
      let updatedValue = dataState.all_tags.map((itemData) => {
        let typeDetails = flowType_In_Out.find((e) => e.status === itemData.type)
        let itemDetails = dataState.all_items.find((e) => e.id === Number(itemData.item));
        let stampsDetails = dataState.all_stamps.find((e) => e.id === Number(itemData.stamp));
        let prefixDetails = dataState.all_voucher.find((e) => e.id === Number(itemData.linked_id));

        return {
          ...itemData,
          prefix: prefixDetails?.prefix || '',
          id: itemData.linked_counter || '',
          type: typeDetails?.name || '',
          item: itemDetails?.name || '',
          stamp: stampsDetails?.name || '',
        }
      })
      setData({
        ...dataTableDataByTags,
        rows: updatedValue
      });
    }
    else if (dataState.all_tagsByVoucher.length > 0) {
      let updatedValue = dataState.all_tagsByVoucher.map((itemData) => {
        // let typeDetails = flowType_In_Out.find((e) => e.status === itemData.type)
        // let itemDetails = dataState.all_items.find((e) => e.id === Number(itemData.item));
        // let stampsDetails = dataState.all_stamps.find((e) => e.id === Number(itemData.stamp));
        let prefixDetails = dataState.all_voucher.find((e) => e.id === Number(itemData.linked_id));

        return {
          ...itemData,
          prefix: prefixDetails?.prefix || '',
          id: itemData.linked_id || '',
          // type: typeDetails?.name || '',
          // item: itemDetails?.name || '',
          // stamp: stampsDetails?.name || '',
        }
      })
      setData({
        ...dataTableDataByVoucherID,
        rows: updatedValue
      });
    }




  }, [dataState.all_tags, dataState.all_tagsByVoucher, dataState.all_items, dataState.all_stamps,dataState.all_voucher]);









  return (
    <DashboardLayout>





      <DashboardNavbar />



      {/* <MDBox display="flex" justifyContent="space-evenly" my={2} gap={2}> */}


      {/* <Grid item sm={2} xs={2}> */}
      <MDBox my={2}>
        <FilterMenu />
      </MDBox>
      {/* </Grid> */}




      {/* <Grid item sm={10} xs={10}> */}
      <MDBox my={2}>
        <Card>
          {data?.rows?.length > 0 ? (
            <DataTable table={data} canSearch />
          ) : (
            <MDBox p={2}>
              <NoDataFound
                title="No Data Found"
              />
            </MDBox>
          )}
        </Card>
      </MDBox>
      {/* </Grid> */}




      {/* </MDBox> */}





    </DashboardLayout>
  );
}

export default AllTags;
