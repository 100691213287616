import React, { useContext } from 'react'

import { Autocomplete, Grid } from '@mui/material'
import CustomDatepicker from 'components/CustomDatepicker'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import { useState, useEffect } from 'react'
import { useAuth } from 'utils/AuthProvider'
import { DataContext } from "context/dataContext";
import { ConditionalCompare } from 'layouts/transactions/components/functions'
import FM_Button1 from 'components/FilterMenu/FM_Button1'
import FilterMenuContainer from 'components/FilterMenu/FilterMenuContainer'
import ErrorText from 'components/ErrorText'

const axios = require('axios').default

const FilterMenu = ({
  userInfo,
  setUserInfo,
  userInfoValidation,
}) => {

  const { cookies } = useAuth();
  axios.defaults.headers.common = { 'Authorization': `Bearer ${cookies.token}` }

  const { dataState } = useContext(DataContext)


  let items_groups_and_item_group_type = [...dataState.items_groups, ...dataState.item_group_type]

  // const [customers, setCustomers] = useState([
  //   { label: 'Select', id: 0, _id: 0, phone: '123456789' }
  // ]);

  const handelToUpdateUserInfo = (value, key) => {
    setUserInfo(prevData => ({
      ...prevData,
      [key]: value,
    }));
  }

  function resetParams() {
    setUserInfo(prevData => ({
      ...prevData,
      item_group: "",
      selected_date: "",
    }));
  }

  useEffect(() => {
    var accounts = dataState.all_accounts
    var accountitems = []
    accounts.forEach((cus, i) => {
      accountitems.push({ label: cus.name, id: cus.id, phone: cus.contact_no, _id: cus._id })
    });
    console.log(accountitems)
    // setCustomers(accountitems)

    handelToUpdateUserInfo(new Date(), 'selected_date')

  }, [dataState.all_accounts]);




  return (
    <>


      <FilterMenuContainer>



        <MDBox sx={{ width: '100%', maxWidth: '25%', position: 'relative' }}>
          <CustomDatepicker
            value={userInfo.selected_date || new Date()}
            valueKey="selected_date"
            editValue={handelToUpdateUserInfo}
            label="Select Date"
          />
        </MDBox>








        <MDBox sx={{ width: '100%', maxWidth: '25%', position: 'relative' }}>
          <Autocomplete
            value={
              items_groups_and_item_group_type.find((item) => ConditionalCompare(item.id, '===', userInfo.item_group)) || null
            }
            options={items_groups_and_item_group_type}
            getOptionLabel={(innerOptions) => innerOptions?.name}

            onChange={(_, newValue) => {
              handelToUpdateUserInfo(newValue?.id || null, 'item_group');
            }}

            sx={{
              '& .MuiFormControl-root': { background: 'initial' },
              ...(userInfoValidation.item_group && userInfo?.item_group === null && {
                '& .MuiInput-root:before': { borderColor: '#F44335' },
                '& .MuiInputLabel-root': { color: '#F44335' },
              }),
            }}

            renderInput={(params) => (
              <MDInput
                {...params}
                label="Item Group"
                variant="standard"
                error={userInfoValidation.item_group && userInfo?.item_group === null}
              />
            )}
          />
          {userInfoValidation.item_group && userInfo?.item_group === null && (
            <ErrorText errorName="Customer is required" />
          )}
        </MDBox>






        {/* <MDInput
          type='number'
          variant="standard"
          label="Bill No"
          value={userInfo.bill_number}
          onChange={(e) => {
            handelToUpdateUserInfo(e.target.value, 'bill_number')
          }}
          style={{
            width: '100%'
          }}
        /> */}



        <FM_Button1
          actionButton={resetParams}
        />

      </FilterMenuContainer>


    </>
  )
}

export default FilterMenu