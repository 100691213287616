
import styled from '@emotion/styled'


export const TableInnerStyle = styled.p`
  font-size: 14px;
  padding: 5px;
  white-space: pre;
  font-weight: 600;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: ${(props) => props.align ? props.align : 'left'};
  width: ${(props) => props.itsWidth ? `${props.itsWidth}px` : 'auto'};
  color: ${(props) => props.color ? props.color : 'initial'};
  text-transform: ${(props) => props.textTransform ? props.textTransform : 'capitalize'};
  ${(props) => props.itsError ? 'border: 2px solid #c80500' : ''}


  
`
export const TableInnerErrorStyle = styled.p`
  font-size: 11px;
  padding: 5px 10px;
  font-weight: 600;
  color: #be0000;
  line-height: 1.1;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  word-spacing: 1px;
  
  text-align: ${(props) => props.align ? props.align : 'left'};
`

export const TableButtonsGroupStyle = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
  /* gap: 5px; */
`
export const TableButtonStyle = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  /* background: #e7dbc0; */
  border-radius: 4px;
  flex-shrink: 0;

  svg{
    width: 20px;
    height: 20px;
  }
`

export const LessContainerWrrprStyle = styled.div`
  position: relative;
  display: grid;
  gap: 24px;
  margin-bottom: ${(props) => props.mb_none ? '0' : '30px'};
 

  
  .divider{
    position: absolute;
    top: 20px;
    bottom: 18px;
    right: -20px;
    left: auto;
    width: 20px;
    border: 2px solid #e7dbc0;
    border-left: hidden;
    border-radius: 0 8px 8px 0;
  }



`



export const LessDeleteStyle = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #e7dbc0;
  border-radius: 50px;
  flex-shrink: 0;

  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: -35px;

  /* :before,
  :after{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 2px;
    right: 0;
    margin: auto;
    border: 2px solid #e7dbc0;
    height: 21px;
    width: 22px;
  } */

  /* :before{
    transform: translate(-50%, calc(-100% - 4px));
    border-left: hidden;
    border-bottom: hidden;
    border-top-right-radius: 8px;
  }

  :after{
    transform: translate(-50%, calc(100% + 4px));
    border-top: hidden;
    border-left: hidden;
    border-bottom-right-radius: 8px;
  } */





  svg{
    width: 18px;
    height: 18px;
  }
`



export const InputBoxReplicaStyle = styled.div`
    /* background: rgb(0 0 0 / 5%); */
    border: 1px solid #d2d6da;
    border-radius: 6px;
    position: relative;
    background: #f5f5f5;
    
    background: ${(props) => props.background ? props.background : '#f5f5f5'};

  label{
    font-weight: 400;
    letter-spacing: 0.00938em;
    display: block;
    color: #7b809a;
    font-size: 12px;
    line-height: 1.5;
    position: absolute;
    top: -10px;
    left: 7px;
    background: #fff;
    padding: 0px 6px;
    border-radius: 10px;
  }
  p{
    height: 100%;
    text-align: left;
    font-weight: 600;
    padding: 5px 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 42px;
  }

`




export const PopoverModalStyle = styled.div`

  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;

  svg{
    background: #f6f1e4;
    border: 1px solid #e5e0d2;
    height: 30px;
    width: 30px;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
  }

    

`

export const TableStyle = styled.table`
  width: 100%;
  text-align: left;
  /* border: 1px solid #d8d8d8; */
  border-bottom: none;
  border-radius: 3px;
  overflow: hidden;
`;

export const TableHeaderInsideStyle = styled.p`
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  display: flex;
  align-items: center;
  padding: 5px;
  height: 30px;
  background-color: #a47e1b;
  color: #fff;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  line-height: 1.1;
`;









