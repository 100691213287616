

import { useContext, useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";
// import Autocomplete from "@mui/material/Autocomplete";

//  React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// import MDInput from "components/MDInput";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";

// import { addDays } from 'date-fns';
// import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// import FormField from "layouts/account/components/FormField";
// import { ElevatorSharp } from "@mui/icons-material";


// import { salePanelPaymentOption, paymentMethodOption } from "components/const";
import { v4 as uuidv4 } from 'uuid';
// import { TableInnerStyle } from "../../transactions/components/style";
// import SalePanelRow from "../../transactions/components/SalePanelRow";
// import { SetFormattedIndianNumber } from "components/globalFunctions";
// import { ConditionalCompare, GetCalculationOf_ItemFine, GetCalculationOf_ItemTotal, GetCalculationOf_Netwt, GetCalculationOf_Row2_Fine, TotalCalculationOf_Fine, TotalCalculationOf_GrandFine, TotalCalculationOf_GrandTotal, TotalCalculationOf_ItemTotal } from "../../transactions/components/functions";
// import { TableContainer } from "@mui/material";
// import GrandTotal from "../../transactions/components/GrandTotal";
import FilterMenu from "./components/FilterMenu";
// import TagItemRow from "./components/TagItemRow";
import TagItemRow from "../../transactions/components/TagItemRow";
import Label from "layouts/invoice/components/Label";
import { DataContext } from 'context/dataContext'








function Tags() {
  const { dataState, createTagStockApi } = useContext(DataContext)
  // const [menu, setMenu] = useState(null);
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  // const [selectDate, setSelectDate] = useState(new Date().toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' }));
  // const [setdate, setDate] = useState(new Date());
  // const [bill, setBill] = useState(1);
  // const [dateopen, setDateOpen] = useState(false);
  // const [customer, setCustomer] = useState('Select');

  const allDataSourceTableMaxLength = 50 // Temporary Value
  // const [itemCodeCounter, setItemCodeCounter] = useState(1340);
  // const [counterForCopyRow, setCounterForCopyRow] = useState(null);
  // const itemCodeType = 'BL-';




  const [itemTableHeaderData, setItemTableHeaderData] = useState([
    { name: "S/N", align: "left", key: "serial_number", visibilty: true },
    { name: "IMG", align: "left", key: "img", visibilty: true },
    { name: "Code", align: "left", key: "item_code", visibilty: true },
    { name: "Item", align: "left", key: "item", visibilty: true },
    { name: "Stamp", align: "left", key: "stamp", visibilty: true },
    { name: "Gr.WT", align: "right", key: "grwt", visibilty: true },
    { name: "Less", align: "right", key: "less", visibilty: true },
    { name: "Net.WT", align: "right", key: "netwt", visibilty: true },
    { name: "Size", align: "right", key: "size", visibilty: true },
    { name: "Qty", align: "right", key: "qty", visibilty: true },
    { name: "UID", align: "right", key: "uid", visibilty: true },
    { name: "Tunch", align: "right", key: "tunch", visibilty: true },
    { name: "D. Carats", align: "right", key: "d_carats", visibilty: true },
    { name: "Color / Clarity", align: "right", key: "color_clarity", visibilty: true },
    { name: "Remarks", align: "right", key: "remarks", visibilty: true },
  ])



  const [tagItems, setTagItems] = useState([{
    id: uuidv4(),
    img: null,
    item_code: '',
    item: null,
    stamp: null,
    grwt: 0.000,
    less: 0.000,
    less_details: [
    ],
    netwt: 0.000,
    size: "",
    qty: 0.00,
    uid: "",
    tunch: 100.00,
    d_carats: 0.00,
    color_clarity: "",
    remarks: "",
  }]);
  // const [tagItems, setTagItems] = useState([
  //   { "id": "46382a5f-9ef7-4143-aa82-31a85f8d8be2", "img": null, "prefix": "tk", "prefix_counter": 1001, "item": "0b0acf3e-752e-467c-a3ab-c02978a74255", "grwt": 13.264, "less": 2.362, "netwt": "0.000", "size": 43, "qty": 8, "uid": "LO845", "tunch": 100, "d_carats": 31, "color_clarity": "red", "remarks": "mark 1", "unit": "Kg" },
  //   { "id": "46382a1f-1ef7-4143-aa82-31a85f8d8be2", "img": null, "prefix": "tk", "prefix_counter": 1002, "item": "0b0acf3e-752e-467c-a3ab-c02978a74255", "grwt": 6.3, "less": 3.24, "netwt": "0.000", "size": 46, "qty": 29, "uid": "PO71", "tunch": 94, "d_carats": 3, "color_clarity": "Black", "remarks": "mark2", "unit": "Carat" },
  //   { "id": "8921c97e-0833-443e-9678-e359163585c5", "img": null, "prefix": "bl", "prefix_counter": 1001, "item": "4b8b4b51-2a17-4bb3-ad69-afcfc4161dc8", "grwt": 1.2, "less": 0, "netwt": "0.000", "size": 48, "qty": 6, "uid": "NW66", "tunch": 100, "d_carats": 12, "color_clarity": "green", "remarks": "mark3", "unit": "Ratti" },
  //   { "id": "9a9a356c-f911-4e98-8e47-b568555b9123", "img": null, "prefix": "tp", "prefix_counter": 1001, "item": "b14b166f-add3-40dc-90f5-87fcf098831e", "grwt": 45.10, "less": 10, "netwt": "0.000", "size": 54, "qty": 14, "uid": "DK58", "tunch": 96, "d_carats": 45, "color_clarity": "blue", "remarks": "mark3", "unit": "Gm" }
  // ]);








  // let today = moment().format("MMM DD, YYYY");

  const [stockInfo, setStockInfo] = useState({
    selected_on: new Date(),
    item_group_id: null,
    type: "Opening Stock"
  });



  const [stockValidation, setStockValidation] = useState({
    item_group_id: false,
  });

  const [errorForTags, setErrorForTags] = useState(false)





  // Placeholder to Add New Row
  const newRow = {
    id: uuidv4(),
    img: null,
    item: null,
    item_code: "",
    stamp: null,
    prefix: "",
    prefix_counter: 0,
    grwt: 0.000,
    less: 0.000,
    less_details: [],
    netwt: 0.000,
    size: "",
    qty: 0.00,
    uid: "",
    tunch: 0.00,
    d_carats: 0.00,
    color_clarity: "",
    remarks: "",
  };


  // Adding a New Empty Row
  const handleToAppendOneMoreRow = () => {
    if (tagItems.length < allDataSourceTableMaxLength) {
      setTagItems(prevData => [
        ...prevData,
        {
          ...newRow,
          // item_code: incrementItemCodeForAppend()
        }
      ]);
    } else {
      // setCounterForCopyRow(0)
      console.log('Cannot add more rows. The maximum allowable row limit of 50 has been reached.');
    }
  };


  // const incrementItemCodeForAppend = () => {
  //   let tempitemCode = itemCodeCounter
  //   setItemCodeCounter(tempitemCode + 1)
  //   return itemCodeType + itemCodeCounter
  // }












































  const [disablebuttons, setDisablebuttons] = useState({
    save: false,
    save_print: false,
  })



  const handelToDisableButton = (value, key) => {
    setDisablebuttons(prevData => ({
      ...prevData,
      [key]: value,
    }));
  }







  const collectAndUpdateStateInfo = () => {

    const updatedStockInfo = {
      ...stockInfo,
    };
    setStockInfo(updatedStockInfo);

    const updatedItems = tagItems.map((item) => ({
      ...item,
      netwt: item.grwt - item.less,
      item_code: item.prefix && item.prefix_counter ? (item.prefix + '-' + item.prefix_counter) : ''
    }));
    setTagItems(updatedItems);

    // console.log('Old Data ', { items: saleItems, panels: panels, total: grandTotal })
    return { stockInfo: updatedStockInfo, tagItems: updatedItems }
  }














  const saveTagData = () => {
    handelToDisableButton(true, 'save')
    console.log('saveTagData =================>');
    const newData = collectAndUpdateStateInfo()
    console.log('New Data ', newData);
    saveTag(newData)
    setTimeout(() => {
      handelToDisableButton(false, 'save')
    }, 500);
    handleItemCodeOn_Save()
  }



  // Print State To Manage Invoice Print
  const [roughPrintState, setInvoicePrintState] = useState(false);





  const [tagData, setTagData] = useState(null);

  const savePrintTagData = () => {
    handelToDisableButton(true, 'save_print')
    console.log('savePrintTagData =================>');
    const newData = collectAndUpdateStateInfo();
    console.log('New Data ', collectAndUpdateStateInfo());

    setTagData(newData);
    saveTag(newData)
    setInvoicePrintState(true)


    setTimeout(() => {
      handelToDisableButton(false, 'save_print')
    }, 1000);
    handleItemCodeOn_Save()
  }


  // const cancelTagData = () => {
  //   console.log('cancelTagDatav =================>');

  // }





























  // const [stockInfo, setStockInfo] = useState({
  //   date: "",
  //   item_group_id: null
  // });


  // const [stockValidation, setStockValidation] = useState({
  //   item_group_id: false,
  // });


  // const [errorForTags, setErrorForTags] = useState(false)




  function saveTag(newData, print) {

    const allItemsValid = newData.tagItems.every((val) => {
      return val.item && val.stamp && val.grwt && val.qty
    });
    setErrorForTags(!allItemsValid);

    stockInfo?.item_group_id === null ? (
      setStockValidation({ item_group_id: true })
    ) : (
      setStockValidation({ item_group_id: false })
    )

    if (stockInfo?.item_group_id === null || !allItemsValid) {
      handelToDisableButton(false, 'save')
      handelToDisableButton(false, 'save_print')
      return
    }
    else {
      setErrorForTags(false);
      createTagStockApi(newData).then((res) => {
        if (res.success) {
          // Reset Values
          setTagItems([newRow])
          setStockInfo({
            item_group_id: null,
            selected_on: ""
          });
        }
      });




      handelToDisableButton(false, 'save')
      if (print) {
        setInvoicePrintState(true)
        handelToDisableButton(false, 'save_print')
      }
    }

  }






























































































  const [prefixDataManager, setPrefixDataManager] = useState([]);

  const setPrefixData = () => {
    let updatePDF = dataState.all_items.map((item) => ({
      id: item.id,
      name: item.name,
      prefix: item.prefix,
      old_counter: Number(item.prefix_counter),
      new_counter: Number(item.prefix_counter)
    }));

    setPrefixDataManager(updatePDF);
  };

  useEffect(() => {
    // if (prefixDataManager?.length === 0) {
    setPrefixData();
    // }
  }, [dataState.all_items]);










  const updatePrefixData = (id, newPrefix) => {
    setPrefixDataManager((prevItems) => (
      prevItems.map((item) => (
        item.id === id ? {
          ...item,
          new_counter: newPrefix
        } : item
      ))
    ));
  };



  const handleToManageItemCode = async (id, index) => {
    let curruntPrefixData = prefixDataManager.find((item) => item.id === id)

    let newPrefixCount = curruntPrefixData.new_counter + 1

    setTagItems((prevItems) => (
      prevItems.map((item, indx) => (
        indx === index ? {
          ...item,
          prefix: curruntPrefixData.prefix,
          prefix_counter: newPrefixCount
        } : item
      ))
    ));

    updatePrefixData(id, newPrefixCount)

  }



  const handleItemCodeOn_CopyRow = (id, newPrefix) => {
    updatePrefixData(id, newPrefix)

  }

  const handleItemCodeOn_UpdateItem = (id, index) => {
    handleToManageItemCode(id, index)
  }







  const handleItemCodeOn_Save = () => {
    // console.log('handleItemCodeOn_Save');

  }












  // Create New Item Code 
















































































































  return (
    <DashboardLayout>








      {tagData && <Label
        visibility={false}
        printState={roughPrintState}
        printStateUpdate={setInvoicePrintState}
        invoicesData={tagData}
      />}









      <DashboardNavbar />






      <MDBox my={2}>
        <FilterMenu
          stockInfo={stockInfo}
          setStockInfo={setStockInfo}
          stockValidation={stockValidation}
        />
      </MDBox>





      <MDBox mb={2}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="flex-start">
            <TagItemRow
              tagItems={tagItems}
              setTagItems={setTagItems}
              // itemCodeCounter={itemCodeCounter}
              // setItemCodeCounter={setItemCodeCounter}
              prefixDataManager={prefixDataManager}
              // itemCodeType={itemCodeType}
              handleToAppendOneMoreRow={handleToAppendOneMoreRow}
              handleItemCodeOn_UpdateItem={handleItemCodeOn_UpdateItem}
              handleItemCodeOn_CopyRow={handleItemCodeOn_CopyRow}
              errorForTags={errorForTags}

              itemTableHeaderData={itemTableHeaderData}
              setItemTableHeaderData={setItemTableHeaderData}

            />
          </MDBox>
        </Card>
      </MDBox>





      <MDBox mb={2}>
        <Card>
          <MDBox display="flex" justifyContent="center" gap={2} p={2}>

            <MDButton
              onClick={() => {
                !disablebuttons.save && saveTagData()
              }}
              variant="outlined"
              color="primary"
            > Save </MDButton>

            <MDButton
              onClick={() => {
                !disablebuttons.save_print && savePrintTagData()
              }}
              variant="gradient"
              color="primary"
            > Save & Print </MDButton>

            <MDButton
              onClick={() => {
                handleToAppendOneMoreRow()
              }}
              variant="outlined"
              color="primary"
            > Add Row </MDButton>

          </MDBox>
        </Card>
      </MDBox>






      {/* <Footer /> */}
    </DashboardLayout >
  );
}

export default Tags;



