import React, { useContext } from 'react'

import { Autocomplete, Grid } from '@mui/material'
// import CustomDatepicker from 'components/CustomDatepicker'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import { useState, useEffect } from 'react'
// import { useAuth } from '../../../../utils/AuthProvider'
import { DataContext } from "context/dataContext";
import { addDays } from 'date-fns';
import { DateRange } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import SelectComponent from 'components/SelectComponent'
import { ConditionalCompare } from 'layouts/transactions/components/functions'
import FilterMenuContainer from 'components/FilterMenu/FilterMenuContainer'
import FM_ButtonGroup from 'components/FilterMenu/FM_ButtonGroup'
import FM_Button2 from 'components/FilterMenu/FM_Button2'
import FM_Button1 from 'components/FilterMenu/FM_Button1'
import MonthsSelectComponent from 'components/MonthsSelecComponent'
import moment from 'moment'






// const axios = require('axios').default

const FilterMenu = () => {

  // const { cookies } = useAuth();
  // axios.defaults.headers.common = { 'Authorization': `Bearer ${cookies.token}` }

  const { dataState } = useContext(DataContext)





  const [filterMenuData, setFilterMenuData] = useState({
    stock: null,
    item: null,
    date: {
      startDate: '',
      endDate: '',
      key: 'selection'
    },
  })



  let items_groups_and_item_group_type = [...dataState.items_groups, ...dataState.item_group_type]
  const [allItems, setAllItems] = useState([])




  let today = moment().format("MMM DD, YYYY");
  // let oneMonthAgo = moment().subtract(1, 'months').format("MMM DD, YYYY");

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [dateopen, setDateOpen] = useState(false);

  const [datestate, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection'
    }
  ]);
  const [origselection, setOrigSelection] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection'
    }
  ]);



  const handelToUpdateData = (key, value) => {
    setFilterMenuData(prevData => ({
      ...prevData,
      [key]: value,
    }));
    console.log(filterMenuData)
  }

  function resetParams() {
    setFilterMenuData(prevData => ({
      ...prevData,
      stock: null,
      item: null,
      date: '',
    }));

    setStartDate('')
    setEndDate('')
    setDateState(origselection)
  }





  console.log('items_groups_and_item_group_type', items_groups_and_item_group_type);
  console.log('all_items_is_enable', dataState.all_items_is_enable);
  console.log('_______________________________________');




  useEffect(() => {
    if (filterMenuData?.stock) {
      let newData = dataState.all_items_is_enable.filter((item) => {
        console.log(Number(item.item_type) + '===' + Number(filterMenuData.stock));

        return Number(item.item_type) === Number(filterMenuData.stock);
      });
      setAllItems(newData)
    }
    else {
      setAllItems(dataState.all_items_is_enable)
    }
  }, [filterMenuData, dataState.all_items_is_enable, dataState.items_groups, dataState.item_group_type])









  return (
    <>

      <FilterMenuContainer>



        <Autocomplete
          value={items_groups_and_item_group_type.find((item) => ConditionalCompare(item.id, '===', filterMenuData.stock)) || null}
          options={items_groups_and_item_group_type}
          getOptionLabel={(innerOptions) => innerOptions?.name}

          onChange={(_, newValue) => { handelToUpdateData('stock', (newValue?.id || null)) }}

          style={{ width: '100%', maxWidth: '25%' }}
          renderInput={(params) => (
            <MDInput {...params} label="Stock" variant="standard" />
          )}
        />






        <Autocomplete
          key={allItems.length}
          value={allItems.find((item) => ConditionalCompare(item.id, '===', filterMenuData.item)) || null}
          options={allItems}
          getOptionLabel={(innerOptions) => innerOptions?.name}

          onChange={(_, newValue) => { handelToUpdateData('item', (newValue.id || null)) }}

          style={{ width: '100%', maxWidth: '25%' }}
          renderInput={(params) => (
            <MDInput {...params} label="Item" variant="standard" />
          )}
        />




        <MDBox sx={{ width: '100%', maxWidth: '25%', position: 'relative' }}>
          <MDInput
            variant="standard"
            label="Select Date"
            value={startDate + ' - ' + endDate}
            placeholder="Select Range"
            onClick={() => setDateOpen(!dateopen)}
            onFocus={() => setDateOpen(!dateopen)}
            // onBlur={() => setDateOpen(!dateopen)}
            style={{ width: '100%' }}
            type='text'
          />
          {dateopen ? <>
            <MDBox
              onClick={() => setDateOpen(false)}
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                top: '3.5rem',
                padding: '2',
                backgroundColor: '#fff',
                border: '1px solid #d2d6da',
                borderRadius: '8px',
                boxShadow: '2px 2px 5px -3px rgb(0 0 0 / 30%)',
                overflow: 'hidden',
                zIndex: 1201,
              }}>
                <DateRange
                  rangeColors={['#AF891F']}
                  // maxDate={addDays(new Date(), 0)}

                  onChange={item => {
                    console.log(item.selection)
                    setDateState([item.selection])

                    var options = { year: 'numeric', month: 'short', day: 'numeric' };

                    setStartDate(new Date(item.selection.startDate).toLocaleDateString("en-US", options))
                    setEndDate(new Date(item.selection.endDate).toLocaleDateString("en-US", options))
                    if (item.selection.startDate !== item.selection.endDate) {
                      setDateOpen(false)
                    }

                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  ranges={datestate}
                  direction="vertical"
                />
              </MDBox>
            </> : ''}
          </MDBox>


          <MDBox sx={{ width: '100%', maxWidth: '25%', position: 'relative'}}>
              <MonthsSelectComponent setStartDate={setStartDate} setEndDate={setEndDate}/>
        </MDBox>


           
{/* //                 bottom: 0,
//                 right: 0,
//                 zIndex: 1200,
//               }}
//             ></div>
//             <MDBox sx={{
//               position: 'absolute',
//               zIndex: 4,
//               left: 0,
//               top: '3.5rem',
//               padding: '2',
//               backgroundColor: '#fff',
//               border: '1px solid #d2d6da',
//               borderRadius: '8px',
//               boxShadow: '2px 2px 5px -3px rgb(0 0 0 / 30%)',
//               overflow: 'hidden',
//               zIndex: 1201,
//             }}>
//               <DateRange
//                 rangeColors={['#AF891F']}
//                 // maxDate={addDays(new Date(), 0)}

//                 onChange={item => {
//                   console.log(item.selection)
//                   setDateState([item.selection])

//                   var options = { year: 'numeric', month: 'short', day: 'numeric' };

//                   setStartDate(new Date(item.selection.startDate).toLocaleDateString("en-US", options))
//                   setEndDate(new Date(item.selection.endDate).toLocaleDateString("en-US", options))
//                   if (item.selection.startDate !== item.selection.endDate) {
//                     setDateOpen(false)
//                   }

//                 }}
//                 showSelectionPreview={true}
//                 moveRangeOnFirstSelection={false}
//                 months={1}
//                 ranges={datestate}
//                 direction="vertical"
//               />
//             </MDBox>
//           </> : ''}
//         </MDBox> */}





 

        <FM_ButtonGroup>
          <FM_Button2 />
          <FM_Button1
            actionButton={resetParams}
          />
        </FM_ButtonGroup>


      </FilterMenuContainer>

    </>
  )
}

export default FilterMenu