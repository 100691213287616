import React, { useContext } from 'react'

import { Autocomplete } from '@mui/material'
// import CustomDatepicker from 'components/CustomDatepicker'
import MDBox from 'components/MDBox'
// import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import { useState, useEffect } from 'react'
// import { useAuth } from '../../../../utils/AuthProvider'
import { DataContext } from "context/dataContext";
import { addDays } from 'date-fns';
import { DateRange } from 'react-date-range';

// import 'react-date-range/dist/styles.css'; // main css file
// import 'react-date-range/dist/theme/default.css'; // theme css file
// import SelectComponent from 'components/SelectComponent'
import { ConditionalCompare } from 'layouts/transactions/components/functions'
import FilterMenuContainer from 'components/FilterMenu/FilterMenuContainer'
import FM_ButtonGroup from 'components/FilterMenu/FM_ButtonGroup'
import FM_Button2 from 'components/FilterMenu/FM_Button2'
import FM_Button1 from 'components/FilterMenu/FM_Button1'
import moment from 'moment'
import ErrorText from 'components/ErrorText'
import MDButton from 'components/MDButton'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';







const FilterMenu = () => {

  const { dataState, getTagsByVoucherApi, myDispatch } = useContext(DataContext)


  let voucher = dataState.all_voucher.find((e) => e.id === Number(dataState.current_voucher_of_all_tags[0]))?.prefix + '-' + dataState.current_voucher_of_all_tags[1]





  const [filterMenuData, setFilterMenuData] = useState({
    item_group_id: null,
    // item_group_id: 117,
    item: null,
  })



  let items_groups_and_item_group_type = [...dataState.items_groups, ...dataState.item_group_type]
  const [allItems, setAllItems] = useState([])




  let today = moment().format("MMM DD, YYYY");
  let oneMonthAgo = moment().subtract(1, 'months').format("MMM DD, YYYY");

  const [startDate, setStartDate] = useState(oneMonthAgo);
  const [endDate, setEndDate] = useState(today);
  const [dateopen, setDateOpen] = useState(false);

  const [datestate, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection'
    }
  ]);
  const [origselection, setOrigSelection] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection'
    }
  ]);



  const handelToUpdateData = (key, value) => {
    setFilterMenuData(prevData => ({
      ...prevData,
      [key]: value,
    }));
    console.log(filterMenuData)
  }

  function resetParams() {
    setFilterMenuData(prevData => ({
      ...prevData,
      item_group_id: null,
      item: null,
    }));
    setStartDate(oneMonthAgo);
    setEndDate(today);
    setDateState(origselection)
  }














  useEffect(() => {
    if (filterMenuData?.item_group_id) {
      let newData = dataState.all_items_is_enable.filter((item) => {
        console.log(Number(item.item_type) + '===' + Number(filterMenuData.item_group_id));

        return Number(item.item_type) === Number(filterMenuData.item_group_id);
      });
      setAllItems(newData)
    }
    else {
      setAllItems(dataState.all_items_is_enable)
    }
  }, [filterMenuData, dataState.all_items_is_enable, dataState.items_groups, dataState.item_group_type])



















  const [filterMenuValidation, setFilterMenuValidation] = useState({
    item_group_id: null,
  })









  const clearTagData = () => {
    myDispatch('SET_ALL_TAGS', [])
    myDispatch('CURRENT_VOUCHER_OF_ALL_TAGS', [])
  }







  const handleToSearch = () => {

    getTagsByVoucherApi(startDate || null, endDate || null)

    // filterMenuData?.item_group_id === null ? (
    //   setFilterMenuValidation({ item_group_id: true })
    // ) : (
    //   setFilterMenuValidation({ item_group_id: false })
    // )


    // if (filterMenuData?.item_group_id === null) {
    //   return
    // }
    // else {
    //   getTagsApi(startDate || null, endDate || null, filterMenuData.item_group_id || null, filterMenuData.item || null)
    // }

  }














  return (
    <>

      {/* <FilterMenuContainer flexDirection="column"> */}
      <FilterMenuContainer>



        {/* <MDBox sx={{ width: '100%', maxWidth: '25%', position: 'relative' }}>
          <Autocomplete
            value={items_groups_and_item_group_type.find((item) => ConditionalCompare(item.id, '===', filterMenuData.item_group_id)) || null}
            options={items_groups_and_item_group_type}
            getOptionLabel={(innerOptions) => innerOptions?.name}

            onChange={(_, newValue) => { handelToUpdateData('item_group_id', (newValue?.id || null)) }}

            sx={{
              '& .MuiFormControl-root': { background: 'initial' },
              ...(filterMenuValidation.item_group_id && filterMenuData?.item_group_id === null && {
                '& .MuiInput-root:before': { borderColor: '#F44335' },
                '& .MuiInputLabel-root': { color: '#F44335' },
              }),
            }}

            renderInput={(params) => (
              <MDInput
                {...params}
                label="Stock"
                variant="standard"
                error={filterMenuValidation.item_group_id && filterMenuData?.item_group_id === null}
              />
            )}
          />
          {filterMenuValidation.item_group_id && filterMenuData?.item_group_id === null && (
            <ErrorText errorName="Stock is required" />
          )}
        </MDBox> */}






        {/* <MDBox sx={{ width: '100%', maxWidth: '25%', position: 'relative' }}>
          <Autocomplete
            key={allItems.length}
            value={allItems.find((item) => ConditionalCompare(item.id, '===', filterMenuData.item)) || null}
            options={allItems}
            getOptionLabel={(innerOptions) => innerOptions?.name}

            onChange={(_, newValue) => { handelToUpdateData('item', (newValue?.id || null)) }}

            renderInput={(params) => (
              <MDInput {...params} label="Item" variant="standard" />
            )}
          />
        </MDBox> */}




        <MDBox sx={{ width: '100%', maxWidth: '25%', position: 'relative' }}>
          <MDInput
            variant="standard"
            label="Select Date"
            value={startDate + ' - ' + endDate}
            placeholder="Select Range"
            onClick={() => setDateOpen(!dateopen)}
            onFocus={() => setDateOpen(!dateopen)}
            // onBlur={() => setDateOpen(!dateopen)}
            style={{ width: '100%' }}
            type='text'
          />
          {dateopen ? <>
            <div
              onClick={() => setDateOpen(false)}
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1200,
              }}
            ></div>
            <MDBox sx={{
              position: 'absolute',
              zIndex: 4,
              left: 0,
              top: '3.5rem',
              padding: '2',
              backgroundColor: '#fff',
              border: '1px solid #d2d6da',
              borderRadius: '8px',
              boxShadow: '2px 2px 5px -3px rgb(0 0 0 / 30%)',
              overflow: 'hidden',
              zIndex: 1201,
            }}>
              <DateRange
                rangeColors={['#AF891F']}
                // maxDate={addDays(new Date(), 0)}

                onChange={item => {
                  console.log(item.selection)
                  setDateState([item.selection])

                  var options = { year: 'numeric', month: 'short', day: 'numeric' };

                  setStartDate(new Date(item.selection.startDate).toLocaleDateString("en-US", options))
                  setEndDate(new Date(item.selection.endDate).toLocaleDateString("en-US", options))
                  if (item.selection.startDate !== item.selection.endDate) {
                    setDateOpen(false)
                  }

                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={datestate}
                direction="vertical"
              />
            </MDBox>
          </> : ''}
        </MDBox>




        <FM_ButtonGroup>


          {dataState.current_voucher_of_all_tags.length > 0 && (
            <MDButton
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                clearTagData()
              }}
              style={{
                // minWidth: 100,
                flexShrink: 0,
                height: 30,
                marginLeft: 'auto',
                display: 'flex',
                alignItems: 'center',
                padding: 4,
                paddingLeft: '12px'
              }}
            >
              {voucher}
              <HighlightOffIcon
                style={{
                  width: 22,
                  height: 22,
                  marginLeft: 8,
                  cursor: 'pointer',
                }}
              />
            </MDButton>
          )}

          <FM_Button2
            actionButton={handleToSearch}
          />
          <FM_Button1
            actionButton={resetParams}
          />
        </FM_ButtonGroup>


      </FilterMenuContainer>

    </>
  )
}

export default FilterMenu