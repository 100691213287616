import moment from "moment";
// import IdCell from "./IdCell";
import DefaultCell from "components/DataTable/DefaultCell";
// import { SetFormattedIndianNumber } from "components/globalFunctions";


// import NoUserImage from 'assets/images/default-image.png'
// import { Padding } from "@mui/icons-material";


const dataTableData = {
  columns: [
    {
      Header: "Date",
      accessor: "selected_on",
      customFunction: 'get_tags',
      Cell: ({ value }) => {
        return <DefaultCell value={moment(value).format('DD-MM-YYYY')} />
      }
    },
    {
      Header: "Voucher ID",
      accessor: "linked_counter",
      customFunction: 'get_tags',
      Cell: ({ value, row }) => {
        return <DefaultCell value={(row?.original?.prefix).toUpperCase() + '-' + value} />
      }
    },
    {
      Header: "Total number of items",
      accessor: "count",
      customFunction: 'get_tags',
      Cell: ({ value }) => {
        return <DefaultCell value={value} />
      }
    },
    {
      Header: "Tag Type",
      accessor: "type",
      customFunction: 'get_tags',
      Cell: ({ value }) => {
        return <DefaultCell value={value} />
      }
    },
  ],

  rows: [],
};

export default dataTableData;
