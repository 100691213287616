import moment from "moment";
import IdCell from "./IdCell";
import DefaultCell from "components/DataTable/DefaultCell";
import { SetFormattedIndianNumber } from "components/globalFunctions";
 

const dataTableData = {
  columns: [
    {
      Header: "Date",
      accessor: "selected_date",
      link: '/transactions/sale/',
      linkByID: true,
      Cell: ({ value }) => {
        return <DefaultCell value={moment(value).format('DD-MM-YYYY')} />
      }
    },
    {
      Header: "Bill",
      accessor: "voucher_id",
      link: '/transactions/sale/',
      linkByID: true,
      Cell: ({ value, row }) => {
        return <DefaultCell value={''} />
      }
    },
    {
      Header: "Type",
      accessor: "type",
      link: '/transactions/sale/',
      linkByID: true,
      Cell: ({ value }) => {
        return <DefaultCell value={value} />
      }
    },
    {
      Header: "Narration",
      // accessor: "item",
      link: '/transactions/sale/',
      linkByID: true,
      // Cell: ({ value }) => <DefaultCell value={value} />
      Cell: ({ row }) => {
        return <DefaultCell value={
          row?.original?.item ? row?.original?.item : row?.original?.method
        } />
      }
    },
    {
      Header: "Stamp",
      accessor: "stamp",
      link: '/transactions/sale/',
      linkByID: true,
      Cell: ({ value, row }) => {
        return <DefaultCell value={row?.original?.method ? '' : value} />
      }
    },
    {
      Header: "GrWt",
      accessor: "grwt",
      align: "right",
      link: '/transactions/sale/',
      linkByID: true,
      Footer: (info) => {
        let value = info.rows.reduce((sum, row) => sum + parseFloat(row.values.grwt || 0), 0)
        return <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
      },
      Cell: ({ value }) => {
        return <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
      }
    },
    {
      Header: "Less",
      accessor: "less",
      align: "right",
      link: '/transactions/sale/',
      linkByID: true,
      Footer: (info) => {
        let value = info.rows.reduce((sum, row) => sum + parseFloat(row.values.less || 0), 0)
        return <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
      },
      Cell: ({ value, row }) => {
        return <DefaultCell value={row?.original?.method ? '' : SetFormattedIndianNumber(value, 3)} />
      }
    },
    {
      Header: "NetWt",
      accessor: "netwt",
      align: "right",
      link: '/transactions/sale/',
      linkByID: true,
      Footer: (info) => {
        let value = info.rows.reduce((sum, row) => sum + parseFloat(row.values.netwt || 0), 0)
        return <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
      },
      Cell: ({ value, row }) => {
        return <DefaultCell value={row?.original?.method ? '' : SetFormattedIndianNumber(value, 3)} />
      }
    },
    {
      Header: "Tunch",
      accessor: "tunch",
      align: "right",
      link: '/transactions/sale/',
      linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value)} />
    },
    {
      Header: "Wstg",
      accessor: "wstg",
      align: "right",
      link: '/transactions/sale/',
      linkByID: true,
      Footer: (info) => {
        let value = info.rows.reduce((sum, row) => sum + parseFloat(row.values.wstg || 0), 0)
        return <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
      },
      Cell: ({ value, row }) => {
        return <DefaultCell value={row?.original?.method ? '' : SetFormattedIndianNumber(value, 3)} />
      }
    },
    {
      Header: "Rate",
      accessor: "rate",
      align: "right",
      link: '/transactions/sale/',
      linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value)} />
    },
    {
      Header: "Lbr",
      accessor: "lbr",
      align: "center",
      link: '/transactions/sale/',
      linkByID: true,
      Cell: ({ value, row }) => {
        return <DefaultCell value={row?.original?.method ? '' : value} />
      }
    },
    {
      Header: "On",
      accessor: "on",
      align: "center",
      link: '/transactions/sale/',
      linkByID: true,
      Cell: ({ value, row }) => {
        return <DefaultCell value={row?.original?.method ? '' : value} />
      }
    },
    {
      Header: "Fine",
      accessor: "fine",
      align: "right",
      link: '/transactions/sale/',
      linkByID: true,
      Footer: (info) => {
        let value = info.rows.reduce((sum, row) => sum + parseFloat(row.values.fine || 0), 0)
        return <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
      },
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
    },
    {
      Header: "Amount",
      accessor: "total",
      align: "right",
      link: '/transactions/sale/',
      linkByID: true,
      Footer: (info) => {
        let value = info.rows.reduce((sum, row) => sum + parseFloat(row.values.total || 0), 0)
        return <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
      },
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value)} />,
    },
  ],

  rows: [],
};

export default dataTableData;
