import { Autocomplete, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import moment from "moment";
import MDInput from "./MDInput";
const monthsList = [
  {
    id: 1,
    name: "1 Month",
  },
  {
    id: 3,
    name: "Quater Year",
  },
  {
    id: 6,
    name: "Half Year",
  },
  {
    id: 12,
    name: "1 Year",
  },
];
const MonthsSelectComponent = ({ setStartDate, setEndDate }) => {
  let today = moment().format("MMM DD, YYYY");
  // let oneMonthAgo = moment().subtract(1, "months").format("MMM DD, YYYY");

  // const [startDate, setStartDate] = useState(oneMonthAgo);
  // const [endDate, setEndDate] = useState(today);
  return (
    // <FormControl sx={{ width: "100%" }}>
    //   <InputLabel id="demo-simple-select-helper-label">Date</InputLabel>
    //   <Select
    //     labelId="demo-simple-select-helper-label"
    //     id="demo-simple-select-helper"
    //     variant="standard"
    //     defaultValue={1}
    //     label="Date"
    //     onChange={(_, newValue) => {
    //       console.log("newValue", newValue);
    //       setStartDate(moment().subtract(newValue.id, "months").format("MMM DD, YYYY"));
    //       setEndDate(today);
    //     }}
    //   >
    //     {monthsList.map((data) => (
    //       <MenuItem value={data.id}>{data.name}</MenuItem>
    //     ))}
    //   </Select>
    // </FormControl>
    <Autocomplete
      popupIcon={""}
      defaultValue={monthsList.find((month) => month.id === 1)}
      options={monthsList}
      openOnFocus
      getOptionLabel={(innerOptions) => innerOptions.name || ""}
      renderInput={(params) => (
        <MDInput
          {...params}
          label="Date"
          variant="standard"
          // error={userInfoValidation.user_name && userInfo?.user_id === null}
        />
      )}
      onChange={(_, newValue) => {
        console.log("newValue", newValue);
        setStartDate(moment().subtract(newValue.id, "months").format("MMM DD, YYYY"));
        setEndDate(today);
      }}
      sx={{
        "& .MuiFormControl-root": { background: "initial" },
      }}
    />
  );
};

export default MonthsSelectComponent;
