import moment from "moment";
import IdCell from "./IdCell";
import DefaultCell from "components/DataTable/DefaultCell";
import { SetFormattedIndianNumber } from "components/globalFunctions";


import NoUserImage from 'assets/images/default-image.png'
import { Padding } from "@mui/icons-material";


const dataTableData = {
  columns: [
    {
      Header: "Date",
      accessor: "selected_on",
      customFunction: 'edit_tags',
      Cell: ({ value }) => {
        return <DefaultCell value={moment(value).format('DD-MM-YYYY')} />
      }
    },
    {
      Header: "IMG",
      accessor: "img",
      customFunction: 'edit_tags',
      padding: false,
      align: "center",
      width: 40,
      Cell: ({ value }) => {
        return <>
          <img src={value || NoUserImage} style={{ width: "40px", height: "40px", display: 'block' }} />
        </>
      }
    },
    {
      Header: "Item Code",
      accessor: "item_code",
      customFunction: 'edit_tags',
      Cell: ({ value }) => {
        return <DefaultCell value={value} />
      }
    },
    {
      Header: "Item",
      accessor: "item",
      customFunction: 'edit_tags',
      // Cell: ({ value }) => <DefaultCell value={value} />
      Cell: ({ value }) => {
        return <DefaultCell value={value} />
      }
    },
    {
      Header: "Stamp",
      accessor: "stamp",
      customFunction: 'edit_tags',
      Cell: ({ value }) => {
        return <DefaultCell value={value} />
      }
    },
    {
      Header: "GrWt",
      accessor: "grwt",
      align: "right",
      customFunction: 'edit_tags',
      // Footer: (info) => {
      //   let value = info.rows.reduce((sum, row) => sum + parseFloat(row.values.grwt || 0), 0)
      //   return <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
      // },
      Cell: ({ value }) => {
        return <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
      }
    },
    {
      Header: "Less",
      accessor: "less",
      align: "right",
      customFunction: 'edit_tags',
      // Footer: (info) => {
      //   let value = info.rows.reduce((sum, row) => sum + parseFloat(row.values.less || 0), 0)
      //   return <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
      // },
      Cell: ({ value, row }) => {
        return <DefaultCell value={row?.original?.method ? '' : SetFormattedIndianNumber(value, 3)} />
      }
    },
    {
      Header: "NetWt",
      accessor: "netwt",
      align: "right",
      customFunction: 'edit_tags',
      // Footer: (info) => {
      //   let value = info.rows.reduce((sum, row) => sum + parseFloat(row.values.netwt || 0), 0)
      //   return <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
      // },
      Cell: ({ value, row }) => {
        return <DefaultCell value={row?.original?.method ? '' : SetFormattedIndianNumber(value, 3)} />
      }
    },
    {
      Header: "Size",
      accessor: "size",
      align: "right",
      customFunction: 'edit_tags',
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Qty",
      accessor: "qty",
      align: "right",
      customFunction: 'edit_tags',
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value)} />
    },
    {
      Header: "Uid",
      accessor: "uid",
      align: "right",
      customFunction: 'edit_tags',
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Tunch",
      accessor: "tunch",
      align: "right",
      customFunction: 'edit_tags',
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value)} />
    },
    {
      Header: "D. Carats",
      accessor: "d_carats",
      align: "right",
      customFunction: 'edit_tags',
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value)} />
    },
    {
      Header: "Color / Clarity",
      accessor: "color_clarity",
      align: "right",
      customFunction: 'edit_tags',
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Remarks",
      accessor: "remarks",
      align: "right",
      customFunction: 'edit_tags',
      Cell: ({ value }) => <DefaultCell value={value} />
    },
  ],

  rows: [],
};

export default dataTableData;
