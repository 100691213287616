

import { useContext, useEffect, useState } from "react";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "components/DataTable";
// import 'react-date-range/dist/styles.css'; // main css file
// import 'react-date-range/dist/theme/default.css'; // theme css file
import dataTableData from "layouts/reports/stocks/components/dataTableData";
// import FormField from "layouts/account/components/FormField";
import { DataContext } from "context/dataContext";
// import SelectComponent from "components/SelectComponent";
import FilterMenu from "./components/FilterMenu";
import ComplexStatisticsCard from "components/Cards/StatisticsCards/ComplexStatisticsCard";
import { SetFormattedIndianNumber } from "components/globalFunctions";
import NoDataFound from "components/NoDataFound";
import { transactionsTypeOption2 } from "components/const";
import { transactionsTypeOption } from "components/const";
import { flowType_In_Out } from "components/const";


function Stocks() {
  const { dataState } = useContext(DataContext)

  const [data, setData] = useState({ ...dataTableData, rows: [] });




  useEffect(() => {

    // let combinedValue = [...(dataState?.all_ledgers?.sales || []), ...(dataState?.all_ledgers?.payments || [])]
    let updatedValue = dataState?.all_stock.map((itemData) => {

      let typeDetails = flowType_In_Out.find((e) => e.status === itemData.type)

      let itemDetails = dataState.all_items.find((e) => e.id === Number(itemData.item));
      let stampsDetails = dataState.all_stamps.find((e) => e.id === Number(itemData.stamp));
      // let prefixDetails = dataState.all_voucher.find((e) => e.id === Number(itemData.prefix));
      return {
        ...itemData,
        // id: itemData.linked_id || '',
        type: typeDetails?.name || '',
        item: itemDetails?.name || '',
        stamp: stampsDetails?.name || '',
        // prefix: prefixDetails?.prefix || '',
      }
    })

    console.log('updatedValue', updatedValue);

    setData(prevData => ({
      ...prevData,
      rows: updatedValue
    }));

  }, [dataState.all_stock, dataState.all_items, dataState.all_stamps]);









  return (
    <DashboardLayout>





      <DashboardNavbar />





      <MDBox my={2}>
        <FilterMenu />
      </MDBox>






      <MDBox mb={2}>
        <Grid container mb={2} spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ height: '100%' }}>
              <ComplexStatisticsCard
                // icon="weekend"
                color="primary"
                title="Total Fine"
                count={SetFormattedIndianNumber(6425.362, 3)}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "more than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ height: '100%' }}>
              <ComplexStatisticsCard
                // icon="leaderboard"
                color="primary"
                title="Total Gross WT"
                count={SetFormattedIndianNumber(5375.482, 3)}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "more than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ height: '100%' }}>
              <ComplexStatisticsCard
                // icon="store"
                color="primary"
                title="Total Net WT"
                count={SetFormattedIndianNumber(6425.362, 3)}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "more than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ height: '100%' }}>
              <ComplexStatisticsCard
                // icon="person_add"
                color="primary"
                title="Total Wstg"
                count={SetFormattedIndianNumber(5375.482, 3)}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "more than last month",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>











      <MDBox my={3}>
        <Card>
          {data?.rows?.length > 0 ? (
            <DataTable table={data} canSearch />
          ) : (
            <MDBox p={2}>
              <NoDataFound
                title="No Data Found"
              />
            </MDBox>
          )}
        </Card>
      </MDBox>





    </DashboardLayout>
  );
}

export default Stocks;
