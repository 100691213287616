import React, { useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MDBox from 'components/MDBox';
import { Switch } from '@mui/material';
import MDTypography from 'components/MDTypography';

const TableColumnManager = ({ itemTableHeaderData, setItemTableHeaderData }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };



  const changeVisibilityOfColumn = (key) => {
    setItemTableHeaderData((prevData) => (
      prevData.map((item) => {
        if (item.key === key) {
          return { ...item, visibilty: !item.visibilty };
        }
        return item;
      })
    ));
  };






  return (
    <>
      <SettingsIcon
        style={{
          width: '28px',
          height: '28px',
          // background: '#b7994b',
          padding: '5px',
          borderRadius: '50px',
          cursor: 'pointer'
        }}
        onClick={toggleDrawer(true)}
      />

      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiPaper-root': {
            width: 250,
            overflowX: 'hidden'
          },
          '.MuiBackdrop-root': {
            transition: '0s !important'
          }
        }}
      >
        <Box
          sx={{ padding: '16px' }}
          role="presentation"
        // onClick={toggleDrawer(false)}
        // onKeyDown={toggleDrawer(false)}
        >
          <MDTypography
            display="flex"
            alignItems="center"
            gap={1}
            variant="h3"
            fontWeight="medium"
            justifyContent="center"
            mb={2}
            sx={{
              fontSize: '20px'
            }}
          >
            Column Visibilty
          </MDTypography>


          <MDBox>
            {itemTableHeaderData.map((item, index) => (
              item?.visibilty !== undefined && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDBox display="flex" alignItems="center">
                    <MDBox lineHeight={0}>
                      <MDTypography display="flex" alignItems="center" gap={1} variant="h6" fontWeight="medium" sx={{ fontSize: '14px' }}>
                        {item.name}
                      </MDTypography>
                    </MDBox>
                  </MDBox>

                  <MDBox
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    width={{ xs: "100%", sm: "auto" }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <MDBox>
                      <Switch
                        checked={item.visibilty}
                        onChange={(e) => { changeVisibilityOfColumn(item.key) }}
                      />
                    </MDBox>
                  </MDBox>
                </MDBox>
              )
            ))}
          </MDBox>


        </Box>
      </Drawer>
    </>
  );
};

export default TableColumnManager;
