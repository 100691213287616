

import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import Divider from "@mui/material/Divider";
// import Grid from "@mui/material/Grid";
// import Autocomplete from "@mui/material/Autocomplete";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// import MDInput from "components/MDInput";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
import DataTable from "components/DataTable";
import NoDataFound from "components/NoDataFound";

// import { addDays } from 'date-fns';
// import { DateRange } from 'react-date-range';
import { DataContext } from 'context/dataContext'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// Data
import dataTableData from "./components/dataTableData";
// import { useAuth } from "utils/AuthProvider";
import FilterMenu from "./components/FilterMenu";
import { transactionsTypeOption } from "components/const";
import { transactionsTypeOption2 } from "components/const";

// const axios = require('axios').default

function SaleReport() {
  const { dataState } = useContext(DataContext)
  // const { cookies } = useAuth();
  // axios.defaults.headers.common = { 'Authorization': `Bearer ${cookies.token}` }
  // let items_groups_and_item_group_type = [...dataState.items_groups, ...dataState.item_group_type]

  const [data, setData] = useState({ ...dataTableData, rows: [] });



  // let account_groups_and_account_group_type = [...dataState.account_groups, ...dataState.account_group_type]

  useEffect(() => {
    setData(prevData => ({
      ...prevData,
      rows: dataState.all_payments.filter((item) => item.type === 'inflow').map((itemData) => {
        let typeDetails = transactionsTypeOption2.find((e) => e.status === itemData.type);
        // let itemDetails = dataState.all_items.find((e) => e.id === Number(itemData.item));
        let userDetails = dataState.all_accounts.find((e) => e.id === Number(itemData.user_id));
        // let stampsDetails = dataState.all_stamps.find((e) => e.id === Number(itemData.stamp));
        let prefixDetails = dataState.all_voucher.find((e) => e.id === Number(itemData.voucher_id));

        return {
          ...itemData,
          type: typeDetails?.name || '',
          // item: itemDetails?.name || '',
          // stamp: stampsDetails?.name || '',
          user_id: userDetails?.name || '',          
          prefix: prefixDetails?.prefix || '',

        }
      })
    }));
  }, [dataState.all_payments, dataState.all_accounts]);



  // useEffect(() => {
  //   axios
  //     .post('http://localhost:5000/api/ledgers/type', {
  //       type: 'outflow'
  //     })
  //     .then(function (response) {
  //       console.log(response.data)
  //       setData(prevData => ({ ...prevData, rows: response.data }));
  //       //window.location.href = '/dashboards/analytics'
  //     })
  //     .catch(function (error) {
  //       console.log('Something went wrong')
  //       console.log(error)
  //     })
  //   //setData(prevData => ({ ...prevData, rows: rowDataJson }));
  // }, [dataTableData]);







  // const [filterMenuData, setFilterMenuData] = useState({
  //   selected_date: "",
  // });








  return (
    <DashboardLayout>
      <DashboardNavbar />



      <MDBox my={2}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <MDTypography variant="h5" fontWeight="medium">Sales Transactions</MDTypography>
          <Link to="/transactions/sale">
            <MDButton variant="gradient" color="primary">
              add sale
            </MDButton>
          </Link>
        </MDBox>
      </MDBox>










      <MDBox my={2}>
        <FilterMenu
          // filterMenuData={filterMenuData}
          // setFilterMenuData={setFilterMenuData}
        />
      </MDBox>


















      <MDBox my={2}>
        {/* <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <Link to="/transactions/sale">
            <MDButton variant="gradient" color="primary">
              add sale
            </MDButton>
          </Link>

          <MDBox display="flex">
            <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox> */}
        <Card>
          {
            data.rows.length > 0 ? (
              <DataTable table={data} canSearch />
            ) : (
              <MDBox p={2}>
                <NoDataFound
                  title="No Data Found"
                />
              </MDBox>
            )
          }
        </Card>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default SaleReport;