import { useContext, useEffect, useState } from "react";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css';

import { v4 as uuidv4 } from 'uuid';
import FilterMenu from "./components/FilterMenu";
import TagItemRow from "../components/TagItemRow";
import Label from "layouts/invoice/components/Label";
import { DataContext } from '../../../context/dataContext'








function Tags() {
  const { dataState, createTagApi, getTagsApi, myDispatch } = useContext(DataContext)
  const allDataSourceTableMaxLength = 50 // Temporary Value
  // const [counterForCopyRow, setCounterForCopyRow] = useState(null);
  // const itemCodeType = 'BL-';





  const [itemTableHeaderData, setItemTableHeaderData] = useState([
    { name: "S/N", align: "left", key: "serial_number", visibilty: true },
    { name: "IMG", align: "left", key: "img", visibilty: true },
    { name: "Code", align: "left", key: "item_code", visibilty: true },
    { name: "Item", align: "left", key: "item", visibilty: true },
    { name: "Stamp", align: "left", key: "stamp", visibilty: true },
    { name: "Gr.WT", align: "right", key: "grwt", visibilty: true },
    { name: "Less", align: "right", key: "less", visibilty: true },
    { name: "Net.WT", align: "right", key: "netwt", visibilty: true },
    { name: "Size", align: "right", key: "size", visibilty: true },
    { name: "Qty", align: "right", key: "qty", visibilty: true },
    { name: "UID", align: "right", key: "uid", visibilty: true },
    { name: "Tunch", align: "right", key: "tunch", visibilty: true },
    { name: "D. Carats", align: "right", key: "d_carats", visibilty: true },
    { name: "Color / Clarity", align: "right", key: "color_clarity", visibilty: true },
    { name: "Remarks", align: "right", key: "remarks", visibilty: true },
  ])





  const [tagItems, setTagItems] = useState([{
    id: uuidv4(),
    img: null,
    stamp: null,
    item_code: '',
    prefix: "",
    prefix_counter: 0,
    item: null,
    grwt: 0.000,
    less: 0.000,
    less_details: [],
    netwt: 0.000,
    size: "",
    qty: 0.00,
    uid: "",
    tunch: 100.00,
    d_carats: 0.00,
    color_clarity: "",
    remarks: "",
  }]);








  const [stockInfo, setStockInfo] = useState({
    selected_on: new Date(),
    type: null,
    user_id: null,
    item_group_id: null,
  });



  const [filterMenuValidation, setFilterMenuValidation] = useState({
    type: false,
    user_id: false,
    item_group_id: false,
  });


  const [errorForTags, setErrorForTags] = useState(false)


  // Placeholder to Add New Row
  const newRow = {
    id: uuidv4(),
    img: null,
    stamp: null,
    item: null,
    item_code: '',
    prefix: "",
    prefix_counter: 0,
    grwt: 0.000,
    less: 0.000,
    less_details: [],
    netwt: 0.000,
    size: "",
    qty: 0.00,
    uid: "",
    tunch: 100.00,
    d_carats: 0.00,
    color_clarity: "",
    remarks: "",
  };


  // Adding a New Empty Row
  const handleToAppendOneMoreRow = () => {
    if (tagItems.length < allDataSourceTableMaxLength) {
      setTagItems(prevData => [
        ...prevData,
        {
          ...newRow,
          // item_code: incrementItemCodeForAppend()
        }
      ]);
    } else {
      // setCounterForCopyRow(0)
      console.log('Cannot add more rows. The maximum allowable row limit of 50 has been reached.');
    }
  };












































  const [disablebuttons, setDisablebuttons] = useState({
    save: false,
    save_print: false,
    add_row: false
  })



  const handelToDisableButton = (value, key) => {
    setDisablebuttons(prevData => ({
      ...prevData,
      [key]: value,
    }));
  }







  const collectAndUpdateStateInfo = () => {


    const updatedStockInfo = {
      ...stockInfo,
    };
    setStockInfo(updatedStockInfo);

    const updatedItems = tagItems.map((item) => ({
      ...item,
      netwt: item.grwt - item.less,
      item_code: item.prefix && item.prefix_counter ? (item.prefix + '-' + item.prefix_counter) : ''
    }));
    setTagItems(updatedItems);

    // console.log('Old Data ', { items: saleItems, panels: panels, total: grandTotal })
    return { stockInfo: updatedStockInfo, tagItems: updatedItems }
  }














  const saveTagData = () => {
    handelToDisableButton(true, 'save')
    console.log('saveTagData =================>');
    console.log('New Data ', collectAndUpdateStateInfo());
    const newData = collectAndUpdateStateInfo();
    setTagData(newData);
    saveTag(newData, false)

    setTimeout(() => {
      handelToDisableButton(false, 'save')
    }, 500);
    handleItemCodeOn_Save()
  }



  // Print State To Manage Invoice Print
  const [roughPrintState, setInvoicePrintState] = useState(false);





  const [tagData, setTagData] = useState(null);

  const savePrintTagData = () => {
    handelToDisableButton(true, 'save_print')
    console.log('savePrintTagData =================>');
    console.log('New Data ', collectAndUpdateStateInfo());

    const newData = collectAndUpdateStateInfo();
    setTagData(newData);

    setInvoicePrintState(true)

    saveTag(newData, true)

    setTimeout(() => {
      handelToDisableButton(false, 'save_print')
    }, 1000);
    handleItemCodeOn_Save()
  }


  // const cancelTagData = () => {
  //   console.log('cancelTagDatav =================>');

  // }































  function saveTag(newData, print) {

    let type = false;
    let user_id = false;
    let item_group_id = false;

    const allTagsValid = newData.tagItems.every((val) => {
      return val.item && val.stamp && val.grwt && val.qty
    });
    setErrorForTags(!allTagsValid);

    switch (stockInfo?.type) {
      case null:
        setFilterMenuValidation({ type: true, user_id: false, item_group_id: false });
        type = true;
        user_id = false;
        item_group_id = false;
        break;
      case "Vendor":
        if (!stockInfo?.user_id) {
          setFilterMenuValidation({ type: false, user_id: true, item_group_id: false });
          type = false;
          user_id = true;
          item_group_id = false;
        }
        break;
      case "Loose Stock":
        if (!stockInfo?.item_group_id) {
          setFilterMenuValidation({ type: false, user_id: false, item_group_id: true });
          type = false;
          user_id = false;
          item_group_id = true;
        }
        break;
      default:
        setFilterMenuValidation({ type: false, user_id: false, item_group_id: false });
        type = false;
        user_id = false;
        item_group_id = false;
        break;
    }

    if (
      type ||
      (stockInfo?.type === "Vendor" && user_id) ||
      (stockInfo?.type === "Loose Stock" && item_group_id) || !
      allTagsValid
    ) {
      handelToDisableButton(false, 'save')
      handelToDisableButton(false, 'save_print')
      return
    }
    else {
      setErrorForTags(false);
      // console.log('newData', newData);

      createTagApi(newData).then((res) => {
        if (res.success) {
          // Reset Values
          setTagData(null);
          setTagItems([newRow])
          setStockInfo({
            selected_on: new Date(),
            type: null,
            user_id: null,
            item_group_id: null,
          });
        }
      });


      handelToDisableButton(false, 'save')
      if (print) {
        setInvoicePrintState(true)
        handelToDisableButton(false, 'save_print')
      }
    }

  }































































































  const [prefixDataManager, setPrefixDataManager] = useState([]);

  const setPrefixData = () => {
    let updatePDF = dataState.all_items.map((item) => ({
      id: item.id,
      name: item.name,
      prefix: item.prefix,
      old_counter: Number(item.prefix_counter),
      new_counter: Number(item.prefix_counter)
    }));

    setPrefixDataManager(updatePDF);
  };

  useEffect(() => {
    // if (prefixDataManager?.length === 0) {
    setPrefixData();
    // }
  }, [dataState.all_items]);










  const updatePrefixData = (id, newPrefix) => {
    setPrefixDataManager((prevItems) => (
      prevItems.map((item) => (
        item.id === id ? {
          ...item,
          new_counter: newPrefix
        } : item
      ))
    ));
  };



  const handleToManageItemCode = async (id, index) => {
    let curruntPrefixData = prefixDataManager.find((item) => item.id === id)

    let newPrefixCount = curruntPrefixData.new_counter + 1

    setTagItems((prevItems) => (
      prevItems.map((item, indx) => (
        indx === index ? {
          ...item,
          prefix: curruntPrefixData.prefix,
          prefix_counter: newPrefixCount
        } : item
      ))
    ));

    updatePrefixData(id, newPrefixCount)

  }



  const handleItemCodeOn_CopyRow = (id, newPrefix) => {
    updatePrefixData(id, newPrefix)

  }

  const handleItemCodeOn_UpdateItem = (id, index) => {
    handleToManageItemCode(id, index)
  }







  const handleItemCodeOn_Save = () => {
    // console.log('handleItemCodeOn_Save');

  }












  // Create New Item Code 




























































































  // get old data from url
  useEffect(() => {
    let url = window.location.href;
    let urlParts = url.split('/');
    let idSegment = urlParts[urlParts.length - 1];
    let ids = idSegment.split('-');
    let linked_id = parseInt(ids[0], 10);
    let linked_counter = parseInt(ids[1], 10);


    if ((linked_id && !isNaN(linked_id)) && (linked_counter && !isNaN(linked_counter))) {
      setDisablebuttons({
        save: true,
        save_print: true,
        add_row: true
      })

      getTagsApi(linked_id, linked_counter).then((res) => {
        let response = res.data || false
        if (response) {
          setTagItems([
            ...response.map((item) => ({
              ...item,
              id: uuidv4()
            }))
          ]);
        }

        let selected_on = response.length > 0 ? response[0].selected_on : 0

        if (selected_on) {
          setStockInfo((prevData) => ({
            ...prevData,
            selected_on: selected_on,
          }));
        }
        myDispatch('SET_ALL_TAGS', [])
        myDispatch('CURRENT_VOUCHER_OF_ALL_TAGS', [])
      })
    } else {
      // Reset Values
      setTagData(null);
      setTagItems([newRow])
      setStockInfo({
        selected_on: new Date(),
        type: null,
        user_id: null,
        item_group_id: null,
      });
    }
  }, [])























  return (
    <DashboardLayout>








      {tagData && <Label
        visibility={false}
        printState={roughPrintState}
        printStateUpdate={setInvoicePrintState}
        invoicesData={tagData}
      />}









      <DashboardNavbar />






      <MDBox my={2}>
        <FilterMenu
          stockInfo={stockInfo}
          setStockInfo={setStockInfo}
          filterMenuValidation={filterMenuValidation}
        />
      </MDBox>





      <MDBox mb={2}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="flex-start">
            <TagItemRow
              tagItems={tagItems}
              setTagItems={setTagItems}
              // itemCodeCounter={itemCodeCounter}
              // setItemCodeCounter={setItemCodeCounter}
              prefixDataManager={prefixDataManager}
              // itemCodeType={itemCodeType}
              handleToAppendOneMoreRow={handleToAppendOneMoreRow}
              handleItemCodeOn_UpdateItem={handleItemCodeOn_UpdateItem}
              handleItemCodeOn_CopyRow={handleItemCodeOn_CopyRow}
              errorForTags={errorForTags}

              itemTableHeaderData={itemTableHeaderData}
              setItemTableHeaderData={setItemTableHeaderData}

            />
          </MDBox>
        </Card>
      </MDBox>





      <MDBox mb={2}>
        <Card>
          <MDBox display="flex" justifyContent="center" gap={2} py={1} px={2}>

            <MDButton
              onClick={() => {
                !disablebuttons.save && saveTagData()
              }}
              variant="outlined"
              color="primary"
            > Save </MDButton>

            <MDButton
              onClick={() => {
                !disablebuttons.save_print && savePrintTagData()
              }}
              variant="gradient"
              color="primary"
            > Save & Print </MDButton>

            <MDButton
              onClick={() => {
                !disablebuttons.add_row && handleToAppendOneMoreRow()
              }}
              variant="outlined"
              color="primary"
            > Add Row </MDButton>

          </MDBox>
        </Card>
      </MDBox>






      {/* <Footer /> */}
    </DashboardLayout >
  );
}

export default Tags;



