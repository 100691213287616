import { useContext, useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

//  React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file



import { v4 as uuidv4 } from 'uuid';
import SalePanelRow from "../components/SalePanelRow";
import { ConditionalCompare, GetCalculationOf_ItemFine, GetCalculationOf_ItemTotal, GetCalculationOf_Netwt, GetCalculationOf_Row2_Fine, TotalCalculationOf_Fine, TotalCalculationOf_GrandFine, TotalCalculationOf_GrandTotal, TotalCalculationOf_ItemTotal } from "../components/functions";
import GrandTotal from "../components/GrandTotal";
import FilterMenu from "./components/FilterMenu";
import RoughInvoice from "layouts/invoice/components/RoughInvoice";
import { DataContext } from "context/dataContext";







function TransactionPayment() {
  const { dataState, createSalesPaymentApi } = useContext(DataContext)





  // const [saleItems, setSaleItems] = useState([{
  //   id: uuidv4(),
  //   item: null,
  //   type: "outflow",
  //   stamp: null,
  //   unit: null,
  //   pc: 0,
  //   grwt: 0.000,
  //   less: 0.000,
  //   netwt: 0.000,
  //   tunch: 0.00,
  //   wstg: 0.00,
  //   rate: 0.00,
  //   lbr: 0.00,
  //   on: 'Wt',
  //   fine: 0.000,
  //   total: 0.00,
  // }]);


  const [panels, setPanels] = useState([{
    id: uuidv4(),
    type: 'inflow',
    method: null,
    miaGoldBhav: true,
    bank: null,
    useAsGoldBhav: false,
    amount: 0.000,
    grwt: 0.000,
    tunch: 100.00,
    fine: 0.000,
    rate: 0.00,
  }]);



  const [grandTotal, setGrandTotal] = useState({
    id: uuidv4(),
    balance: {
      fine: 0.000,
      fine_type: 'inflow',
      total: 0.00,
      total_type: 'inflow',
    },

    // sale_fine: 0.000,
    // sale_total: 0.00,

    // return_fine: 0.000,
    // return_total: 0.00,

    sub_total: {
      fine: 0.000,
      fine_type: 'inflow',
      total: 0.00,
      total_type: 'inflow',
    },

    adjustments_fine: 0.0,
    adjustments_type: "percentage",
  });




  const [userInfo, setUserInfo] = useState({
    selected_date: "",
    user_id: null,
  });



  const [userInfoValidation, setUserInfoValidation] = useState({
    user_name: false,
  });






  let newPanelRow = {
    id: uuidv4(),
    type: 'inflow',
    method: null,
    miaGoldBhav: true,
    bank: null,
    useAsGoldBhav: false,
    amount: 0.000,
    grwt: 0.000,
    tunch: 100.00,
    fine: 0.000,
    rate: 0.00,
  }







  function deleteRow2(i) {
    const index = i
    var panelitems = panels
    if (index > -1) {
      panelitems.splice(index, 1);
    }
    setTimeout(() => {
      setPanels([])
      setPanels(panelitems)
    }, 100);
  }












  const [disablebuttons, setDisablebuttons] = useState({
    save: false,
    save_print: false,
  })



  const handelToDisableButton = (value, key) => {
    setDisablebuttons(prevData => ({
      ...prevData,
      [key]: value,
    }));
  }


  const collectAndUpdateStateInfo = () => {









    const updatedUserInfo = {
      ...userInfo,
    };
    // setUserInfo(updatedUserInfo);





    // const updatedItems = saleItems.map((item) => ({
    //   ...item,
    //   netwt: GetCalculationOf_Netwt(item),
    //   fine: GetCalculationOf_ItemFine(item),
    //   total: GetCalculationOf_ItemTotal(item),
    // }));
    // setSaleItems(updatedItems);







    const updatedPanels = panels.map((item) => ({
      ...item,
      fine: Number(GetCalculationOf_Row2_Fine(item)),
    }));
    setPanels(updatedPanels);






    // const updatedGrandTotal = {
    //   ...grandTotal,
    //   sale_fine: TotalCalculationOf_Fine(
    //     saleItems.filter((item) => ConditionalCompare(item.type, '===', 'outflow'))
    //   ),
    //   sale_total: TotalCalculationOf_ItemTotal(
    //     saleItems.filter((item) => ConditionalCompare(item.type, '===', 'outflow'))
    //   ),
    //   return_fine: Math.abs(Number(
    //     TotalCalculationOf_Fine(
    //       saleItems.filter((item) => ConditionalCompare(item.type, '===', 'inflow') || ConditionalCompare(item.type, '===', 'outinflow'))
    //     )
    //   )),
    //   return_total: Math.abs(Number(
    //     TotalCalculationOf_ItemTotal(
    //       saleItems.filter((item) => ConditionalCompare(item.type, '===', 'inflow') || ConditionalCompare(item.type, '===', 'outinflow'))
    //     )
    //   )),
    //   sub_total: {
    //     ...grandTotal.sub_total,
    //     fine: Math.abs(Number(TotalCalculationOf_GrandFine(saleItems, panels))),
    //     fine_type: TotalCalculationOf_GrandFine(saleItems, panels) <= 0 ? (TotalCalculationOf_GrandFine(saleItems, panels) === 0 ? null : "inflow") : "outflow",
    //     total: Math.abs(Number(TotalCalculationOf_GrandTotal(saleItems, panels))),
    //     total_type: TotalCalculationOf_GrandTotal(saleItems, panels) <= 0 ? (TotalCalculationOf_GrandTotal(saleItems, panels) === 0 ? null : "inflow") : "outflow",
    //   },
    // };
    // setGrandTotal(updatedGrandTotal);


    // console.log('Old Data ', { items: saleItems, panels: panels, total: grandTotal })

    return {
      user: updatedUserInfo,
      items: null,
      payments: updatedPanels,
      // total: updatedGrandTotal
    }

  }



































  const [errorForPayment, setErrorForPayment] = useState(false)




  function saveAllData(newData, print) {

    const allItemsValid = newData.payments.every((val) => {
      return val.method
    });
    setErrorForPayment(!allItemsValid);

    userInfo?.user_id === null ? (
      setUserInfoValidation({ user_name: true })
    ) : (
      setUserInfoValidation({ user_name: false })
    )

    if (userInfo?.user_id === null || !allItemsValid) {
      handelToDisableButton(false, 'save')
      handelToDisableButton(false, 'save_print')
      return
    }
    else {
      setErrorForPayment(false);
      createSalesPaymentApi(newData)

      // Reset Values
      setPanels([newPanelRow])

      handelToDisableButton(false, 'save')
      if (print) {
        setInvoicePrintState(true)
        handelToDisableButton(false, 'save_print')
      }
    }

  }































  const savePaymentData = () => {
    handelToDisableButton(true, 'save')
    console.log('savePaymentData =================>');
    console.log('New Data ', collectAndUpdateStateInfo());
    const newData = collectAndUpdateStateInfo();
    saveAllData(newData, false)
  }



  // Print State To Manage Invoice Print
  const [roughPrintState, setInvoicePrintState] = useState(false);





  const [paymentData, setPaymentDataData] = useState(null);

  const savePrintPaymentData = () => {
    handelToDisableButton(true, 'save_print')
    console.log('savePrintPaymentData =================>');

    const newData = collectAndUpdateStateInfo();
    setPaymentDataData(newData);

    setInvoicePrintState(true)


    saveAllData(newData, true)
  }


  const cancelPaymentData = () => {
    console.log('cancelPaymentDatav =================>');

  }










  // To Update Voucher Number 
  // useEffect(() => {
  //   if (dataState?.all_voucher) {
  //     let voucherCount = dataState.all_voucher.find((item) => item.id === 3)?.counter || 0;
  //     setUserInfo((prevUserInfo) => ({
  //       ...prevUserInfo,
  //       voucher_number: voucherCount + 1,
  //     }));
  //   }
  // }, [dataState.all_voucher]);




  // Add Account Balance
  useEffect(() => {
    let curruntUser = dataState?.all_accounts?.find((item) => item.id === userInfo.user_id) || null

    setGrandTotal((prevData) => ({
      ...prevData,
      balance: {
        fine: curruntUser?.material_weight || 0.000,
        fine_type: curruntUser?.material_method || null,
        total: curruntUser?.amount || 0.000,
        total_type: curruntUser?.amount_method || null,
      },
    }));
  }, [userInfo.user_id])







  return (
    <DashboardLayout>





      {paymentData && <RoughInvoice
        visibility={false}
        printState={roughPrintState}
        printStateUpdate={setInvoicePrintState}
        invoicesData={paymentData}
        invoicesSettings={{ itemSection: false }}
      />}





      <DashboardNavbar />





      <MDBox my={2}>
        <FilterMenu
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          userInfoValidation={userInfoValidation}
        />
      </MDBox>





      <MDBox>
        <Grid container spacing={2}>
          <Grid item lg={7} md={7} sm={12} xs={12} mb={2}>
            <Card>
              <SalePanelRow
                panels={panels}
                setPanels={setPanels}
                // saleItems={saleItems}
                deleteRow2={deleteRow2}
                errorForPayment={errorForPayment}
              />
            </Card>
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12} mb={2}>
            <Card>
              <GrandTotal
                // saleItems={saleItems}
                panels={panels}
                grandTotal={grandTotal}
              />
            </Card>
          </Grid>
        </Grid>
      </MDBox>





      <MDBox mb={2}>
        <Card>
          <MDBox display="flex" justifyContent="center" gap={2} py={1} px={2}>

            <MDButton
              onClick={() => {
                !disablebuttons.save && savePaymentData()
              }}
              variant="outlined"
              color="primary"
            > Save </MDButton>

            <MDButton
              onClick={() => {
                !disablebuttons.save_print && savePrintPaymentData()
              }}
              variant="gradient"
              color="primary"
            > Save & Print </MDButton>

            {/* <MDButton
              onClick={() => {
                cancelPaymentData()
              }}
              variant="outlined"
              color="error"
            > Cancel </MDButton> */}

          </MDBox>
        </Card>
      </MDBox>





      {/* <Footer /> */}
    </DashboardLayout >
  );
}

export default TransactionPayment;

