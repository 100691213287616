

import { useState, useEffect, useContext } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
// import Autocomplete from "@mui/material/Autocomplete";

//  React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
// import MDInput from "components/MDInput";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
import DataTable from "components/DataTable";

// import { addDays } from 'date-fns';
// import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// Data
// import FormField from "layouts/account/components/FormField";
import NoDataFound from "components/NoDataFound";

// import { useAuth } from "utils/AuthProvider";
import ComplexStatisticsCard from "components/Cards/StatisticsCards/ComplexStatisticsCard";
import FilterMenu from "./components/FilterMenu";
import { SetFormattedIndianNumber } from "components/globalFunctions";
import { DataContext } from 'context/dataContext'
import { transactionsTypeOption } from "components/const";
import dataTableData from "./components/dataTableData";
import { transactionsTypeOption2 } from "components/const";



// const axios = require('axios').default


function Ledger() {
  const { dataState } = useContext(DataContext)

  const [data, setData] = useState({ ...dataTableData, rows: [] });




  useEffect(() => {

    let combinedValue = [...(dataState?.all_ledgers?.sales || []), ...(dataState?.all_ledgers?.payments || [])]
    let updatedValue = combinedValue.map((itemData) => {

      let typeDetails = itemData.method && itemData.method !== 'gold bhav' ? (
        transactionsTypeOption2.find((e) => e.status === itemData.type)
      ) : (
        transactionsTypeOption.find((e) => e.status === itemData.type)
      )

      let itemDetails = dataState.all_items.find((e) => e.id === Number(itemData.item));
      let stampsDetails = dataState.all_stamps.find((e) => e.id === Number(itemData.stamp));
      let prefixDetails = dataState.all_voucher.find((e) => e.id === Number(itemData.prefix));
      return {
        ...itemData,
        id: itemData.linked_id || '',
        type: typeDetails?.name || '',

        item: itemDetails?.name || '',
        stamp: stampsDetails?.name || '',
        prefix: prefixDetails?.prefix || '',
      }
    })

    setData(prevData => ({
      ...prevData,
      rows: updatedValue
    }));

  }, [dataState.all_ledgers, dataState.all_items, dataState.all_stamps]);















  return (
    <DashboardLayout>





      <DashboardNavbar />





      <MDBox my={2}>
        <FilterMenu />
      </MDBox>






      <MDBox mb={2}>
        <Grid container mb={2} spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ height: '100%' }}>
              <ComplexStatisticsCard
                // icon="weekend"
                color="primary"
                title="Opening Gold"
                count={SetFormattedIndianNumber(6425.362, 3)}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "more than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ height: '100%' }}>
              <ComplexStatisticsCard
                // icon="leaderboard"
                color="primary"
                title="Closing Gold"
                count={SetFormattedIndianNumber(5375.482, 3)}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "more than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ height: '100%' }}>
              <ComplexStatisticsCard
                // icon="store"
                color="primary"
                title="Opening Balance"
                count={SetFormattedIndianNumber(150000000)}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "more than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ height: '100%' }}>
              <ComplexStatisticsCard
                // icon="person_add"
                color="primary"
                title="Closing Balance"
                count={SetFormattedIndianNumber(990000000)}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "more than last month",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>


















      <MDBox mb={2}>
        {/* <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDTypography></MDTypography>
          <MDBox display="flex">
            <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>file_download</Icon>
                &nbsp;export pdf
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox> */}
        <Card>
          {data?.rows?.length > 0 ? (
            <DataTable table={data} canSearch />
          ) : (
            <MDBox p={2}>
              <NoDataFound
                title="No Data Found"
              />
            </MDBox>
          )}
        </Card>
      </MDBox>























      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Ledger;
