import React, { useContext } from 'react'

import { Autocomplete, Grid } from '@mui/material'
import CustomDatepicker from 'components/CustomDatepicker'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import { useState, useEffect } from 'react'
import { useAuth } from '../../../../utils/AuthProvider'
import { DataContext } from "context/dataContext";
import AddAccountPopup from 'layouts/transactions/components/AddAccountPopup'
import ErrorText from 'components/ErrorText'
import FilterMenuContainer from 'components/FilterMenu/FilterMenuContainer'
import FM_Button1 from 'components/FilterMenu/FM_Button1'

// const axios = require('axios').default

const FilterMenu = ({
  userInfo,
  setUserInfo,
  userInfoValidation,
}) => {

  // const { cookies } = useAuth();
  // axios.defaults.headers.common = { 'Authorization': `Bearer ${cookies.token}` }

  const { dataState } = useContext(DataContext)

  const [customers, setCustomers] = useState([]);

  // Add Account Popup State
  const [addAccountPopupState, setAddAccountPopupState] = useState(false);

  const [newAccountData, setNewAccountData] = useState(false)

  const handelToUpdateUserInfo = (value, key) => {
    setUserInfo(prevData => ({
      ...prevData,
      [key]: value,
    }));
    console.log(userInfo)
  }

  function resetParams() {
    setUserInfo(prevData => ({
      ...prevData,
      selected_date: "",
      user_id: null,
    }));
  }

  // useEffect(() => {
  //   var accounts = dataState.all_accounts
  //   var accountitems = []
  //   accounts.forEach((cus, i) => {
  //     accountitems.push({ label: cus.name, id: cus.id, phone: cus.contact_no, _id: cus._id })
  //   });
  //   // console.log(accountitems)
  //   accountitems.push({ id: 0, label: "Add Account" })
  //   setCustomers(accountitems)

  //   handelToUpdateUserInfo(new Date(), 'selected_date')

  // }, [dataState.all_accounts]);





  // Managing Add Account Popup
  const handleToManageAddAccountPopup = () => {
    setAddAccountPopupState(true)
  }





  // // Add New Account Data to DropDown
  // useEffect(() => {
  //   if (newAccountData) {
  //     handelToUpdateUserInfo(dataState.all_accounts[0]?.name || '', 'user_name')
  //     handelToUpdateUserInfo(dataState.all_accounts[0]?.id || null, 'user_id')
  //     handelToUpdateUserInfo(dataState.all_accounts[0]?.contact_no || '', 'phone_number')
  //     handelToUpdateUserInfo(dataState.all_accounts[0]?._id || '', 'accountId')
  //   }
  // }, [dataState?.all_accounts])


  useEffect(() => {
    var accounts = dataState.all_accounts
    var accountitems = []
    accounts.forEach((cus, i) => {
      accountitems.push({ label: cus.name, id: cus.id, phone: cus.contact_no, _id: cus._id })
    });
    accountitems.push({ id: 0, label: "Add Account" })
    setCustomers(accountitems)

    handelToUpdateUserInfo(new Date(), 'selected_date')


    // Add New Account Data to DropDown
    if (newAccountData) {
      handelToUpdateUserInfo(dataState.all_accounts[0]?.name || '', 'user_name')
      handelToUpdateUserInfo(dataState.all_accounts[0]?.id || null, 'user_id')
    }
  }, [dataState?.all_accounts])












  return (
    <>


      <FilterMenuContainer>



        <MDBox sx={{ width: '100%', maxWidth: '25%', position: 'relative' }}>
          <CustomDatepicker
            value={userInfo.selected_date || new Date()}
            valueKey="selected_date"
            editValue={handelToUpdateUserInfo}
            label="Select Date"
          />
        </MDBox>







        <MDBox style={{ width: '100%', maxWidth: '25%' }} >
          <Autocomplete
            popupIcon={''}
            value={userInfo.user_name}
            options={customers}
            onChange={(_, newValue) => {
              console.log('newValue', newValue);
              if (newValue?.label === "Add Account") {
                handleToManageAddAccountPopup()
                setNewAccountData(true)
              } else {
                handelToUpdateUserInfo(newValue?.label || '', 'user_name')
                handelToUpdateUserInfo(newValue?.id || null, 'user_id')
                setNewAccountData(false)
              }
            }}

            sx={{
              '& .MuiFormControl-root': { background: 'initial' },
              ...(userInfoValidation.user_name && userInfo?.user_id === null && {
                '& .MuiInput-root:before': { borderColor: '#F44335' },
                '& .MuiInputLabel-root': { color: '#F44335' },
              }),
            }}

            renderInput={(params) => (
              <MDInput
                {...params}
                label="Customer"
                variant="standard"
                error={userInfoValidation.user_name && userInfo?.user_id === null}
              />
            )}
          />
          {userInfoValidation.user_name && userInfo?.user_id === null && (
            <ErrorText errorName="Customer is required" />
          )}
        </MDBox>




        <FM_Button1
          actionButton={resetParams}
        />

      </FilterMenuContainer>




      <AddAccountPopup
        open={addAccountPopupState}
        close={setAddAccountPopupState}
      />


    </>
  )
}

export default FilterMenu